const middleware = {}

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['is-auth'] = require('../middleware/is-auth.js')
middleware['is-auth'] = middleware['is-auth'].default || middleware['is-auth']

middleware['jwt-middleware'] = require('../middleware/jwt-middleware.js')
middleware['jwt-middleware'] = middleware['jwt-middleware'].default || middleware['jwt-middleware']

middleware['page-view'] = require('../middleware/page-view.js')
middleware['page-view'] = middleware['page-view'].default || middleware['page-view']

middleware['routelogin'] = require('../middleware/routelogin.js')
middleware['routelogin'] = middleware['routelogin'].default || middleware['routelogin']

middleware['routeverify'] = require('../middleware/routeverify.js')
middleware['routeverify'] = middleware['routeverify'].default || middleware['routeverify']

export default middleware

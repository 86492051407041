var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44",
      "height": "28",
      "viewBox": "0 0 44 28",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    attrs: {
      "d": "M44,26.349345 C44,27.2663755 43.2727273,28 42.3636364,28 L1.63636364,28 C0.727272727,28 0,27.2663755 0,26.349345 L0,1.65065502 C0,0.733624454 0.727272727,0 1.63636364,0 L42.3636364,0 C43.2727273,0 44,0.733624454 44,1.65065502 L44,26.349345 L44,26.349345 Z",
      "id": "Path",
      "fill": "#1A1F71"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M16.7272727,8.13100437 L11.8181818,19.930131 L8.60606061,19.930131 L6.18181818,10.5152838 C6.06060606,9.9650655 5.87878788,9.72052402 5.45454545,9.47598253 C4.72727273,9.10917031 3.51515152,8.68122271 2.48484848,8.49781659 L2.54545455,8.13100437 L7.6969697,8.13100437 C8.36363636,8.13100437 8.96969697,8.55895197 9.09090909,9.35371179 L10.3636364,16.2008734 L13.5151515,8.19213974 L16.7272727,8.19213974 L16.7272727,8.13100437 Z M29.2727273,16.0786026 C29.2727273,12.9606987 25.030303,12.7772926 25.030303,11.4323144 C25.030303,11.0043668 25.4545455,10.5764192 26.3030303,10.4541485 C26.7272727,10.3930131 27.9393939,10.3318777 29.2727273,11.0043668 L29.8181818,8.49781659 C29.0909091,8.25327511 28.1818182,7.94759825 26.969697,7.94759825 C24,7.94759825 21.8787879,9.5371179 21.8787879,11.860262 C21.8787879,13.5720524 23.3939394,14.489083 24.5454545,15.0393013 C25.6969697,15.5895197 26.1212121,15.9563319 26.1212121,16.5065502 C26.1212121,17.30131 25.2121212,17.6681223 24.3030303,17.6681223 C22.7878788,17.6681223 21.8787879,17.2401747 21.2121212,16.9344978 L20.6666667,19.5021834 C21.3939394,19.8078603 22.6666667,20.1135371 24,20.1135371 C27.1515152,20.1135371 29.2121212,18.5240175 29.2727273,16.0786026 M37.1515152,19.930131 L39.9393939,19.930131 L37.5151515,8.13100437 L34.9090909,8.13100437 C34.3030303,8.13100437 33.8181818,8.49781659 33.6363636,8.98689956 L29.0909091,19.930131 L32.2424242,19.930131 L32.8484848,18.1572052 L36.7272727,18.1572052 L37.1515152,19.930131 Z M33.7575758,15.7729258 L35.3333333,11.371179 L36.2424242,15.7729258 L33.7575758,15.7729258 Z M21.030303,8.13100437 L18.5454545,19.930131 L15.5151515,19.930131 L18,8.13100437 L21.030303,8.13100437 Z",
      "id": "Shape",
      "fill": "#FFFFFF"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="c--tabs_auth-tabs relative noselect">
    <ul class="display-flex justify-content-between align-items-end pb-10 pb-sm-0 px-20 px-sm-0">
      <li v-for="(tab, index) in tabs" :key="`auth-tab-${index}`" :data-testid="`${tab.text}-tab`" class="w-half">
        <nuxt-link
          :to="localePath(tabAction(tab.link))"
          :class="[
            'display-flex justify-content-center align-items-center p-10 mb-10 mb-sm-0 p-sm-16 pb-sm-30 typo-h6 typo-sm-h4-right text-decoration-none',
            {
              'auth-tab-active box-rounded-sm-t color-text-primary color-bg-lighter color-bg-white-sm shadowed-sm':
                getRouteBaseName() && getRouteBaseName() === tab.link.name,
              'color-text-secondary': !(getRouteBaseName() && getRouteBaseName() === tab.link.name),
            },
          ]"
        >
          {{ tab.text }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'AuthTabs',
    props: ['tabs'],
    methods: {
      tabAction(routeObj) {
        let route = routeObj
        const referralQueryParam = this.$route?.query?.referral

        if (referralQueryParam) {
          route = {
            ...routeObj,
            query: {
              referral: referralQueryParam,
            },
          }
        }

        return this.localePath(route)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .auth-tab-active {
    @media screen and (max-width: 575px) {
      border-radius: 7px;
    }
  }
</style>

<template>
  <div class="c--loyalty-program_how-loyalty-program-works-modal important-tips-how-loyalty-program-works">
    <general-modal
      modal-id="how-loyalty-program-works-modal"
      :hasCloseButton="false"
      :modalView="modalView"
      height="unset"
    >
      <ImportantTipsModal
        assets-type="image"
        class="text-center"
        :title="$t('scenes.welcome.to.loyalty.program.tips.title')"
        :nextTipCaption="$t('scenes.welcome.to.loyalty.program.next-button')"
        :lastTipCaption="$t('scenes.welcome.to.loyalty.program.lets-learn-button')"
        :tipsList="infoList"
        :preloader="preloader"
        @closeModalClicked="onCloseModalClicked"
      />
    </general-modal>
  </div>
</template>

<script>
  import ImportantTipsModal from '~/components/modals/important-tips'
  import GeneralModal from '~/components/modals/general-modal.vue'

  export default {
    components: {
      ImportantTipsModal,
      GeneralModal,
    },

    props: {
      viewModal: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        infoList: [
          {
            title: this.$t(`scenes.welcome.to.loyalty.program.tips.first-title`),
            imageUrl: '/assets/images/loyalty-program/loyalty-onboarding-visual-1.png',
          },
          {
            title: this.$t(`scenes.welcome.to.loyalty.program.tips.second-title`),
            imageUrl: '/assets/images/loyalty-program/loyalty-onboarding-visual-2.png',
          },
          {
            title: this.$t(`scenes.welcome.to.loyalty.program.tips.third-title`),
            imageUrl: '/assets/images/loyalty-program/loyalty-onboarding-visual-3.png',
          },
        ],

        modalView: false,
        preloader: true,
      }
    },

    methods: {
      emitCloseModalEvent() {
        this.$emit('onClickOk')
      },

      async onCloseModalClicked() {
        await this.setUserIsLoyaltyWelcomeViewed()
        this.emitCloseModalEvent()
      },

      async setUserIsLoyaltyWelcomeViewed() {
        await this.$store.dispatch('userSettings/post_settings', {
          is_loyalty_welcome_viewed: true,
        })
      },
    },

    watch: {
      viewModal(val) {
        if (val) {
          this.modalView = val
          setTimeout(() => {
            this.preloader = false
          }, 100)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .important-tips-how-loyalty-program-works {
    height: 100%;
  }
</style>

<template>
  <div
    :class="[
      'c--lottie-preloader',
      'lottie-preloader-wrapper',
      `position-${position}`,
      theme,
      { 'background-colored': isBackground },
    ]"
  >
    <div class="lottie-preloader">
      <template v-if="options && options.animationData">
        <Lottie :id="elementID" :ref="elementID" :options="options" :width="width" :height="height" />
      </template>
    </div>
  </div>
</template>

<script>
  import Lottie from 'vue-lottie/src/lottie.vue'

  export default {
    name: 'preloader',

    components: {
      Lottie,
    },

    props: {
      position: {
        type: String,
        required: false,
        default: 'absolute', // fixed
      },
      theme: {
        type: String,
        required: false,
        default: 'light', // dark, light
      },
      isBackground: {
        type: Boolean,
        required: false,
        default: true,
      },
      width: {
        type: Number,
        required: false,
        default: 88,
      },
      height: {
        type: Number,
        required: false,
        default: 88,
      },
    },

    computed: {
      elementID() {
        return `lottie-preloader-${this.theme}-default`
      },

      options() {
        return {
          loop: true,
          autoplay: true,
          animationData: this.preloaderData || null,
          rendererSettings: false,
        }
      },
    },

    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .lottie-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-wrapper {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9999;

      &.background-colored {
        &.dark {
          background: rgba(#4a4a4a, 0.4);
        }

        &.light {
          background: rgba(250, 250, 250, 0.75);
        }
      }
    }
  }
</style>

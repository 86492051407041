<script>
  export default {
    name: 'ui-branding-topline',
    props: {
      branding: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<template>
  <div
    v-if="branding"
    :style="`background-color: ${branding.background_color}; color: ${branding.text_color};`"
    class="tw-px-5 md:tw-px-7.5"
  >
    <div class="tw-max-w-[1120px] tw-mx-auto tw-py-[7px] tw-text-[12px]/[1] tw-font-bold">
      <div class="tw-flex tw-gap-5">
        <a
          :href="branding.partner_url"
          target="_blank"
          class="tw-flex tw-items-center tw-gap-1 tw-text-current hover:tw-text-current"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            class="rtl:tw-rotate-180"
          >
            <path d="M-2.18557e-07 5L7.5 0.669872L7.5 9.33013L-2.18557e-07 5Z" fill="currentColor" />
          </svg>
          <span>{{ $t('components.branding.topline.back-link-text', { name: branding.partner_url_text }) }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "width": "16",
      "height": "16"
    }
  }, [_c('g', {
    attrs: {
      "id": "LOGOS"
    }
  }, [_c('g', {
    attrs: {
      "id": "<Group>"
    }
  }, [_c('g', {
    attrs: {
      "id": "<Group>"
    }
  }, [_c('g', {
    attrs: {
      "id": "<Group>"
    }
  }, [_c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#25f4ee",
      "d": "m6.4 6.3v-0.6q-0.3-0.1-0.6-0.1c-2.7 0-4.9 2.2-4.9 4.9 0 1.6 0.9 3.1 2.1 3.9-0.8-0.8-1.3-2-1.3-3.3 0-2.6 2.1-4.7 4.7-4.8z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#25f4ee",
      "d": "m6.5 13.4c1.2 0 2.2-1 2.3-2.2v-10.5h1.9q-0.1-0.3-0.1-0.7h-2.6v10.6c-0.1 1.1-1 2.1-2.2 2.1q-0.6 0-1-0.3c0.4 0.6 1 1 1.7 1z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#25f4ee",
      "d": "m14.3 4.3v-0.6c-0.8 0-1.4-0.2-2-0.6 0.5 0.6 1.2 1 2 1.2z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#fe2c55",
      "d": "m12.3 3.1c-0.6-0.7-0.9-1.5-0.9-2.4h-0.7c0.2 1 0.8 1.8 1.6 2.4z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#fe2c55",
      "d": "m5.8 8.3c-1.2 0-2.2 1-2.2 2.2 0 0.8 0.4 1.6 1.2 1.9-0.3-0.3-0.5-0.8-0.5-1.3 0-1.2 1-2.2 2.2-2.2q0.4 0 0.7 0.1v-2.7q-0.3 0-0.7 0 0 0-0.1 0v2.1q-0.3-0.1-0.6-0.1z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#fe2c55",
      "d": "m14.3 4.3v2c-1.4 0-2.7-0.4-3.7-1.2v5.4c0 2.6-2.2 4.8-4.8 4.8-1.1 0-2-0.3-2.8-0.9 0.9 1 2.1 1.6 3.5 1.6 2.7 0 4.9-2.2 4.9-4.9v-5.3c1 0.7 2.3 1.2 3.6 1.2v-2.7q-0.4 0-0.7 0z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "<Path>",
      "fill": "#000000",
      "d": "m10.6 10.5v-5.4c1 0.8 2.3 1.2 3.7 1.2v-2c-0.8-0.2-1.5-0.6-2-1.2-0.8-0.6-1.4-1.4-1.6-2.4h-1.9v10.5c-0.1 1.2-1.1 2.2-2.3 2.2-0.7 0-1.3-0.4-1.7-1-0.8-0.3-1.2-1.1-1.2-1.9 0-1.2 1-2.2 2.2-2.2q0.3 0 0.6 0.1v-2.1c-2.6 0.1-4.7 2.2-4.7 4.8 0 1.3 0.5 2.5 1.3 3.3 0.8 0.6 1.7 0.9 2.8 0.9 2.6 0 4.8-2.2 4.8-4.8z"
    }
  })])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--modals_important-tips important-tips-modal",
    attrs: {
      "data-testid": "important-tips-modal"
    }
  }, [_vm.title ? _c('div', {
    staticClass: "important-tips-modal-title",
    attrs: {
      "data-testid": "important-tips-title"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._v(" "), _vm.preloader ? [_c('div', {
    staticClass: "relative",
    staticStyle: {
      "min-height": "406px"
    }
  }, [_c('client-only', [_c('v-lottie', {
    attrs: {
      "is-background": false
    }
  })], 1)], 1)] : [_c('div', {
    staticClass: "select-none"
  }, [_c('swiper', {
    ref: "importantTipsModalSwiper",
    staticClass: "important-tips-modal-swiper",
    attrs: {
      "options": _vm.swiperOption,
      "dir": _vm.$dir()
    },
    on: {
      "slideChangeTransitionEnd": _vm.onSlideChange
    }
  }, _vm._l(_vm.tipsList, function (item, index) {
    return _c('swiper-slide', {
      key: `important-tips-modal-item-${index}`,
      staticClass: "important-tips-modal-swiper-item"
    }, [_vm.assetsType === 'lottie' ? [_c('Lottie', {
      ref: `important-tips-modal-item-lottie-${index}`,
      refInFor: true,
      staticClass: "player-item",
      attrs: {
        "id": `important-tips-modal-item-lottie-${index}`,
        "options": {
          loop: true,
          autoplay: false,
          animationData: item.data,
          rendererSettings: false
        },
        "height": 445
      },
      on: {
        "animCreated": function ($event) {
          return _vm.handleAnimation($event, index);
        }
      }
    })] : [_c('div', {
      class: {
        'important-tips-modal-swiper-item-image': _vm.hasLargeImage
      }
    }, [_c('nuxt-img', {
      staticClass: "max-w-full",
      attrs: {
        "src": item.imageUrl,
        "alt": "",
        "width": _vm.imgWidth,
        "height": _vm.imgHeight,
        "loading": "lazy"
      }
    })], 1)], _vm._v(" "), _c('p', {
      staticClass: "swiper-item-title",
      class: {
        'swiper-item-title--no-subtitle': !item.subtitle
      },
      attrs: {
        "data-testid": "swiper-item-title"
      }
    }, [_vm._v("\n            " + _vm._s(item.title) + "\n          ")]), _vm._v(" "), item.subtitle ? _c('p', {
      staticClass: "swiper-item-subtitle"
    }, [_vm._v(_vm._s(item.subtitle))]) : _vm._e()], 2);
  }), 1)], 1)], _vm._v(" "), _vm.hasHorizontalAlign ? _c('div', {
    staticClass: "important-tips-modal-swiper-bottom"
  }, [_c('div', {
    staticClass: "important-tips-modal-swiper-bottom-left"
  }, [_c('div', {
    staticClass: "sw-itm-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })]), _vm._v(" "), _c('div', {
    staticClass: "swiper-button",
    attrs: {
      "data-testid": `${_vm.swiperButtonCaption}-btn`
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "overlay-tooltip-cta",
      "block": ""
    },
    on: {
      "click": _vm.swiperBottomButton
    }
  }, [_vm._v(_vm._s(_vm.swiperButtonCaption))])], 1)]) : _c('div', {
    staticClass: "tw-flex tw-flex-col tw-items-center tw-mb-5"
  }, [_c('div', {
    staticClass: "tw-mb-3"
  }, [_c('div', {
    staticClass: "sw-itm-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })]), _vm._v(" "), _c('div', {
    staticClass: "swiper-butto tw-block tw-w-full tw-px-5",
    attrs: {
      "data-testid": `${_vm.swiperButtonCaption}-btn`
    }
  }, [_c('button', {
    staticClass: "tw-block tw-border-primary tw-border-solid tw-rounded tw-mx-auto tw-py-4 tw-w-full tw-font-semibold tw-text-3 tw-tracking-wider",
    class: {
      'tw-bg-neutral-600 tw-text-white': _vm.activeIndex === 2,
      'tw-bg-white': _vm.activeIndex !== 2
    },
    on: {
      "click": _vm.swiperBottomButton
    }
  }, [_vm._v("\n        " + _vm._s(_vm.swiperButtonCaption) + "\n      ")])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['c--lottie-preloader', 'lottie-preloader-wrapper', `position-${_vm.position}`, _vm.theme, {
      'background-colored': _vm.isBackground
    }]
  }, [_c('div', {
    staticClass: "lottie-preloader"
  }, [_vm.options && _vm.options.animationData ? [_c('Lottie', {
    ref: _vm.elementID,
    attrs: {
      "id": _vm.elementID,
      "options": _vm.options,
      "width": _vm.width,
      "height": _vm.height
    }
  })] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Header :is-error="true" />
    <nuxt ref="page" />
    <Footer :key="`Footer-error`" />
  </div>
</template>

<script>
  import Header from '~/components/shared/header/index.vue'
  import Footer from '~/components/shared/footer/index.vue'

  export default {
    components: {
      Header,
      Footer,
    },
    head() {
      const currentLocale = this.$i18n.locale
      const currentLang = this.$i18n.locales.find((v) => v.code === this.$i18n.locale)

      const listOfSpecialFonts = ['ar', 'he', 'hi', 'ja', 'ko', 'th']

      let link = []

      if (listOfSpecialFonts.includes(currentLocale)) {
        const isFontsIsExist = link.find((v) => v.hid === `stylesheet-${currentLocale}`)

        if (!isFontsIsExist) {
          link.push({
            hid: `stylesheet-${currentLocale}`,
            rel: 'stylesheet',
            href: `/fonts/ibm-plex-sans-${currentLocale}/fonts.css`,
          })
        }
      }

      return {
        link,
        htmlAttrs: {
          lang: currentLang.code,
          dir: currentLang.dir,
        },
        bodyAttrs: {
          style: this.$dir() === 'rtl' ? `text-align: start;` : '',
        },
      }
    },
  }
</script>

<template>
  <ul class="c--shared_header_sub-menu sub-menu" :class="[`size-${type}`, customClass]">
    <li v-for="(item, index) in list" :key="`shared-header-submenu-item-${index}`">
      <template v-if="!item.link && item.callback && item.callback.event && item.callback.functionName">
        <a
          @[item.callback.event]="clickPreventItem($event, item.callback.event, item)"
          :class="[item.customClass ? item.customClass : '']"
        >
          {{ item.text
          }}<template v-if="item.image && item.image.length > 0">
            <span
              :class="[
                item.image,
                'tw-text-4 sub-menu-item-image',
                {
                  'rtl-transform-rotate-y': $dir() === 'rtl',
                },
              ]"
              :data-testid="`${item.text}-item`"
            ></span
          ></template>
        </a>
      </template>
      <template v-else>
        <component
          @click.native="clickHandler(item)"
          :target="item.external ? '_blank' : '_self'"
          :to="item.selfUrl ? item.link : localePath(item.link)"
          :href="item.selfUrl ? item.link : localePath(item.link)"
          :class="[item.customClass ? item.customClass : '']"
          :is="item.selfUrl ? 'a' : 'nuxt-link'"
          :data-testid="`${item.text}-item`"
        >
          {{ item.text }}
          <template v-if="item.image && item.image.length > 0">
            <span
              :class="[
                item.image,
                'sub-menu-item-image',
                {
                  'rtl-transform-rotate-y': $dir() === 'rtl',
                },
              ]"
            ></span
          ></template>
        </component>
      </template>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: false,
        default: 'small', // small, normal, big
      },
      list: {
        type: Array,
        required: true,
      },
      customClass: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      clickHandler(item) {
        this.$utils.sendEvent('mainNavigation', {
          text: item?.text,
          url: this.localePath(item?.link),
        })
      },
      clickPreventItem(e, eventName, data) {
        if (eventName === 'click') {
          e.preventDefault()
        }

        this.$emit('onClickItem', data)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sub-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    min-width: 255px;
    padding: 0 0 0 20px;

    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);

    [dir='rtl'] & {
      padding: 0 20px 0 0;
    }

    &.language-sub-menu {
      li {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 22px;

          .sub-menu-item-image {
            visibility: hidden;
            opacity: 0;
            display: inline-flex;
          }

          &.active-language {
            .sub-menu-item-image {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }

    &.size-small {
      li {
        a {
          font-size: 0.8125rem;
          line-height: 1;
          padding: 15px 0;
        }
      }
    }

    &.size-big {
      li {
        a {
          font-size: 0.9375rem;
          line-height: 1.3333;
          font-weight: 500;
          padding: 16px 0;
        }
      }
    }

    li {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      padding-right: 20px;

      [dir='rtl'] & {
        padding-right: unset;
        padding-left: 20px;
      }

      &:last-child {
        border-bottom-color: transparent;
      }

      a {
        color: #4a4a4a;
        letter-spacing: 0;
        text-decoration: none;
        width: 100%;
        text-align: left;
        cursor: pointer;

        [dir='rtl'] & {
          text-align: right;
        }

        &.logout-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #f2222e;
          span.sub-menu-item-image {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
</style>

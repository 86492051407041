module.exports = (value, { currency = null, decimals = 2, forceDecimals = false } = {}) => {
  let result = parseFloat(value)

  if (isNaN(result)) throw new Error(`${value} is not a valid number.`)

  if (!Number.isInteger(result) || (Number.isInteger(result) && forceDecimals)) {
    result = result.toFixed(decimals)
  }

  switch (currency) {
    case 'USD':
      result = `US $${result}`
      break
    default:
      result = `${result}`
  }

  return result
}

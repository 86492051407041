var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.1562 3.5H5.84375C4.54933 3.5 3.5 4.54933 3.5 5.84375V16.1562C3.5 17.4507 4.54933 18.5 5.84375 18.5H16.1562C17.4507 18.5 18.5 17.4507 18.5 16.1562V5.84375C18.5 4.54933 17.4507 3.5 16.1562 3.5Z",
      "fill": "#FF0000"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M16.225 8.31878C16.0875 7.83753 15.7438 7.49377 15.2625 7.35627C14.4375 7.15002 10.9312 7.15002 10.9312 7.15002C10.9312 7.15002 7.49375 7.15002 6.6 7.35627C6.11875 7.49377 5.775 7.83753 5.6375 8.31878C5.5 9.21253 5.5 11 5.5 11C5.5 11 5.5 12.7875 5.70625 13.6813C5.84375 14.1625 6.1875 14.5063 6.66875 14.6438C7.49375 14.85 11 14.85 11 14.85C11 14.85 14.4375 14.85 15.3312 14.6438C15.8125 14.5063 16.1563 14.1625 16.2938 13.6813C16.5 12.7875 16.5 11 16.5 11C16.5 11 16.5 9.21253 16.225 8.31878ZM9.9 12.65V9.35003L12.7875 11L9.9 12.65Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "c--shared_icons airalo-icon-wrapper display-flex"
  }, [_vm.type === 'image' ? [_vm.loader === 'raw' ? [_c('span', {
    staticClass: "display-flex",
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.name}${_vm.themeName}.svg?raw`))
    }
  })] : [_vm.loader === 'data' ? _c('img', {
    attrs: {
      "src": require(`~/assets/images/svg/${_vm.name}${_vm.themeName}.svg?data`),
      "alt": _vm.alternativeText,
      "width": _vm.width,
      "height": _vm.height,
      "loading": _vm.loading
    }
  }) : _c('img', {
    attrs: {
      "src": require(`~/assets/images/svg/${_vm.name}${_vm.themeName}.svg`),
      "alt": _vm.alternativeText,
      "width": _vm.width,
      "height": _vm.height,
      "loading": _vm.loading
    }
  })]] : _vm.type === 'exclusive' ? [_vm._m(0)] : [_c('i', {
    class: ['airalo-icon', `icon-${_vm.name}${_vm.themeName}`]
  })]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "airmoney-pay-icon"
  }, [_c('i', {
    staticClass: "airalo-icon-airmoney tw-text-white tw-text-3 mr-3"
  }), _vm._v(" "), _c('span', {
    staticClass: "text"
  }, [_vm._v("Pay")])]);

}]

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.name, {
    tag: "component",
    staticClass: "c--shared_svg-icon"
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
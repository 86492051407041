export default class EventManager {
  constructor(app) {
    // const acceptableProviders = ['GTMProvider', 'ImpactProvider', 'ClevertapProvider']
    const acceptableProviders = []

    if (app.$utils?.firebaseFeatureFlag('analytics_web_enable_impact') === 'true') {
      acceptableProviders.push('ImpactProvider')
    }

    if (app.$utils?.firebaseFeatureFlag('analytics_web_enable_clevertap') === 'true') {
      acceptableProviders.push('ClevertapProvider')
    }

    if (app.$utils?.firebaseFeatureFlag('analytics_web_enable_gtm_v1') === 'true') {
      acceptableProviders.push('GTMProvider')
    }

    if (app.$utils?.firebaseFeatureFlag('analytics_web_enable_gtm_v2') === 'true') {
      acceptableProviders.push('GTMProviderV2')
    }

    this.app = app
    this.providers = []

    acceptableProviders.forEach((acc) => {
      const { [acc]: provider } = require(`./providers/${[acc]}`)

      this.providers.push(new provider(app))
    })
  }

  events(eventName, params) {
    this.providers.forEach((provider) => {
      if (typeof provider[eventName] !== 'undefined') {
        provider[eventName](params)
      }
    })
  }
}

<template>
  <div class="error-404-container">
    <div class="airgsm-container px-20 px-sm-30">
      <div class="d-grid grid-cols-1 grid-cols-sm-12 gap-30 pt-40 pb-30 pb-sm-40">
        <div class="col-span-sm-12 col-span-lg-10 col-start-lg-2 d-grid grid-cols-sm-12 grid-cols-lg-10 gap-30">
          <div class="error-404-left align-items-center col-span-sm-6 col-span-lg-5">
            <img src="~/static/assets/images/Error-Left.png" width="445" height="455" alt="" />
          </div>
          <div class="error-404-right d-flex flex-column col-span-sm-6 col-span-lg-4 col-start-lg-7">
            <span class="error-404-right-title">
              {{ $t('scenes.fourzerofour.error-title') }}
            </span>
            <span class="error-404-right-desc">
              {{ $t('scenes.fourzerofour.error-description') }}
            </span>

            <div class="error-404-right-helpful-links d-flex flex-column">
              <span>{{ $t('scenes.fourzerofour.helpful-links') }}</span>
              <nuxt-link
                v-for="(link, index) in helpfulLinks"
                :to="localePath(link.url)"
                :key="`error-404-item-${index}`"
              >
                {{ link.title }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    layout: 'errorLayout',

    data() {
      return {
        helpfulLinks: [
          {
            title: this.$t('components.footer.local-esims'),
            url: '/',
          },
          {
            title: this.$t('components.footer.regional-esims'),
            url: '/regional-esim',
          },
          {
            title: this.$t('components.footer.global-esims'),
            url: '/global-esim',
          },
          {
            title: this.$t('components.footer.my-esims'),
            url: '/my-esims',
          },
          {
            title: this.$t('components.footer.help-center'),
            url: '/help',
          },
          {
            title: this.$t('components.footer.blog'),
            url: '/blog',
          },
        ].map((item) => this.$utils.getShortLocalesCodeForHelp(item, 'url')),
      }
    },

    head() {
      return {
        title: this.$t('scenes.fourzerofour.error-title'),

        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('seo.homepage.description'),
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: this.$t('seo.homepage.keywords'),
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  .error-404-container {
    .error-404-left {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
      }
      img {
        max-width: 100%;
        height: auto;
        @media (max-width: 768px) {
          max-width: 75%;
        }
      }
    }
    .error-404-right {
      align-self: center;
      justify-self: center;
      &-title {
        color: #4a4a4a;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 32px;
      }
      &-desc {
        margin-top: 10px;
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;
      }
      &-helpful-links {
        margin-top: 20px;
        span {
          color: #4a4a4a;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
        }
        a {
          color: #8a8a8a;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
        }
        *:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }
</style>

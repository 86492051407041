const { validate } = require('validate-phone-number-node-js')

import { Validator } from 'vee-validate'
const phoneNumber = {
  getMessage: (field) => `${field} is not a valid phone number`,
  validate(value) {
    return Promise.resolve({ valid: validate(value) })
  },
}
Validator.extend('phone', phoneNumber)

Validator.extend('verify_password', {
  validate: (value) => {
    const strongRegex = new RegExp(
      /^(?=.*\p{L})(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|;:'",.<>?])[\p{L}0-9!@#$%^&*()\-_+=\[\]{}|;:'",.<>?]{8,128}$/u
    )
    return strongRegex.test(value)
  },
})

export default (ctx) => {
  const locale = ctx.app.i18n.locale === 'zh' ? 'zh_CN' : ctx.app.i18n.locale
  import(`vee-validate/dist/locale/${locale}`)
    .then((dict) => Validator.localize(locale, dict))
    .catch((_err) => null)
}

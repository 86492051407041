import urlJoin from 'url-join'

export const state = () => ({
  blog_posts: null,
  get_in_touch_email_address: '',
  media_centers: null,

  endpoints: {
    blog_posts: '/v2/blog-posts',
    blogs: '/v2/blogs',
    get_in_touch_email_address: '/v2/media-centers/contacts',
    in_the_news: '/v2/news',
    media_centers: '/v2/media-centers',
    news: '/v2/news',
    press_releases: '/v2/press-releases',
  },

  EMPTY_STATES: {
    news_search: {
      data: [],
      links: {
        first: '',
        last: '',
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: null,
        last_page: 1,
        path: '',
        per_page: 5,
        to: null,
        total: 0,
      },
    },
  },

  MOCKS: {
    media_centers: [
      {
        id: 3,
        key: 'airalo_secondary_logo',
        attachment: 'https://staging-airalo.gumlet.io/press-center/7a8c92e9-a40e-484a-8ea6-022344f83b0e.zip',
        created_at: '2023-09-12 11:06:29',
      },
      {
        id: 2,
        key: 'airalo_primary_logo',
        attachment: 'https://staging-airalo.gumlet.io/press-center/152dc15a-28d1-4681-bff3-7c1cff323d95.zip',
        created_at: '2023-09-12 11:06:19',
      },
      {
        id: 1,
        key: 'airalo_press_kit',
        attachment: 'https://staging-airalo.gumlet.io/press-center/e8a2ae7a-f415-41a4-aa45-9dc22bda6703.zip',
        created_at: '2023-09-12 11:06:11',
      },
    ],
  },
})

export const getters = {
  get_get_in_touch_email_address(state) {
    return state.get_in_touch_email_address
  },

  get_media_centers(state) {
    return state.media_centers
  },

  get_news_search_empty_state(state) {
    return state.EMPTY_STATES.news_search
  },
}

export const mutations = {
  set_blog_posts(state, newBlogPosts) {
    state.blog_posts = newBlogPosts
  },

  set_get_in_touch_email_address(state, payload) {
    state.get_in_touch_email_address = payload
  },

  set_media_centers(state, newMediaCenters) {
    state.media_centers = newMediaCenters
  },
}

export const actions = {
  async fetch_blog_posts({ state, dispatch }, parameters) {
    return new Promise((resolve, reject) => {
      dispatch('fetch_elements', {
        endpoint: state.endpoints.blog_posts,
        ...parameters,
      })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          console.error('fetch_blog_posts', { error })
          reject(error)
        })
    })
  },

  async fetch_elements(_, payload) {
    const parametersString = Object.keys(payload)
      .filter((key) => key !== 'endpoint' && payload[key] !== null)
      .map((key) => `${key}=${payload[key]}`)
      .join('&')
    const url = parametersString ? `${payload.endpoint}?${parametersString}` : payload.endpoint

    try {
      const { data } = await this.$axios.get(url)
      return data
    } catch (error) {
      return error
    }
  },

  async fetch_get_in_touch_email_address({ state, commit }) {
    try {
      const { data } = await this.$axios.get(state.endpoints.get_in_touch_email_address)
      commit('set_get_in_touch_email_address', data?.email || '')
    } catch (error) {
      return error
    }
  },

  fetch_media_centers({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.media_centers) {
        resolve(state.media_centers)
      } else {
        this.$axios
          .get(state.endpoints.media_centers)
          .then((response) => {
            const mediaCentersData = response.data?.data
            if (mediaCentersData?.length === 3) {
              commit('set_media_centers', mediaCentersData)
              resolve(mediaCentersData)
            } else {
              // commit('set_media_centers', state.MOCKS.media_centers)
              throw new Error(`Media Centers length = ${mediaCentersData.length}`)
            }
          })
          .catch((error) => {
            console.error('fetch_media_centers', { error })
            reject(error)
            // resolve(state.MOCKS.media_centers)
          })
      }
    })
  },

  async fetch_news({ state, dispatch }, parameters) {
    const url = urlJoin(state.endpoints.news, parameters.searchQuery || '')

    return new Promise((resolve, reject) => {
      dispatch('fetch_elements', {
        endpoint: url,
        ...parameters,
        searchQuery: null,
      })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          console.error('fetch_news', { error })
          reject(error)
        })
    })
  },

  async fetch_press_releases({ state, dispatch }, parameters) {
    return new Promise((resolve, reject) => {
      dispatch('fetch_elements', {
        endpoint: state.endpoints.press_releases,
        ...parameters,
      })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          console.error('fetch_press_releases', { error })
          reject(error)
        })
    })
  },
}

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22px",
      "height": "22px",
      "viewBox": "0 0 22 22",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('title', [_vm._v("_Assets/Icons/ic_facebook_social/Dark & Light Mode")]), _vm._v(" "), _c('defs', [_c('polygon', {
    attrs: {
      "id": "path-1",
      "points": "0 0 16 0 16 15.9028087 0 15.9028087"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "_Assets/Icons/ic_facebook_social/Dark-&-Light-Mode",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "Facebook",
      "transform": "translate(3.000000, 3.000000)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group-3"
    }
  }, [_c('mask', {
    attrs: {
      "id": "mask-2",
      "fill": "white"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": "#path-1"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-2"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M16,7.99968749 C16,3.58123364 12.4180714,-0.000625024416 7.99953122,-0.000625024416 C3.58099107,-0.000625024416 -0.000937554935,3.58123364 -0.000937554935,7.99968749 C-0.000937554935,11.9928747 2.92471825,15.302629 6.74945798,15.9028087 L6.74945798,10.3122778 L4.71808895,10.3122778 L4.71808895,7.99968749 L6.74945798,7.99968749 L6.74945798,6.23711864 C6.74945798,4.23204031 7.94387171,3.12449705 9.77135379,3.12449705 C10.6466707,3.12449705 11.56224,3.28075315 11.56224,3.28075315 L11.56224,5.24958006 L10.5533996,5.24958006 C9.5595445,5.24958006 9.24960447,5.86627603 9.24960447,6.49895699 L9.24960447,7.99968749 L11.4684845,7.99968749 L11.1137762,10.3122778 L9.24960447,10.3122778 L9.24960447,15.9028087 C13.0743442,15.302629 16,11.9928747 16,7.99968749",
      "id": "Fill-1",
      "fill": "#1877F2",
      "mask": "url(#mask-2)"
    }
  })]), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M11.1137762,10.3122778 L11.4684845,7.99968749 L9.24960447,7.99968749 L9.24960447,6.49895699 C9.24960447,5.86627603 9.5595445,5.24958006 10.5533996,5.24958006 L11.56224,5.24958006 L11.56224,3.28075315 C11.56224,3.28075315 10.6466707,3.12449705 9.77135379,3.12449705 C7.94387171,3.12449705 6.74945798,4.23204031 6.74945798,6.23711864 L6.74945798,7.99968749 L4.71808895,7.99968749 L4.71808895,10.3122778 L6.74945798,10.3122778 L6.74945798,15.9028087 C7.15677872,15.9667174 7.5742563,16 7.99953122,16 C8.42480614,16 8.84228373,15.9667174 9.24960447,15.9028087 L9.24960447,10.3122778 L11.1137762,10.3122778",
      "id": "Fill-4",
      "fill": "#FFFFFF"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "size": _vm.modalSize,
      "modal-class": "prompt-simple-wrapper",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.onHide,
      "hidden": _vm.onHidden
    },
    model: {
      value: _vm.modalView,
      callback: function ($$v) {
        _vm.modalView = $$v;
      },
      expression: "modalView"
    }
  }, [_c('box-type-simple', {
    class: {
      'close-animation': _vm.isCloseAnimation
    },
    attrs: {
      "shadowed": true,
      "rounded": true
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.overlayView,
      "variant": "white"
    }
  }, [_c('div', {
    staticClass: "c--modals_prompt-simple prompt-simple-content",
    attrs: {
      "data-testid": "prompt-item"
    }
  }, [_c('div', {
    staticClass: "prompt-simple-text-wrapper",
    class: {
      'tw-px-5 md:tw-px-7.5 tw-pt-5 md:tw-pt-7.5': !_vm.noPadding
    }
  }, [_vm.text && _vm.text.length > 0 ? _c('div', {
    staticClass: "prompt-simple-text",
    attrs: {
      "data-testid": "prompt-text-item"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.text))])]) : _vm._e(), _vm._v(" "), _vm._t("default")], 2), _vm._v(" "), _c('div', {
    staticClass: "prompt-simple-actions tw-px-5 md:tw-px-7.5 tw-mb-5 md:tw-mb-7.5",
    class: {
      'single-full-button': _vm.type === 'single-full-button',
      'double-full-buttons': _vm.type === 'double-full-buttons'
    },
    attrs: {
      "data-testid": "prompt-action-buttons"
    }
  }, [_vm._l(_vm.buttons, function (button, index) {
    return [button.isClose ? [_c('b-button', {
      key: `prompt-simple-content-button-${index}`,
      staticClass: "btn-prompt-simple",
      class: [button.class],
      attrs: {
        "variant": button.variant || 'prompt-simple-primary',
        "data-testid": `${button.text}-button`
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.$bvModal.hide(_vm.modalId);
        }
      }
    }, [_vm._v("\n                " + _vm._s(button.text) + "\n              ")])] : button.url && button.url.length ? [_c('b-button', {
      key: `prompt-simple-content-button-${index}`,
      staticClass: "btn-prompt-simple",
      class: [button.class],
      attrs: {
        "variant": button.variant || 'prompt-simple-primary',
        "data-testid": `${button.text}-button`,
        "to": _vm.localePath(button.url)
      }
    }, [_vm._v("\n                " + _vm._s(button.text) + "\n              ")])] : [_c('b-button', {
      key: `prompt-simple-content-button-${index}`,
      staticClass: "btn-prompt-simple",
      class: [button.class],
      attrs: {
        "variant": button.variant || 'prompt-simple-primary',
        "data-testid": `${button.text}-button`
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.clickButtonEvent($event, button);
        }
      }
    }, [_vm._v("\n                " + _vm._s(button.text) + "\n              ")])]];
  })], 2)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-lg-20 py-lg-20"
  }, [_c('div', {
    staticClass: "new-container mx-auto"
  }, [_c('nuxt')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.button ? _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default")], 2) : !_vm.to ? _c('span', {
    staticClass: "c--shared_link"
  }, [_vm._t("default")], 2) : _vm.to && _vm.external ? _c('a', {
    attrs: {
      "href": _vm.selfUrl ? _vm.to : _vm.localePath(_vm.to),
      "target": "_blank",
      "rel": "noopener"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default")], 2) : _c('nuxt-link', {
    attrs: {
      "to": _vm.selfUrl ? _vm.to : _vm.localePath(_vm.to)
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
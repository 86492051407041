import Cookies from 'cookies'
import ClientSideCookies from 'js-cookie'
import countryCodes from '@/lang/country-codes.js'

const DEFAULT_LOCALE = 'en'

export default async ({ app, store, req, res, $axios, route, redirect }, inject) => {
  const cookieOptions = {
    expires: new Date(Date.now() + 60 * 60 * 60 * 24 * 365),
    httpOnly: false,
  }

  if (process.server) {
    const cookies = new Cookies(req, res)

    if (route.path.startsWith('/en')) {
      cookies.set('Airalo.locale', DEFAULT_LOCALE, cookieOptions)
      redirect(route.path.replace('/en', '') || '/')
    }

    if (app.i18n.locale === 'en') {
      let locale = cookies.get('Airalo.locale')

      if (!locale) {
        const cfViewerCountry = req.headers['cf-ipcountry'] || req.headers['cloudfront-viewer-country']

        if (app.i18n.getBrowserLocale()) {
          locale = app.i18n.getBrowserLocale()
        } else if (cfViewerCountry) {
          locale = countryCodes.find((c) => c['alpha-2'] === cfViewerCountry)?.code || DEFAULT_LOCALE
        } else {
          locale = DEFAULT_LOCALE
        }
      }

      await app.i18n.setLocale(locale)
    }
  }

  ClientSideCookies.set('Airalo.locale', app.i18n.locale, cookieOptions)

  $axios.defaults.headers.common['accept-language'] = app.i18n.locale

  app.i18n.onLanguageSwitched = async (_oldLocale, _newLocale) => {
    $axios.defaults.headers.common['accept-language'] = _newLocale

    if (process.client) {
      await store.dispatch('fetch_footer_countries_links')

      store.commit('set_cr_key')
    }
  }

  app.i18n.currentLanguageName = () => {
    return app.i18n.locales.find((x) => x.code === app.i18n.locale)?.native
  }

  app.nuxt.defaultTransition.beforeEnter = () => {
    if (app.i18n.locale !== ClientSideCookies.get('Airalo.locale')) {
      ClientSideCookies.set('Airalo.locale', app.i18n.locale, cookieOptions)
    }

    app.i18n.finalizePendingLocaleChange()
  }

  app.router.options.scrollBehavior = async (to, from, savedPosition) => {
    if (to.name !== from.name) {
      await app.i18n.waitForPendingLocaleChange()
    }

    return savedPosition || { x: 0, y: 0 }
  }

  const dir = () => {
    return app.i18n.locales.find((x) => x.code === app.i18n.locale)?.dir
  }

  inject('dir', dir)
}

<template>
  <b-card
    no-body
    class="c--card-boxes_box-type-simple box-type-shadow-card"
    :class="[`type-${type}`, { shadowed, 'box-rounded': rounded }]"
  >
    <slot></slot>
  </b-card>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: false,
        default: 'normal',
      },
      shadowed: {
        type: Boolean,
        required: false,
        default: false,
      },
      rounded: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .box-type-shadow-card {
    background-color: #ffffff;
    border: none;

    &.shadowed {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    }

    &.box-rounded {
      border-radius: 7px;
    }

    &.type-big {
      &.box-rounded {
        border-radius: 22px;
      }
    }
  }
</style>

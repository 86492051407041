<template>
  <div class="px-lg-20 py-lg-20">
    <div class="new-container mx-auto">
      <nuxt />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { setSmartBannerPlatformVisibility } from '~/utils/smartbanner'

  import ClientSideCookies from 'js-cookie'
  import urlJoin from 'url-join'

  export default {
    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
      }),
    },
    head() {
      const isIos = (this.$device?.isIos || this.$ua?.isFromIpad()) ?? false
      const smartBannerTitle = this.$t(`components.store.banner.${isIos ? 'ios' : 'android'}-title`)
      const smartBannerDesc = this.$t(`components.store.banner.${isIos ? 'ios' : 'android'}-description`)

      const currentLocale = this.$i18n.locale
      const currentLang = this.$i18n.locales.find((v) => v.code === this.$i18n.locale)

      const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

      const allLocaleCodes = this.$i18n.locales.map((v) => v.locale)

      const listOfSpecialFonts = ['ar', 'he', 'hi', 'ja', 'ko', 'th']

      let link = []

      if (listOfSpecialFonts.includes(currentLocale)) {
        const isFontsIsExist = link.find((v) => v.hid === `stylesheet-${currentLocale}`)

        if (!isFontsIsExist) {
          link.push({
            hid: `stylesheet-${currentLocale}`,
            rel: 'stylesheet',
            href: `/fonts/ibm-plex-sans-${currentLocale}/fonts.css`,
          })
        }
      }

      link = [...link, ...i18nHead.link.filter((v) => allLocaleCodes.includes(v.hreflang))]

      let xDefault = i18nHead.link.find((v) => v.hreflang === 'x-default')

      link.unshift(xDefault)

      link.push({
        rel: 'canonical',
        href: urlJoin(process.env.APP_URL, this.$route.path),
      })

      link = link.map((v) => {
        if (v.rel === 'alternate') {
          const urlObj = new URL(v.href)
          urlObj.search = ''
          urlObj.hash = ''
          const newHref = urlObj.toString()

          return { ...v, href: newHref }
        }
        return v
      })

      return {
        title: this.$t('seo.homepage.title'),

        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('seo.homepage.description'),
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: this.$t('seo.homepage.keywords'),
          },
          { name: 'smartbanner:title', content: smartBannerTitle },
          { name: 'smartbanner:author', content: smartBannerDesc },
          {
            name: 'smartbanner:button',
            content: this.$t('components.store.banner.view-button'),
          },
          setSmartBannerPlatformVisibility(!this.isCoBranded),
        ],
        htmlAttrs: {
          lang: currentLang.code,
          dir: currentLang.dir,
        },
        bodyAttrs: {
          style: this.$dir() === 'rtl' ? `text-align: start;` : '',
        },
        link,
      }
    },

    methods: {
      smartBannerEvent(event) {
        const app_store = this.$device?.isIos || this.$ua?.isFromIpad() ? 'AppStore' : 'PlayStore'

        this.$utils.sendEvent('downloadBannerEvent', { event, app_store })
      },
      checkClickIdFromUrl() {
        const irClickId = this.$route.query.irclickid

        if (irClickId?.length) {
          ClientSideCookies.set('Airalo.irclickid', irClickId, {
            expires: new Date(Date.now() + 60 * 60 * 60 * 24 * 365),
            httpOnly: false,
          })
        }
      },
      checkDiscountCode() {
        const code = this.$route.query.code

        if (code) {
          window.localStorage.setItem('airalocode', code)
        }
      },
    },

    beforeDestroy() {
      document.removeEventListener('smartbanner.clickout', () => this.smartBannerEvent('click'))
      document.removeEventListener('smartbanner.view', () => this.smartBannerEvent('view'))
      document.removeEventListener('smartbanner.exit', () => this.smartBannerEvent('close'))
    },

    mounted() {
      this.checkClickIdFromUrl()
      this.checkDiscountCode()

      document.addEventListener('smartbanner.clickout', () => this.smartBannerEvent('click'))
      document.addEventListener('smartbanner.view', () => this.smartBannerEvent('view'))
      document.addEventListener('smartbanner.exit', () => this.smartBannerEvent('close'))
    },
  }
</script>

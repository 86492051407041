var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-404-container"
  }, [_c('div', {
    staticClass: "airgsm-container px-20 px-sm-30"
  }, [_c('div', {
    staticClass: "d-grid grid-cols-1 grid-cols-sm-12 gap-30 pt-40 pb-30 pb-sm-40"
  }, [_c('div', {
    staticClass: "col-span-sm-12 col-span-lg-10 col-start-lg-2 d-grid grid-cols-sm-12 grid-cols-lg-10 gap-30"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "error-404-right d-flex flex-column col-span-sm-6 col-span-lg-4 col-start-lg-7"
  }, [_c('span', {
    staticClass: "error-404-right-title"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('scenes.fourzerofour.error-title')) + "\n          ")]), _vm._v(" "), _c('span', {
    staticClass: "error-404-right-desc"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('scenes.fourzerofour.error-description')) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "error-404-right-helpful-links d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('scenes.fourzerofour.helpful-links')))]), _vm._v(" "), _vm._l(_vm.helpfulLinks, function (link, index) {
    return _c('nuxt-link', {
      key: `error-404-item-${index}`,
      attrs: {
        "to": _vm.localePath(link.url)
      }
    }, [_vm._v("\n              " + _vm._s(link.title) + "\n            ")]);
  })], 2)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-404-left align-items-center col-span-sm-6 col-span-lg-5"
  }, [_c('img', {
    attrs: {
      "src": require("static/assets/images/Error-Left.png"),
      "width": "445",
      "height": "455",
      "alt": ""
    }
  })]);

}]

export { render, staticRenderFns }
export const state = () => ({
  customer_reviews: [],
  loading: false,
  reviews_lang: '',
  why_airalo_reviews: [],
})

export const getters = {
  get_customer_reviews(state) {
    return state.customer_reviews
  },

  get_why_airalo_reviews(state) {
    return state.why_airalo_reviews
  },
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },

  SET_REVIEWS(state, reviews) {
    if (reviews.length > 0) {
      state.customer_reviews = reviews.filter(({ section }) => {
        return !section || section.indexOf('Review') !== -1
      })

      state.why_airalo_reviews = reviews.filter(({ section }) => {
        return section && section.indexOf('Why Airalo') !== -1
      })
    }
  },

  SET_REVIEWS_LANG(state, payload) {
    state.reviews_lang = payload
  },
}

export const actions = {
  async fetch_customer_reviews({ state, commit }, payload) {
    if (state.reviews_lang === payload && state.customer_reviews?.length) {
      return true
    }

    commit('SET_LOADING', true)

    try {
      let { data: reviews } = await this.$axios.get('v2/customer-reviews')
      commit('SET_REVIEWS', reviews)
      commit('SET_REVIEWS_LANG', payload)
    } catch (error) {
      console.error('store/reviews fetch_customer_reviews', { error })
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

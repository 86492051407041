export default async function (context) {
  if (context.$auth.loggedIn && process.client) {
    const refreshTokenEnabled = !!context.$cookiz.get('auth._refresh_strategy')
    const isAuthMigrateFeatureFlagEnabled = context.$featureFlag.isActive('web_auth_migrate_token')

    // if user doesn't have a refresh_token && logged in,
    // user holds an old token, and we need to get the newer pair of access_token + refresh_token
    if (!refreshTokenEnabled && isAuthMigrateFeatureFlagEnabled) {
      try {
        await context.$axios.post('/auth/new-token')
      } catch (e) {
        console.error({ error: e })
      }
    }
  }
}

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44px",
      "height": "28px",
      "viewBox": "0 0 44 28",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('rect', {
    attrs: {
      "id": "Rectangle",
      "x": "0",
      "y": "0",
      "width": "44",
      "height": "28"
    }
  }), _vm._v(" "), _c('rect', {
    attrs: {
      "id": "Rectangle",
      "fill": "#FF5F00",
      "fill-rule": "nonzero",
      "x": "16.0514053",
      "y": "3.38646601",
      "width": "11.8977926",
      "height": "21.227068"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M16.8068962,14 C16.8030871,9.85774341 18.7182342,5.94387008 22.0003393,3.38646601 C16.4254274,-0.964067322 8.41902198,-0.330863419 3.60815228,4.84105194 C-1.20271743,10.0129673 -1.20271743,17.9870327 3.60815228,23.1589481 C8.41902198,28.3308634 16.4254274,28.9640673 22.0003393,24.613534 C18.7182568,22.0561118 16.8031154,18.1422501 16.8068962,14 Z",
      "id": "Path",
      "fill": "#EB001B",
      "fill-rule": "nonzero"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M44,14 C44,19.1684144 41.0280226,23.8832607 36.3457504,26.1421798 C31.6634783,28.401099 26.0931104,27.807521 22.0003393,24.613534 C25.2793809,22.0536812 27.1937454,18.1413792 27.1937454,14 C27.1937454,9.8586208 25.2793809,5.94631879 22.0003393,3.38646601 C26.0931104,0.192478976 31.6634783,-0.401098977 36.3457504,1.85782017 C41.0280226,4.11673932 44,8.83158564 44,14 L44,14 Z",
      "id": "Path",
      "fill": "#F79E1B",
      "fill-rule": "nonzero"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M42.7027251,22.3690483 L42.7027251,21.9313229 L42.8792279,21.9313229 L42.8792279,21.8421461 L42.429718,21.8421461 L42.429718,21.9313229 L42.6062963,21.9313229 L42.6062963,22.3690483 L42.7027251,22.3690483 Z M43.5754188,22.3690483 L43.5754188,21.8412773 L43.437631,21.8412773 L43.2791486,22.2042922 L43.1206209,21.8412773 L42.9827576,21.8412773 L42.9827576,22.3690483 L43.0800551,22.3690483 L43.0800551,21.9709444 L43.2286831,22.3141674 L43.3295688,22.3141674 L43.4782006,21.9700757 L43.4782006,22.3690483 L43.5754188,22.3690483 Z",
      "id": "Shape",
      "fill": "#F79E1B",
      "fill-rule": "nonzero"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--shared_header_item-block header-item-block",
    attrs: {
      "dir": _vm.dir
    }
  }, [_vm.itemListType === 'partial' ? [_vm.item.image && _vm.item.image.name && _vm.item.image.name.length > 0 ? [_c('header-item', {
    class: {
      'mr-10': _vm.$dir() === 'ltr',
      'ml-10': _vm.$dir() === 'rtl'
    },
    attrs: {
      "image": _vm.item.image.name,
      "is-image-class": _vm.item.image.class,
      "dir": _vm.dir
    }
  })] : _vm._e(), _vm._v(" "), _vm._l(_vm.item.text.texts, function (text, textIndex) {
    return [text.url && text.url.name && text.url.name.length > 0 ? [_c('nuxt-link', {
      key: `header-item-block-${textIndex}`,
      staticClass: "d-inline-flex justify-content-start align-items-center",
      attrs: {
        "to": _vm.localePath(text.url),
        "data-testid": `${text.text}-btn`
      }
    }, [_c('header-item', {
      attrs: {
        "item": text,
        "text": text.text
      }
    })], 1)] : [_c('header-item', {
      key: `header-item-block-${textIndex}`,
      attrs: {
        "text": text.text,
        "dir": _vm.dir
      }
    })], _vm._v(" "), _vm.item.text.separator && textIndex < _vm.item.text.texts.length - 1 ? [_c('header-item', {
      key: `header-item-block-sep-${textIndex}`,
      staticClass: "ml-5px mr-5px",
      attrs: {
        "text": _vm.item.text.separator
      }
    })] : _vm._e()];
  }), _vm._v(" "), _vm.isSlot ? [_vm._t("default", null, {
    "data": _vm.item
  })] : _vm._e()] : _vm.itemListType === 'partial-block' ? [_c('nuxt-link', {
    staticClass: "d-inline-flex justify-content-start align-items-center",
    attrs: {
      "to": _vm.localePath(_vm.item.url)
    }
  }, [_vm.item.image && _vm.item.image.name && _vm.item.image.name.length > 0 ? [_c('header-item', {
    attrs: {
      "image": _vm.item.image.name,
      "is-image-class": _vm.item.image.class
    }
  })] : _vm._e(), _vm._v(" "), [_vm._l(_vm.item.text.texts, function (text, textIndex) {
    return _c('header-item', {
      key: `header-item-block-${textIndex}`,
      attrs: {
        "text": text.text
      }
    });
  }), _vm._v(" "), _vm.item.text.separator && _vm.textIndex < _vm.item.text.texts - 1 ? [_c('header-item', {
    key: `header-item-block-sep-${_vm.textIndex}`,
    attrs: {
      "text": _vm.item.text.separator
    }
  })] : _vm._e()], _vm._v(" "), _vm.isSlot ? [_vm._t("default", null, {
    "data": _vm.item
  })] : _vm._e()], 2)] : _vm.itemListType === 'none' ? [_c('header-item', {
    attrs: {
      "image": _vm.item.image && _vm.item.image.name && _vm.item.image.name.length > 0 ? _vm.item.image.name : '',
      "is-image-class": _vm.item.image ? _vm.item.image.class : false,
      "text": _vm.item.text,
      "dir": _vm.dir
    }
  }), _vm._v(" "), _vm.isSlot ? [_vm._t("default", null, {
    "data": _vm.item
  })] : _vm._e()] : [_c('nuxt-link', {
    staticClass: "d-inline-flex justify-content-start align-items-center",
    attrs: {
      "to": _vm.localePath(_vm.item.url)
    }
  }, [_c('header-item', {
    attrs: {
      "item": _vm.item,
      "image": _vm.item.image && _vm.item.image.name && _vm.item.image.name.length > 0 ? _vm.item.image.name : '',
      "is-image-class": _vm.item.image ? _vm.item.image.class : false,
      "text": _vm.item.text,
      "dir": _vm.dir
    }
  }), _vm._v(" "), _vm.isSlot ? [_vm._t("default", null, {
    "data": _vm.item
  })] : _vm._e()], 2)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
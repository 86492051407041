<template>
  <button
    v-if="isInit && !isHide"
    id="zendesk-button"
    :class="[
      'c--shared_zendesk-button',
      'position-fixed color-bg-orange border-none shadowed-secondary overflow-hidden support-button',
      {
        'right-20': $dir() === 'ltr',
        'left-20': $dir() === 'rtl',
      },
      {
        'bottom-20': !deviceType.isMobile,
        'bottom-77': deviceType.isMobile,
      },
    ]"
    @click="toggleZE"
  >
    <div
      :class="[
        'display-flex justify-content-center align-items-center w-100 h-100 position-absolute left-0 support-button-icon-wrapper',
        {
          'top-0': !isOpen,
          'top-full-negative': isOpen,
        },
      ]"
    >
      <template v-if="icon === 'message'">
        <span class="position-absolute centered-xy display-inline-flex color-bg-white support-button-icon"></span>
      </template>
      <template v-else>
        <svg
          position="left"
          class="absolute centered-xy color-text-white"
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
            fill="currentColor"
            transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
          ></path>
        </svg>
      </template>
    </div>
    <div
      :class="[
        'display-flex justify-content-center align-items-center w-100 h-100 position-absolute left-0 support-button-icon-wrapper',
        {
          'top-full': !isOpen,
          'top-0': isOpen,
        },
      ]"
    >
      <svg
        class="absolute centered-xy color-text-white"
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="13"
        viewBox="0 0 21 13"
        focusable="false"
        role="presentation"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="currentColor"
          d="M20.1005 2.7169L10.9931 11.8244C10.4724 12.3451 9.62815 12.3451 9.10745 11.8244L-8.00829e-06 2.7169L1.88561 0.831278L10.0503 8.99593L18.2149 0.831278L20.1005 2.7169Z"
        ></path>
      </svg>
    </div>
  </button>
</template>

<script>
  import JSCookies from 'js-cookie'

  export default {
    data() {
      return {
        isHide: true,
        isInit: false,
        isOpen: false,
        hasIFrameFound: false,
        chatShowFromRC: false,
        findIFrameInterval: null,
        viewWhiteList: [
          'index',
          'index-list-package',
          'my-esims-index',
          'my-esims-index-archived',
          'my-esims-detail-id',
          'my-esims-detail-id-installation-android-index-qr',
          'my-esims-detail-id-installation-android-index-manual',
          'my-esims-detail-id-installation-ios-index-qr',
          'my-esims-detail-id-installation-ios-index-manual',
          'profile',
        ],
        icon: 'message', // bubble, message
      }
    },

    computed: {
      deviceType() {
        return this.$store.getters.get_device_types
      },
    },

    methods: {
      toggleZE() {
        if (window.zE) {
          zE('messenger', this.isOpen ? 'close' : 'open')
        }
        return true
      },

      initZE() {
        this.isInit = true

        const selectedLanguage = this.$i18n.locales.find((v) => v.locale === this.$i18n.locale)
        zE('messenger:set', 'locale', selectedLanguage.iso)

        zE('messenger:set', 'cookies', true)
        zE('messenger:set', 'zIndex', 254133418)

        zE('messenger:on', 'open', (a, b, c) => {
          this.isOpen = true
        })
        zE('messenger:on', 'close', () => {
          this.isOpen = false
        })

        zE('messenger', 'show')
        this.isHide = false

        zE('messenger', 'open')
        zE('messenger', 'close')

        setTimeout(() => {
          this.findIFrameInterval = setInterval(() => {
            this.setCustomClassForIframe()
          }, 100)
        }, 100)

        if (this.$auth.loggedIn) {
          this.loginForUserOnZendesk()
        } else {
          const zdCookies = this.action_getZDAuthCookies()

          if (zdCookies) {
            this.action_removeZDAuthCookies()
          }
        }
      },

      setCustomClassForIframe() {
        const foundIFrames = document.querySelectorAll('div > div > iframe')

        if (foundIFrames.length > 0) {
          const zeIFrame = Array.from(foundIFrames).filter((v) => {
            const parentsCheck =
              v.parentNode?.parentNode?.tagName.toLowerCase() === 'div' &&
              v.parentNode?.parentNode?.style.visibility.toLowerCase() === 'visible'
            const stylesCheck = v.style.position === 'fixed' && v.style.zIndex === '254133418'

            if (parentsCheck && stylesCheck) {
              return v
            }
          })

          if (zeIFrame.length > 0) {
            this.hasIFrameFound = true
            zeIFrame[0].classList.add('ze-iframe')
            if (this.deviceType.isMobile) {
              zeIFrame[0].classList.add('ze-iframe-mobile')
            }
            clearInterval(this.findIFrameInterval)
          }
        }
      },

      hideZE() {
        if (window.zE) {
          zE('messenger', 'close')
          zE('messenger', 'hide')
        }

        this.isHide = true
      },

      checkZEACLoadedInterval(callback) {
        setTimeout(() => {
          const isLoaded = this.checkZEACLoaded()
          if (isLoaded) {
            if (callback && typeof callback === 'function') {
              callback()
            }
          }
        }, 1000)
      },

      checkZEACLoaded() {
        return window.zE && window.zEACLoaded
      },

      checkChatViewWithRemoteConfig() {
        this.isHide = !this.chatShowFromRC

        if (this.chatShowFromRC) {
          if (!window.zE && !window.zEACLoaded) {
            this.checkZEACLoadedInterval(() => {
              this.initZE()
            })
          } else {
            this.initZE()
          }
        } else {
          if (!window.zE && !window.zEACLoaded) {
            this.checkZEACLoadedInterval(() => {
              this.hideZE()
            })
          } else {
            this.hideZE()
          }
        }
      },

      checkChatViewWithRoutes() {
        if (!this.getRouteBaseName()) {
          return false
        }

        const isIncluded = this.viewWhiteList.map((v) => this.getRouteBaseName() === v).includes(true)
        return isIncluded
      },

      action_RouteIsInWhiteList() {
        if (!this.isInit) {
          this.initZE()
        } else {
          if (this.isHide) {
            zE('messenger', 'show')
            this.isHide = false
          }
        }
      },

      action_RouteIsNotInWhiteList() {
        if (this.isInit) {
          this.checkZEACLoadedInterval(() => {
            this.hideZE()
          })
        } else {
          this.hideZE()
        }
      },

      action_setZDAuthCookies(token, expires_at) {
        JSCookies.set('Airalo.ZDToken', token)
        JSCookies.set('Airalo.ZDTokenExp', expires_at)
      },

      action_removeZDAuthCookies() {
        JSCookies.remove('Airalo.ZDToken')
        JSCookies.remove('Airalo.ZDTokenExp')
      },

      action_getZDAuthCookies() {
        const token = JSCookies.get('Airalo.ZDToken')
        const expires_at = JSCookies.get('Airalo.ZDTokenExp')

        if (token && expires_at) {
          return { token, expires_at: parseFloat(expires_at) }
        }
        return null
      },

      async action_getUserJWTForZendesk() {
        try {
          const { data } = await this.$axios.get('/auth/zendesk')
          const { expires_at, token } = data

          return { expires_at, token }
        } catch (error) {
          console.log({ error })
          return null
        }
      },

      action_loginUserOnZendesk(token) {
        zE('messenger', 'close')
        zE('messenger', 'loginUser', (callback) => {
          callback(token)
        })
      },

      async loginForUserOnZendesk() {
        const isUserLoggedInOnZendesk = this.action_getZDAuthCookies()
        let zdToken = null
        let zeExpires = null

        const currentTimestamp = Math.floor(Date.now() / 1000)

        if (isUserLoggedInOnZendesk?.expires_at > currentTimestamp) {
          zdToken = isUserLoggedInOnZendesk.token
        } else {
          this.action_removeZDAuthCookies()

          const { expires_at, token } = await this.action_getUserJWTForZendesk()

          zdToken = token
          zeExpires = expires_at

          this.action_setZDAuthCookies(zdToken, zeExpires)
        }

        this.action_loginUserOnZendesk(zdToken)
      },

      async readRemoteConfig() {
        try {
          const webZendeskChatShow = this.$featureFlag.isActive('web_zendesk_chat_show')
          const webZendeskChatButtonType = this.$featureFlag.isActive('web_zendesk_chat_button_type')

          return { webZendeskChatShow, webZendeskChatButtonType }
        } catch (e) {
          return { webZendeskChatShow: true, webZendeskChatButtonType: 'message' }
        }
      },
      // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
      closeFC() {
        this.$utils.setFreshChatDisplayStyle('add')
        const freshChatButton = document.getElementById('fc_frame')

        if (freshChatButton) {
          window.fcWidget = null
          freshChatButton.innerHTML = null
        }
      },
      // @TODO END
    },

    async mounted() {
      const { webZendeskChatShow, webZendeskChatButtonType } = await this.readRemoteConfig()

      this.chatShowFromRC = webZendeskChatShow

      if (webZendeskChatButtonType?.length > 0) {
        this.icon = webZendeskChatButtonType
      }

      if (webZendeskChatShow) {
        this.$emit('onEnabled', true)

        // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
        this.closeFC()
        // @TODO END
      }

      const isRouteInWhiteList = this.checkChatViewWithRoutes()

      if (!isRouteInWhiteList) return false

      this.checkChatViewWithRemoteConfig()
    },

    beforeDestroy() {
      this.hideZE()
    },

    watch: {
      isOpen() {
        this.$utils.sendEvent('liveChat', this.isOpen ? 'start' : 'close')
      },

      $route(_val) {
        const isRouteInWhiteList = this.checkChatViewWithRoutes()
        const isZEACLoaded = this.checkZEACLoaded()

        if (isRouteInWhiteList && isZEACLoaded && this.chatShowFromRC) {
          this.action_RouteIsInWhiteList()
        }

        if (!isRouteInWhiteList && isZEACLoaded) {
          this.action_RouteIsNotInWhiteList()
        }
      },

      '$i18n.locale'(val) {
        if (window.zE && window.zEACLoaded && this.isInit && this.chatShowFromRC) {
          const selectedLanguage = this.$i18n.locales.find((v) => v.locale === this.$i18n.locale)
          this.hideZE()

          setTimeout(() => {
            zE('messenger:set', 'locale', selectedLanguage.iso)
            zE('messenger', 'show')
          }, 100)
        }
      },

      '$auth.loggedIn'(val, oldVal) {
        if (oldVal && !val) {
          this.action_removeZDAuthCookies()
          if (window.zE && window.zEACLoaded) {
            zE('messenger', 'logoutUser')
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .support-button {
    width: 60px;
    height: 60px;
    border-radius: 50% 7px 50% 50%;
    z-index: 9999999;

    [dir='rtl'] & {
      border-radius: 7px 50% 50% 50%;
    }

    &-icon {
      border-radius: 7px 7px 7px 2px;
      height: 17px;
      width: 38%;

      &-wrapper {
        transition: top 0.5s cubic-bezier(0.66, 0, 0.12, 1) 0s;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        left: 4px;
        border-radius: 2px 2px 2px 2px;
        border-top: 5px;
        background-color: #ff5f6d;
      }

      &::before {
        top: 5px;
        width: 65%;
      }

      &::after {
        top: 10px;
        width: 44%;
      }
    }
  }
</style>

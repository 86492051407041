export const state = () => ({
  invitations: [],
  loading: false,
})

export const mutations = {
  SET_INVITATIONS: (state, payload) => {
    state.invitations = payload
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload
  },
}

export const actions = {
  async fetch_invitations(context) {
    const invitations = await this.$axios.$get('/company-mode/invitations')
    context.commit('SET_INVITATIONS', invitations)
  },
  async join(context, id) {
    return this.$axios.$patch(`/company-mode/invitations/${id}/join`)
  },
  async leave(context, id) {
    return this.$axios.$patch(`/company-mode/invitations/${id}/leave`)
  },
  async send_pin(context, email) {
    return await this.$axios.$post('/company-mode/invitations/join-with-pin/send-pin ', { email })
  },
  async join_with_pin(context, payload) {
    return await this.$axios.$patch('/company-mode/invitations/join-with-pin', payload)
  },
}

export const getters = {
  get_pending(state) {
    return state.invitations.filter((v) => v.status === 'pending')
  },
  get_joined(state) {
    return state.invitations.filter((v) => v.status === 'joined')
  },
  is_loading(state) {
    return state.loading
  },
}

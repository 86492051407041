export default async ({ app }, inject) => {
  inject('featureFlag', {
    isActive(key) {
      if (!key) {
        return false
      }

      const flags = app.store.getters['firebaseFeatureFlags/get']?.flags

      if (flags) {
        return flags?.[key] === 'true'
      }

      return false
    },
    value(key) {
      if (!key) {
        return false
      }

      const flags = app.store.getters['firebaseFeatureFlags/get']?.flags

      if (flags) {
        return flags?.[key] ?? ''
      }

      return ''
    },
  })
}

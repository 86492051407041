var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.2",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 44 28",
      "width": "44",
      "height": "28"
    }
  }, [_c('title', [_vm._v("Footer-Paypal-svg")]), _vm._v(" "), _c('defs', [_c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "cp1"
    }
  }, [_c('path', {
    attrs: {
      "d": "m8 20.46h20.8v6.11h-20.8z"
    }
  })]), _vm._v(" "), _c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "cp2"
    }
  }, [_c('path', {
    attrs: {
      "d": "m8 20.46h20.8v6.11h-20.8z"
    }
  })]), _vm._v(" "), _c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "cp3"
    }
  }, [_c('path', {
    attrs: {
      "d": "m18.56 20.46h17.44v7.54h-17.44z"
    }
  })]), _vm._v(" "), _c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "cp4"
    }
  }, [_c('path', {
    attrs: {
      "d": "m18.56 20.46h17.44v7.54h-17.44z"
    }
  })])]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "_Assets/Icons/Payment-Method/ic_paypal_payment-method/Light-Mode"
    }
  }, [_c('g', {
    attrs: {
      "id": "paypal"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group-4"
    }
  }, [_c('g', {
    attrs: {
      "id": "Clip-2"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-Path: Fill-1",
      "clip-path": "url(#cp1)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Fill-1",
      "fill-rule": "evenodd",
      "fill": "#238ec2",
      "d": "m28.5 21q0.4 0.5 0.3 1.4-0.4 2.1-2.5 2.1h-0.7c-0.2 0-0.3 0.1-0.3 0.3l-0.3 1.6c0 0.1-0.1 0.2-0.2 0.2h-1.1c-0.1 0-0.2-0.1-0.2-0.2l0.9-5.7c0-0.1 0.2-0.2 0.3-0.2h2.2q1.1 0 1.6 0.5zm-1.5 0.9c-0.2-0.2-0.5-0.2-0.9-0.2h-0.2c-0.1 0-0.1 0-0.1 0.1l-0.3 1.5h0.4c0.5 0 1.1 0 1.2-0.8q0.1-0.4-0.1-0.6z"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-Path: Fill-3",
      "clip-path": "url(#cp2)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Fill-3",
      "fill-rule": "evenodd",
      "fill": "#253667",
      "d": "m12.9 21q0.5 0.5 0.3 1.4-0.3 2.1-2.5 2.1h-0.6c-0.2 0-0.3 0.1-0.3 0.3l-0.3 1.5c0 0.2-0.1 0.3-0.3 0.3h-1c-0.1 0-0.2-0.1-0.2-0.2l0.9-5.7c0-0.1 0.1-0.2 0.3-0.2h2.2q1.1 0 1.5 0.5zm-1.4 0.9c-0.2-0.2-0.6-0.2-1-0.2h-0.1c-0.1 0-0.2 0-0.2 0.1l-0.2 1.5h0.3c0.6 0 1.2 0 1.3-0.8q0.1-0.4-0.1-0.6z"
    }
  })])]), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Fill-5",
      "fill-rule": "evenodd",
      "fill": "#253667",
      "d": "m18 22.7l-0.5 3.6c0 0.2-0.2 0.3-0.3 0.3h-1c-0.1 0-0.1-0.1-0.1-0.2v-0.3c0 0-0.5 0.6-1.4 0.6-0.6 0-1-0.2-1.3-0.6-0.4-0.4-0.5-1-0.4-1.6 0.2-1.2 1.1-2.1 2.3-2.1 0.5 0 1 0.1 1.2 0.4l0.1 0.1v-0.2c0.1-0.1 0.1-0.2 0.2-0.2h1.1c0.1 0 0.2 0.1 0.1 0.2zm-1.8 1.1q-0.2-0.3-0.7-0.3c-0.6 0-1 0.4-1.1 1q-0.1 0.5 0.1 0.8 0.3 0.3 0.7 0.3c0.6 0 1.1-0.4 1.2-1.1q0.1-0.4-0.2-0.7z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Fill-6",
      "fill-rule": "evenodd",
      "fill": "#238ec2",
      "d": "m33.6 22.7l-0.6 3.6c0 0.2-0.1 0.3-0.3 0.3h-0.9c-0.1 0-0.2-0.1-0.2-0.2v-0.3c0 0-0.5 0.6-1.4 0.6-0.5 0-1-0.2-1.3-0.6-0.4-0.4-0.5-1-0.4-1.6 0.2-1.2 1.2-2.1 2.3-2.1 0.5 0 1 0.1 1.3 0.4v0.1l0.1-0.2c0-0.1 0.1-0.2 0.2-0.2h1c0.1 0 0.2 0.1 0.2 0.2zm-1.8 1.1q-0.3-0.3-0.7-0.3c-0.6 0-1.1 0.4-1.2 1q-0.1 0.5 0.2 0.8 0.2 0.3 0.7 0.3c0.6 0 1-0.4 1.2-1.1q0-0.4-0.2-0.7z"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Group-10"
    }
  }, [_c('g', {
    attrs: {
      "id": "Clip-8"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-Path: Fill-7",
      "clip-path": "url(#cp3)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Fill-7",
      "fill-rule": "evenodd",
      "fill": "#253667",
      "d": "m23.4 22.5h-1q-0.2 0-0.3 0.1l-1.4 2.2-0.6-2.1c-0.1-0.1-0.2-0.2-0.3-0.2h-1.1c-0.1 0-0.2 0.1-0.1 0.2l1.1 3.5-1.1 1.5c0 0.1 0 0.3 0.2 0.3h1q0.2 0 0.3-0.1l3.5-5.1c0.1-0.1 0-0.3-0.2-0.3z"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-Path: Fill-9",
      "clip-path": "url(#cp4)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Fill-9",
      "fill-rule": "evenodd",
      "fill": "#238ec2",
      "d": "m34.6 20.6l-0.9 5.8c0 0.1 0.1 0.2 0.2 0.2h0.9c0.2 0 0.3-0.1 0.3-0.3l0.9-5.6c0-0.1-0.1-0.2-0.2-0.2h-1c-0.1 0-0.2 0-0.2 0.1z"
    }
  })])]), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Fill-11",
      "fill-rule": "evenodd",
      "fill": "#238ec2",
      "d": "m28.8 4.6c0.2-1.4 0-2.3-0.8-3.2-0.8-1-2.3-1.4-4.3-1.4h-5.6c-0.4 0-0.7 0.3-0.8 0.7l-2.3 15c-0.1 0.3 0.2 0.5 0.5 0.5h3.4l-0.2 1.6c0 0.2 0.2 0.5 0.4 0.5h2.9c0.4 0 0.7-0.3 0.7-0.6l0.1-0.2 0.5-3.5v-0.2c0.1-0.4 0.4-0.6 0.7-0.6h0.5c2.8 0 5-1.2 5.7-4.5 0.2-1.4 0.1-2.6-0.6-3.4q-0.3-0.4-0.8-0.7z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Fill-12",
      "fill-rule": "evenodd",
      "fill": "#20274f",
      "d": "m28.8 4.6c0.2-1.4 0-2.3-0.8-3.2-0.8-1-2.3-1.4-4.3-1.4h-5.6c-0.4 0-0.7 0.3-0.8 0.7l-2.3 15c-0.1 0.3 0.2 0.5 0.5 0.5h3.4l0.9-5.5v0.1c0-0.4 0.4-0.7 0.8-0.7h1.6c3.3 0 5.8-1.3 6.5-5.1q0.1-0.2 0.1-0.4z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Fill-13",
      "fill-rule": "evenodd",
      "fill": "#253667",
      "d": "m20.7 4.7c0.1-0.3 0.2-0.5 0.4-0.6q0.2 0 0.3 0h4.4q0.8 0 1.5 0.1 0.2 0 0.4 0 0.1 0.1 0.3 0.1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0.2c0.2-1.4 0-2.3-0.8-3.2-0.8-1-2.3-1.4-4.3-1.4h-5.6c-0.4 0-0.7 0.3-0.8 0.7l-2.3 15c-0.1 0.3 0.2 0.5 0.5 0.5h3.4z"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22px",
      "height": "22px",
      "viewBox": "0 0 22 22",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('title', [_vm._v("BCBDB423-C0F7-418B-9926-AA48F46F9BBC")]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Symbols",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "Web/*_Assets/Icons/ic_linkedin/Light-Mode",
      "fill": "#0077B7",
      "fill-rule": "nonzero"
    }
  }, [_c('path', {
    attrs: {
      "d": "M17.5526702,3 L4.44732984,3 C3.648,3 3,3.648 3,4.44732984 L3,17.5526283 C3,18.352 3.648,19 4.44732984,19 L17.5526283,19 C18.352,19 19,18.352 19,17.5526283 L19,4.44732984 C19,3.648 18.352,3 17.5526702,3 Z M7.95107853,16.8154555 C7.95107853,17.0480838 7.76251309,17.2366492 7.52988482,17.2366492 L5.73696335,17.2366492 C5.50433508,17.2366492 5.31576963,17.0480838 5.31576963,16.8154555 L5.31576963,9.29960209 C5.31576963,9.06697382 5.50433508,8.87840838 5.73696335,8.87840838 L7.52988482,8.87840838 C7.76251309,8.87840838 7.95107853,9.06697382 7.95107853,9.29960209 L7.95107853,16.8154555 Z M6.63342408,8.1699267 C5.69273298,8.1699267 4.93013613,7.40732984 4.93013613,6.46663874 C4.93013613,5.52594764 5.69273298,4.76335079 6.63342408,4.76335079 C7.57411518,4.76335079 8.33671204,5.52594764 8.33671204,6.46663874 C8.33671204,7.40732984 7.57415707,8.1699267 6.63342408,8.1699267 Z M17.3208388,16.8493822 C17.3208388,17.0632461 17.1474346,17.2366492 16.9335707,17.2366492 L15.0096335,17.2366492 C14.7957696,17.2366492 14.6223665,17.0632461 14.6223665,16.8493822 L14.6223665,13.3240209 C14.6223665,12.7981152 14.7766283,11.0194764 13.248,11.0194764 C12.0622827,11.0194764 11.8217801,12.2369005 11.7734869,12.7832461 L11.7734869,16.8493822 C11.7734869,17.0632461 11.6001257,17.2366492 11.3862199,17.2366492 L9.52544503,17.2366492 C9.31158115,17.2366492 9.13817801,17.0632461 9.13817801,16.8493822 L9.13817801,9.26567539 C9.13817801,9.05181152 9.31158115,8.87840838 9.52544503,8.87840838 L11.3862199,8.87840838 C11.6000838,8.87840838 11.7734869,9.05181152 11.7734869,9.26567539 L11.7734869,9.9213822 C12.2131518,9.26157068 12.866555,8.75229319 14.2577592,8.75229319 C17.3384712,8.75229319 17.3208388,11.6304503 17.3208388,13.2118534 L17.3208388,16.8493822 L17.3208388,16.8493822 Z",
      "id": "Linkedin"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
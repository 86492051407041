const extractQueryParameter = (url, key) => {
  if (!url) {
    url = window.location.href
  }

  key = key.replace(/[\[\]]/g, '\\$&')

  const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }

  const result = results[2].replace(/%20/g, '+')

  return decodeURIComponent(result)
}

module.exports = extractQueryParameter

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--shared_header_airmoney",
    attrs: {
      "data-testid": "airmoney-header"
    }
  }, [_c('nuxt-link', {
    staticClass: "display-flex flex-column flex-sm-row justify-content-center align-items-end align-items-sm-center text-decoration-none color-text-primary",
    attrs: {
      "to": _vm.localePath({
        name: 'profile-index-airmoney'
      })
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.clickHandler.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('span', {
    staticClass: "display-flex display-sm-none"
  }, [_c('i', {
    class: ['airalo-icon-airmoney tw-text-zinc-500 tw-text-4', {
      'mr-3px': _vm.$dir() === 'ltr',
      'ml-3px': _vm.$dir() === 'rtl'
    }]
  })]), _vm._v(" "), _c('span', {
    staticClass: "display-none display-sm-inline-flex"
  }, [_c('i', {
    class: ['airalo-icon-airmoney tw-text-zinc-500 tw-text-4', {
      'mr-10': _vm.$dir() === 'ltr',
      'ml-10': _vm.$dir() === 'rtl'
    }]
  })]), _vm._v(" "), _c('span', {
    staticClass: "typo-h6 typo-sm-p font-weight-sm-normal"
  }, [_vm._v(_vm._s(_vm.$t('components.main.navigation.airmoney')))])]), _vm._v(" "), _c('span', {
    class: ['airmoney-box display-flex justify-content-center align-items-center tw-whitespace-nowrap border-default border-solid color-border-light border-sm-unset box-rounded px-10 px-sm-0 py-3px py-sm-0', {
      'ml-5px': _vm.$dir() === 'ltr',
      'mr-5px': _vm.$dir() === 'rtl'
    }],
    attrs: {
      "data-testid": "airmoney-value"
    }
  }, [_c('sup', {
    staticClass: "typo-input-heading"
  }, [_vm._v(_vm._s(_vm.userAirmoneyCurrencyCode))]), _vm._v(" "), _c('span', {
    staticClass: "typo-h3"
  }, [_vm._v(_vm._s(_vm.userAirmoneyAmount))])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
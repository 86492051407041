var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_c('Header'), _vm._v(" "), _c('div', {
    staticClass: "default-layout-content"
  }, [_c('nuxt', {
    ref: "page"
  }), _vm._v(" "), _vm.campaignPromotion && _vm.campaignPromotion.type === 'popup' ? _c('b-modal', {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    },
    model: {
      value: _vm.isCampaignPopupShown,
      callback: function ($$v) {
        _vm.isCampaignPopupShown = $$v;
      },
      expression: "isCampaignPopupShown"
    }
  }, [_c('div', {
    staticClass: "pt-4"
  }, [_vm.campaignPromotion.image ? _c('div', {
    staticClass: "px-3"
  }, [_c('div', {
    staticClass: "campaign-popup__image",
    style: `background-image: url(${_vm.campaignPromotion.image.url});`
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('h2', {
    staticClass: "tp-3 text-center"
  }, [_vm._v(_vm._s(_vm.campaignPromotion.title))]), _vm._v(" "), _c('div', {
    staticClass: "tp-5 text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.campaignPromotion.content)
    }
  })]), _vm._v(" "), _c('button', {
    staticClass: "d-block call-to-action dark mb-2 mx-auto btn-primary-hv",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.dismissCampaignPopup
    }
  }, [_vm._v("\n            " + _vm._s(_vm.campaignPromotion.button) + "\n          ")])])]) : _vm._e()], 1), _vm._v(" "), _vm.isCloseBottom && !_vm.isCoBranded ? [_c('Bottom', {
    key: `Bottom-${_vm.crKey}`
  }), _vm._v(" "), _vm.isMyESIMSPages ? [_c('client-only', [_c('div', {
    staticClass: "container-fluid px-20 px-sm-30 home-container-padding"
  }, [_c('div', {
    staticClass: "airgsm-container mx-auto"
  }, [_c('div', {
    staticClass: "d-grid grid-cols-1 grid-cols-lg-2 gap-20 gap-sm-30"
  }, [_c('MyESIMHelpCenter', {
    key: `MyESIMHelpCenter-${_vm.crKey}`,
    attrs: {
      "is-my-esims": true
    }
  }), _vm._v(" "), _c('BottomReferral', {
    key: `BottomReferral-myesims-${_vm.crKey}`,
    attrs: {
      "is-my-esims": true
    }
  })], 1)])])])] : [_c('BottomReferral', {
    key: `BottomReferral-${_vm.crKey}`,
    attrs: {
      "is-store": _vm.isHomePages
    }
  })]] : _vm._e()], 2), _vm._v(" "), _c('Footer', {
    key: `Footer-${_vm.crKey}`,
    attrs: {
      "is-footer-closed": _vm.isFooterClosed
    }
  }), _vm._v(" "), _c('client-only', [[_c('ZendeskButton', {
    on: {
      "onEnabled": _vm.onZendeskEnabled
    }
  })], _vm._v(" "), _vm.shouldDisplayHowLoyaltyProgramWorksModal ? [_c('HowLoyaltyProgramWorks', {
    attrs: {
      "view-modal": _vm.howLoyaltyProgramWorksModalView
    },
    on: {
      "onClickOk": _vm.onCloseHowLoyaltyProgramWorks
    }
  })] : _vm._e(), _vm._v(" "), _vm.shouldDisplayUserLeveledUpPopup ? [_c('UserLeveledUpModal', {
    attrs: {
      "userRanking": _vm.userRankingData,
      "viewUserRankImage": false
    },
    on: {
      "onCloseUserLeveledUpModal": _vm.onCloseUserLeveledUpModal
    }
  })] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
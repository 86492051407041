var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-ignore tw-inline-flex"
  }, [_vm.branding ? [_c('div', {
    staticClass: "tw-flex lg:tw-flex-col tw-items-center lg:tw-gap-2"
  }, [_c('div', {
    staticClass: "footer-ignore tw-flex tw-items-center tw-gap-3"
  }, [_c('img', {
    class: _vm.coBrandingImageClasses,
    attrs: {
      "src": _vm.branding.primary_logo,
      "alt": _vm.branding.name,
      "height": "auto",
      "width": "auto",
      "loading": "eager"
    }
  }), _vm._v(" "), _vm.branding.secondary_logo ? [_c('img', {
    class: _vm.coBrandingImageClasses,
    attrs: {
      "src": _vm.branding.secondary_logo,
      "alt": _vm.branding.name,
      "height": "auto",
      "width": "auto",
      "loading": "eager"
    }
  })] : _vm._e()], 2), _vm._v(" "), _c('div', {
    staticClass: "footer-ignore tw-flex lg:tw-hidden before:tw-content-['']",
    class: ['tw-relative tw-items-center', 'ltr:tw-pl-2.5 ltr:tw-ml-2.5 rtl:tw-pr-2.5 rtl:tw-mr-2.5', 'before:tw-absolute ltr:before:tw-left-0 rtl:before:tw-right-0 before:tw-h-[44px] before:tw-w-[1px] before:tw-bg-zinc-300']
  }, [_c('nuxt-link', {
    staticClass: "tw-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "tw-w-auto",
    attrs: {
      "src": "/assets/images/svg/powered-by-airalo-mobile-logo.svg",
      "alt": "Powered by Airalo mobile footer logo",
      "height": "47",
      "width": "62",
      "loading": "eager"
    }
  })], 1)], 1), _vm._v(" "), _c('nuxt-link', {
    staticClass: "tw-hidden lg:tw-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "tw-w-auto",
    attrs: {
      "src": "/assets/images/svg/powered-by-airalo-desktop-footer-logo.svg",
      "alt": "Powered by Airalo desktop footer logo",
      "height": "18",
      "width": "130",
      "loading": "eager"
    }
  })], 1)], 1)] : [_c('nuxt-link', {
    staticClass: "tw-inline-flex",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('img', {
    attrs: {
      "src": "/assets/images/svg/Footer-Logo-Horizontal.svg",
      "alt": "Airalo",
      "width": "95",
      "height": "28"
    }
  })])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="c--shared_header_item-block header-item-block" :dir="dir">
    <template v-if="itemListType === 'partial'">
      <template v-if="item.image && item.image.name && item.image.name.length > 0">
        <header-item
          :image="item.image.name"
          :is-image-class="item.image.class"
          :class="{
            'mr-10': $dir() === 'ltr',
            'ml-10': $dir() === 'rtl',
          }"
          :dir="dir"
        ></header-item>
      </template>
      <template v-for="(text, textIndex) in item.text.texts">
        <template v-if="text.url && text.url.name && text.url.name.length > 0">
          <nuxt-link
            :to="localePath(text.url)"
            :key="`header-item-block-${textIndex}`"
            class="d-inline-flex justify-content-start align-items-center"
            :data-testid="`${text.text}-btn`"
          >
            <header-item :item="text" :text="text.text"></header-item>
          </nuxt-link>
        </template>
        <template v-else>
          <header-item :text="text.text" :key="`header-item-block-${textIndex}`" :dir="dir"></header-item>
        </template>
        <template v-if="item.text.separator && textIndex < item.text.texts.length - 1">
          <header-item
            :text="item.text.separator"
            :key="`header-item-block-sep-${textIndex}`"
            class="ml-5px mr-5px"
          ></header-item>
        </template>
      </template>
      <template v-if="isSlot">
        <slot :data="item"></slot>
      </template>
    </template>
    <template v-else-if="itemListType === 'partial-block'">
      <nuxt-link :to="localePath(item.url)" class="d-inline-flex justify-content-start align-items-center">
        <template v-if="item.image && item.image.name && item.image.name.length > 0">
          <header-item :image="item.image.name" :is-image-class="item.image.class"></header-item>
        </template>
        <template>
          <header-item
            v-for="(text, textIndex) in item.text.texts"
            :key="`header-item-block-${textIndex}`"
            :text="text.text"
          ></header-item>
          <template v-if="item.text.separator && textIndex < item.text.texts - 1">
            <header-item :text="item.text.separator" :key="`header-item-block-sep-${textIndex}`"></header-item>
          </template>
        </template>
        <template v-if="isSlot">
          <slot :data="item"></slot>
        </template>
      </nuxt-link>
    </template>

    <template v-else-if="itemListType === 'none'">
      <header-item
        :image="item.image && item.image.name && item.image.name.length > 0 ? item.image.name : ''"
        :is-image-class="item.image ? item.image.class : false"
        :text="item.text"
        :dir="dir"
      ></header-item>
      <template v-if="isSlot">
        <slot :data="item"></slot>
      </template>
    </template>

    <template v-else>
      <nuxt-link :to="localePath(item.url)" class="d-inline-flex justify-content-start align-items-center">
        <header-item
          :item="item"
          :image="item.image && item.image.name && item.image.name.length > 0 ? item.image.name : ''"
          :is-image-class="item.image ? item.image.class : false"
          :text="item.text"
          :dir="dir"
        ></header-item>
        <template v-if="isSlot">
          <slot :data="item"></slot>
        </template>
      </nuxt-link>
    </template>
  </div>
</template>

<script>
  export default {
    components: {
      HeaderItem: () => import('~/components/shared/header/item'),
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
      dir: {
        type: String,
        required: false,
        default: '',
      },
      isSlot: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      itemListType() {
        const item = this.item
        const isBlock = item.url && item.url.length > 0
        const isPartial =
          !isBlock &&
          typeof item.text === 'object' &&
          typeof item.text.texts &&
          Array.isArray(item.text.texts) &&
          item.text.texts.length > 0
        const isPartialBlock =
          isBlock &&
          typeof item.text === 'object' &&
          typeof item.text.texts &&
          Array.isArray(item.text.texts) &&
          item.text.texts.length > 0

        if (isBlock && !isPartialBlock) return 'block'
        if (isPartial) return 'partial'
        if (isPartialBlock) return 'partial-block'
        return 'none'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .header-item-block {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    a {
      text-decoration: none;
    }
  }
</style>

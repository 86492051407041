var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isInit && !_vm.isHide ? _c('button', {
    class: ['c--shared_zendesk-button', 'position-fixed color-bg-orange border-none shadowed-secondary overflow-hidden support-button', {
      'right-20': _vm.$dir() === 'ltr',
      'left-20': _vm.$dir() === 'rtl'
    }, {
      'bottom-20': !_vm.deviceType.isMobile,
      'bottom-77': _vm.deviceType.isMobile
    }],
    attrs: {
      "id": "zendesk-button"
    },
    on: {
      "click": _vm.toggleZE
    }
  }, [_c('div', {
    class: ['display-flex justify-content-center align-items-center w-100 h-100 position-absolute left-0 support-button-icon-wrapper', {
      'top-0': !_vm.isOpen,
      'top-full-negative': _vm.isOpen
    }]
  }, [_vm.icon === 'message' ? [_c('span', {
    staticClass: "position-absolute centered-xy display-inline-flex color-bg-white support-button-icon"
  })] : [_c('svg', {
    staticClass: "absolute centered-xy color-text-white",
    attrs: {
      "position": "left",
      "width": "32px",
      "height": "32px",
      "viewBox": "0 0 24 24",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z",
      "fill": "currentColor",
      "transform": "translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
    }
  })])]], 2), _vm._v(" "), _c('div', {
    class: ['display-flex justify-content-center align-items-center w-100 h-100 position-absolute left-0 support-button-icon-wrapper', {
      'top-full': !_vm.isOpen,
      'top-0': _vm.isOpen
    }]
  }, [_c('svg', {
    staticClass: "absolute centered-xy color-text-white",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "13",
      "viewBox": "0 0 21 13",
      "focusable": "false",
      "role": "presentation"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "fill": "currentColor",
      "d": "M20.1005 2.7169L10.9931 11.8244C10.4724 12.3451 9.62815 12.3451 9.10745 11.8244L-8.00829e-06 2.7169L1.88561 0.831278L10.0503 8.99593L18.2149 0.831278L20.1005 2.7169Z"
    }
  })])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
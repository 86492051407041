const showConsoleErrors = process.env.APP_ENVIRONMENT === 'local'

function returnedJsonMatchesUxp1771(json) {
  const keysWithValue = Object.keys(json).reduce((acum, key) => {
    if (json[key]) {
      acum.push(key)
    }

    return acum
  }, [])

  return keysWithValue.length === 1 && keysWithValue[0] === 'status' && json[keysWithValue[0]] === 'notVerified'
}

export const state = () => ({
  endpoints: {
    packageIdentity: '/v2/users/me/packages/{packageID}/identity',
    userDefaultIdentities: '/v2/users/me/identities/default',
    userIdentities: '/v2/users/me/identities',
  },
  package_identity: null,
  user_default_identities: null,
  user_identities: null,
  verify_modal_data: {
    package_id: null,
    isOneTime: false,
    privacyInfo: {},
  },
})

export const getters = {
  get_package_identity(state) {
    return state.package_identity
  },
  get_user_identities(state) {
    return state.user_identities
  },
  get_user_default_identities(state) {
    return state.user_default_identities
  },
  get_verify_modal_data(state) {
    return state.verify_modal_data
  },
}

export const mutations = {
  RESET_PACKAGE_IDENTITY(state) {
    state.package_identity = null
  },
  SET_PACKAGE_IDENTITY(state, payload) {
    state.package_identity = payload
  },
  SET_USER_DEFAULT_IDENTITIES(state, payload) {
    state.user_default_identities = payload
  },
  SET_USER_IDENTITIES(state, payload) {
    state.user_identities = payload
  },
  SET_VERIFY_MODAL_DATA(state, payload) {
    state.verify_modal_data = payload
  },
}

export const actions = {
  async fetch_package_identity(context, packageID = null) {
    let currentPackageIdentity = null

    if (packageID) {
      const url = context.state.endpoints.packageIdentity.replaceAll('{packageID}', packageID)

      try {
        currentPackageIdentity = await this.$axios.$get(url)
      } catch (error) {
        if (showConsoleErrors) {
          console.error('fetch_package_identity', { error })
        }
      }
    }

    context.commit('SET_PACKAGE_IDENTITY', currentPackageIdentity)

    return currentPackageIdentity
  },
  async fetch_user_default_identities(context) {
    let userIdentitiesDefault = null

    try {
      const data = await this.$axios.$get(context.state.endpoints.userDefaultIdentities)

      if (!returnedJsonMatchesUxp1771(data)) {
        userIdentitiesDefault = data
      }
    } catch (error) {
      if (showConsoleErrors) {
        console.error('fetch_user_default_identities', { error })
      }
    }

    context.commit('SET_USER_DEFAULT_IDENTITIES', userIdentitiesDefault)
  },
  async fetch_user_identities(context) {
    let userIdentities = null

    try {
      userIdentities = await this.$axios.$get(context.state.endpoints.userIdentities)
    } catch (error) {
      userIdentities = null

      if (showConsoleErrors) {
        console.error('fetch_user_identities', { error })
      }
    }

    context.commit('SET_USER_IDENTITIES', userIdentities)
  },
}

import debounce from 'lodash.debounce'

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // START-NOSCAN
    const r = (Math.random() * 16) | 0
    // END-NOSCAN

    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

let utilsDetectDevice = null

const detectDeviceCallback = (payload) => {
  if (payload.isMobile) {
    document.documentElement.style.setProperty('--fc-widget-bottom', `72px`)
  } else {
    document.documentElement.style.setProperty('--fc-widget-bottom', `15px`)
  }
}

const detectDevice = (calback) => {
  let types = {
    isDesktop: true,
    isMobile: false,
    isTablet: false,
  }

  try {
    const { isDesktop, isMobile, isTablet } = utilsDetectDevice(window.navigator.userAgent)

    types = { isDesktop, isMobile, isTablet }

    return { ...types }
  } catch (err) {
    types = { isDesktop: true, isMobile: false, isTablet: false }
    return { ...types }
  } finally {
    if (calback && typeof calback === 'function') {
      calback(types)
    }
  }
}

export default ({ app, store }) => {
  if (localStorage) {
    if (!localStorage.getItem('sessionuuid')) {
      localStorage.setItem('sessionuuid', uuidv4())
    }
  }

  if (window?.navigator) {
    utilsDetectDevice = app.$utils.detectDevice

    if (utilsDetectDevice && typeof utilsDetectDevice === 'function') {
      const deviceResult = detectDevice(detectDeviceCallback)

      store.commit('set_device_types', deviceResult)

      window.addEventListener(
        'resize',
        debounce(() => {
          const deviceResult = detectDevice(detectDeviceCallback)

          store.commit('set_device_types', deviceResult)
        }, 100)
      )
    }
  }
}

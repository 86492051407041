var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "c--card-boxes_box-type-simple box-type-shadow-card",
    class: [`type-${_vm.type}`, {
      shadowed: _vm.shadowed,
      'box-rounded': _vm.rounded
    }],
    attrs: {
      "no-body": ""
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
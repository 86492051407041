import { addYears } from 'date-fns'
import jwt from 'jsonwebtoken'

const COOKIE_NAMES = {
  ACCESS_TOKEN: 'auth._token.local',
  ACCESS_TOKEN_EXP: 'auth._token_expiration',
  REFRESH_TOKEN: 'auth._refresh_token.local',
  REFRESH_STRATEGY: 'auth._refresh_strategy',
}

const ENDPOINTS = {
  REFRESH: 'auth/refresh',
  NEW_TOKEN: 'auth/new-token',
  LOGOUT: 'auth/logout',
  LOGIN: 'auth/login',
  SOCIAL_LOGIN: 'auth/social',
}

// 30 seconds in milliseconds
const MILLISECONDS_BEFORE_EXPIRE = 1 * 30 * 1000

const isTokenExpired = (tokenExpirationDate) => {
  const now = new Date()
  let expirationDate = new Date(tokenExpirationDate)

  const timeDifference = expirationDate - now
  return timeDifference < MILLISECONDS_BEFORE_EXPIRE
}

let newTokenAttempts = 0
let addedInterceptorsServer = false
let addedInterceptorsClient = false

// helpers for API requests traffic light system
let isRefreshing = false
let isLoggingOut = false
let pendingRequests = []

// excluded endpoints for which we don't attempt to refresh tokens
const whitelistedEndpoints = [ENDPOINTS.REFRESH, ENDPOINTS.NEW_TOKEN, ENDPOINTS.LOGOUT]

export default function ({ $axios, app, redirect, $cookiz, store }) {
  const removeAuthCookies = () => {
    $cookiz.remove('auth._token_expiration')
    $cookiz.remove('auth._token.local')
    $cookiz.remove('auth._refresh_strategy')
    $cookiz.remove('auth._refresh_token.local')
    $cookiz.remove('auth.provider')
  }

  const setAuthCookies = (accessToken, refreshToken) => {
    $cookiz.set(COOKIE_NAMES.ACCESS_TOKEN, `Bearer ${accessToken}`, {
      httpOnly: true,
      secure: true,
      expires: addYears(new Date(), 1),
      path: '/',
    })
    $cookiz.set(COOKIE_NAMES.REFRESH_TOKEN, refreshToken, {
      httpOnly: true,
      secure: true,
      expires: addYears(new Date(), 1),
      path: '/',
    })
    $cookiz.set(COOKIE_NAMES.REFRESH_STRATEGY, true, {
      secure: true,
      expires: addYears(new Date(), 1),
      path: '/',
    })

    const accessTokenExpirationClaim = jwt.decode(accessToken).exp
    if (accessTokenExpirationClaim) {
      $cookiz.set(COOKIE_NAMES.ACCESS_TOKEN_EXP, new Date(accessTokenExpirationClaim * 1000).toISOString(), {
        httpOnly: false,
        expires: addYears(new Date(), 1),
        path: '/',
      })
    }
  }

  if (process.server) {
    // add axios server interceptor
    if (!addedInterceptorsServer) {
      addedInterceptorsServer = true
      $axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const statusCode = error.response ? error.response.status : null

          const isWhiteListedUrl = whitelistedEndpoints.some((url) => error.config.url.includes(url))

          const refreshTokenCookie = $cookiz.get(COOKIE_NAMES.REFRESH_TOKEN)
          const accessTokenCookie = $cookiz.get(COOKIE_NAMES.ACCESS_TOKEN)

          const hasAtLeastOneTokenType = !!refreshTokenCookie || !!accessTokenCookie
          const shouldTransitionToken = statusCode === 401 && !isWhiteListedUrl && hasAtLeastOneTokenType
          const isAuthMigrateTokenFeatureEnabled = app.$featureFlag.isActive('web_auth_migrate_token')

          if (shouldTransitionToken) {
            try {
              const apiUrl = refreshTokenCookie ? ENDPOINTS.REFRESH : ENDPOINTS.NEW_TOKEN
              let body = {}
              if (apiUrl === ENDPOINTS.REFRESH) {
                body.refreshToken = refreshTokenCookie
              } else if (isAuthMigrateTokenFeatureEnabled) {
                newTokenAttempts++
              } else {
                return Promise.reject(error)
              }
              const { data } = await $axios.post(apiUrl, body)
              const { accessToken, refreshToken } = data

              // manually setting cookies is important
              // because the response from '/refresh' or '/new-token' doesn't reach browser from interceptor
              setAuthCookies(accessToken, refreshToken)

              error.config.headers = {
                ...error.config.headers,
                Authorization: `Bearer ${accessToken}`,
              }
              return $axios.request(error.config)
            } catch (e) {
              console.error({ e })
              removeAuthCookies()
              redirect(app.localePath('/'))
              return Promise.reject(error)
            }
          } else {
            return Promise.reject(error)
          }
        }
      )
    }
  } else {
    // new axios instance without interceptors for /refresh API only
    const refreshAxios = $axios.create()

    // add axios client interceptor
    if (!addedInterceptorsClient) {
      addedInterceptorsClient = true

      $axios.interceptors.request.use(
        async (config) => {
          if (isRefreshing) {
            // If a token refresh is happening, queue the request
            return new Promise((resolve) => {
              pendingRequests.push(() => resolve(config))
            })
          }

          if (config.url.includes(ENDPOINTS.LOGOUT)) {
            removeAuthCookies()
            return config
          }

          const accessTokenExpiration = $cookiz.get(COOKIE_NAMES.ACCESS_TOKEN_EXP)
          const isAccessTokenExpired = accessTokenExpiration ? isTokenExpired(accessTokenExpiration) : false
          const hasRefreshStrategyEnabled = !!$cookiz.get(COOKIE_NAMES.REFRESH_STRATEGY)

          if (isAccessTokenExpired && hasRefreshStrategyEnabled) {
            try {
              isRefreshing = true
              await refreshAxios.post(ENDPOINTS.REFRESH)
              isRefreshing = false

              pendingRequests.forEach((callback) => callback())
              pendingRequests = []
              return config
            } catch (e) {
              isRefreshing = false
              removeAuthCookies()
              await store.$auth.logout()
            }
          }
          return config
        },
        (error) => Promise.reject(error)
      )

      $axios.interceptors.response.use(
        (response) => {
          const filteredRequests = [ENDPOINTS.REFRESH, ENDPOINTS.NEW_TOKEN, ENDPOINTS.LOGIN, ENDPOINTS.SOCIAL_LOGIN]
          const isAllowedUrl = filteredRequests.some((url) => response.config.url.includes(url))
          if (response.config && isAllowedUrl) {
            const data = response.data
            if (data && data.refreshToken) {
              $cookiz.set(COOKIE_NAMES.REFRESH_STRATEGY, true, {
                secure: true,
                expires: addYears(new Date(), 1),
                path: '/',
              })
            }
          }
          return response
        },
        async (error) => {
          if (error.config && error.config.url.includes(ENDPOINTS.LOGOUT)) {
            isLoggingOut = false
            redirect(app.localePath('/'))
            return Promise.resolve()
          }

          const statusCode = error.response ? error.response.status : null

          if (statusCode === 401 && store.$auth.loggedIn && !isLoggingOut) {
            isLoggingOut = true
            try {
              await store.$auth.logout()
            } catch (logoutError) {
              console.error('Logout failed:', logoutError)
            } finally {
              isLoggingOut = false
            }
            return Promise.reject(error)
          }

          return Promise.reject(error)
        }
      )
    }
  }
}

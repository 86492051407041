import Vue from 'vue'
import moment from 'moment'

const getFormat = (format = 'yyyy-mm-dd') => {
  if (format === 'PPP') {
    return 'LL'
  }
  return format
}

const getLocale = () => {
  let locale = $nuxt?._i18n?.locale?.toLowerCase()
  if (locale === 'vls-be') {
    return 'nl-be'
  }
  if (locale === 'uz') {
    return 'uz-latn'
  }
  return locale
}

Vue.filter('dateFilter', (value, newFormat) => {
  const dateFormat = getFormat(newFormat)
  const dateLocale = getLocale()

  return moment(value).locale(dateLocale).format(dateFormat)
})

export default ({ app }, inject) => {
  inject('dateFormat', (value, newFormat, useLocalGMT = true) => {
    const dateFormat = getFormat(newFormat)
    const dateLocale = getLocale()

    /**
     * If useLocalGTM is false, remove the time from the time
     * and use only date to ensure that it will be set to GTM 0 and not the local timezone
     */

    if (!useLocalGMT) {
      const [date] = value.match(/^\d{4}-\d{2}-\d{2}/)

      if (date) {
        value = date
      }
    }

    return moment(value).locale(dateLocale).format(dateFormat)
  })
}

import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":false,"redirect":{"login":"/login","logout":"/","home":"/","callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","expires":365,"secure":true,"httpOnly":true}},"localStorage":false,"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/v4/auth/login","method":"post","propertyName":"token"},"logout":{"url":"auth/logout","method":"POST","propertyName":""},"user":{"url":"/v4/users/me","method":"get","propertyName":""}},"redirect":{"login":"/auth/login","logout":"/","callback":"/auth/callback","home":"/"},"tokenRequired":false,"tokenType":"Bearer","_name":"local"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"client_id":"956325725386-4505pvsrhg9k3e4b9es5ok0ht2ltlv8b.apps.googleusercontent.com","codeChallengeMethod":"","responseType":"code","user":false,"redirect_uri":"https://www.airalo.com/auth/callback","_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo","scope":["openid","profile","email"]}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"427219981442318","userinfo_endpoint":false,"scope":["public_profile","email"],"redirect_uri":"https://www.airalo.com/auth/callback","_name":"facebook","authorization_endpoint":"https://facebook.com/v2.12/dialog/oauth"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}

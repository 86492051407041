var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44px",
      "height": "28px",
      "viewBox": "0 0 44 28",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "amex",
      "transform": "translate(-0.000000, 0.000000)"
    }
  }, [_c('rect', {
    attrs: {
      "id": "Rectangle",
      "fill": "#FFFFFF",
      "x": "0",
      "y": "0",
      "width": "44",
      "height": "28",
      "rx": "2"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Group",
      "fill": "#0071CE",
      "fill-rule": "nonzero"
    }
  }, [_c('path', {
    attrs: {
      "d": "M40.3208191,24.9386667 L38.3686007,22.7733333 L36.3412969,24.9386667 L32.3617747,24.9386667 L32.3617747,24.9386667 L23.8020478,24.9386667 L23.8020478,14.8586667 L19.8225256,14.8586667 L24.778157,3.584 L29.5836177,3.584 L31.3105802,7.46666667 L31.3105802,3.584 L37.3174061,3.584 L38.3686007,6.496 L39.4197952,3.584 L44,3.584 L44,1.86666667 C44,0.821333333 43.1740614,0 42.1228669,0 L1.87713311,0 C0.825938567,0 0,0.821333333 0,1.86666667 L0,26.1333333 C0,27.1786667 0.825938567,28 1.87713311,28 L42.1228669,28 C43.1740614,28 44,27.1786667 44,26.1333333 L44,24.9386667 L40.3208191,24.9386667 Z",
      "id": "Path"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "transform": "translate(21.774744, 4.928000)"
    }
  }, [_c('polygon', {
    attrs: {
      "id": "Path",
      "points": "19.0716724 18.816 22.225256 18.816 18.0955631 14.4106667 22.225256 10.08 19.1467577 10.08 16.5187713 12.9173333 13.9658703 10.08 10.8122867 10.08 15.0170648 14.4853333 10.8122867 18.816 13.890785 18.816 16.5187713 15.9786667"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Path",
      "points": "5.78156997 16.8 5.78156997 15.456 10.7372014 15.456 10.7372014 13.44 5.78156997 13.44 5.78156997 12.096 10.8122867 12.096 10.8122867 10.08 3.37883959 10.08 3.37883959 18.816 10.8122867 18.816 10.8122867 16.8"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Path",
      "points": "19.8976109 8.81066667 22.1501706 8.81066667 22.1501706 0 18.5460751 0.0746666667 16.5938567 5.52533333 14.5665529 0.0746666667 10.8122867 0.0746666667 10.8122867 8.81066667 13.2150171 8.81066667 13.2150171 2.688 15.4675768 8.81066667 17.5699659 8.81066667 19.8976109 2.688"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M6.98293515,0.0746666667 L3.90443686,0.0746666667 L0,8.81066667 L2.62798635,8.81066667 L3.37883959,7.09333333 L7.4334471,7.09333333 L8.18430034,8.81066667 L10.887372,8.81066667 L6.98293515,0.0746666667 Z M4.20477816,5.07733333 L5.40614334,2.24 L6.60750853,5.07733333 L4.20477816,5.07733333 Z",
      "id": "Shape"
    }
  })])]), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Path",
      "fill": "#0071CE",
      "fill-rule": "nonzero",
      "points": "41.8225256 19.1146667 44 21.504 44 16.7253333"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * @param {boolean} show - Show or hide the smart banner
 * @param {function} show - Function to determine if the smart banner should be shown or hidden, returns a boolean
 * @param {object} options - Options object, containing visibilityPlatforms controlling which platforms the smart banner should be visible on
 * @param {string} options.visibilityPlatforms - Comma separated list of platforms the smart banner should be visible on
 */

export const setSmartBannerPlatformVisibility = function setSmartBannerPlatformVisibilityHandler(
  show = true,
  options = { visibilityPlatforms: 'android,ios' }
) {
  const { visibilityPlatforms } = options

  if (!show) {
    return { hid: 'smartbanner-enabled-platforms', name: 'smartbanner:enabled-platforms', content: 'none' }
  }

  if (!visibilityPlatforms || typeof visibilityPlatforms !== 'string') {
    return {
      hid: 'smartbanner-enabled-platforms',
      name: 'smartbanner:enabled-platforms',
      content: 'android,ios',
    }
  }

  const filteredPlatforms = visibilityPlatforms.split(',')?.filter((platform) => ['android', 'ios'].includes(platform))
  const content = filteredPlatforms.length ? filteredPlatforms.join(',') : 'none'

  if (typeof show === 'function') {
    return show()
      ? {
          hid: 'smartbanner-enabled-platforms',
          name: 'smartbanner:enabled-platforms',
          content,
        }
      : { hid: 'smartbanner-enabled-platforms', name: 'smartbanner:enabled-platforms', content: 'none' }
  }

  return {
    hid: 'smartbanner-enabled-platforms',
    name: 'smartbanner:enabled-platforms',
    content,
  }
}

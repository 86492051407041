var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.branding ? _c('div', {
    staticClass: "tw-px-5 md:tw-px-7.5",
    style: `background-color: ${_vm.branding.background_color}; color: ${_vm.branding.text_color};`
  }, [_c('div', {
    staticClass: "tw-max-w-[1120px] tw-mx-auto tw-py-[7px] tw-text-[12px]/[1] tw-font-bold"
  }, [_c('div', {
    staticClass: "tw-flex tw-gap-5"
  }, [_c('a', {
    staticClass: "tw-flex tw-items-center tw-gap-1 tw-text-current hover:tw-text-current",
    attrs: {
      "href": _vm.branding.partner_url,
      "target": "_blank"
    }
  }, [_c('svg', {
    staticClass: "rtl:tw-rotate-180",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "10",
      "viewBox": "0 0 8 10",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M-2.18557e-07 5L7.5 0.669872L7.5 9.33013L-2.18557e-07 5Z",
      "fill": "currentColor"
    }
  })]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$t('components.branding.topline.back-link-text', {
    name: _vm.branding.partner_url_text
  })))])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
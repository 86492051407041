import Vue from 'vue'
import * as animationData from '~/assets/animation/preloader.json'
import LottiePreloader from '~/components/lottie-preloader.vue'

const animationDataDefault = animationData.default

const publicPath = process.env.GUMLET_CDN
const baseURL = `${!publicPath.includes('http') ? 'https://' : ''}${publicPath}`

const allAssetUrls = animationDataDefault.assets.map((v) => v.u).join('|')

const isBaseURLIncludes = allAssetUrls.includes(baseURL)

if (!isBaseURLIncludes) {
  animationDataDefault.assets.forEach((item) => {
    item.u = `${baseURL}${item.u}`
  })
}

LottiePreloader.data = () => {
  return {
    preloaderData: animationDataDefault,
  }
}

Vue.component('v-lottie', LottiePreloader)

import ClientSideCookies from 'js-cookie'

export const state = () => ({
  available_currencies: null,
  current_currency: null,
  default_currency: 'USD',
  general_settings: null,

  endpoints: {
    currencies: '/v4/currencies',
    general_settings: '/v4/general/settings',
  },

  safetyNet: {
    currencies: [
      {
        name: 'United States Dollar',
        code: 'USD',
        symbol: '$',
        title: 'United States Dollar (USD) $',
        selected: true,
      },
    ],
  },
})

export const getters = {
  get_available_currencies(state) {
    return state.available_currencies
  },

  get_currency_cookie(_state) {
    return (process.client && ClientSideCookies.get('Airalo.currency')) || null
  },

  get_current_currency_code(state) {
    return state.current_currency?.code
  },

  get_current_currency_symbol(state) {
    return state.current_currency?.symbol
  },

  get_current_currency_symbol_code(state) {
    if (!state.current_currency) return ''
    return state.current_currency.symbol
      ? `${state.current_currency.symbol} ${state.current_currency.code}`
      : state.current_currency.code
  },

  get_current_currency_title(state) {
    return state.current_currency?.title
  },

  get_min_airmoney_amount(state) {
    return state.general_settings?.airmoney_min
  },

  get_referral_get_amount(state) {
    return state.general_settings?.referral_amount
  },

  get_referral_give_amount(state) {
    return state.general_settings?.referral_amount
  },

  get_selected_currency_code_from_list(state) {
    const sc = (state.available_currencies || []).find((currency) => currency.selected)
    return sc?.code
  },

  is_currency_available: (state) => (currencyCode) => {
    return state.available_currencies.some((currency) => currency.code === currencyCode)
  },
}

export const mutations = {
  set_available_currencies(state, newCurrencies) {
    state.available_currencies = newCurrencies
  },

  set_currency_cookie(state) {
    if (process.client) {
      ClientSideCookies.set('Airalo.currency', state.current_currency.code, {
        expires: 365,
        httpOnly: false,
      })
    }
  },

  set_currency_header(state) {
    this.$axios.setHeader('Accept-Currency', state.current_currency.code)
  },

  set_current_currency_from_list(state) {
    state.current_currency = state.available_currencies.find((curr) => curr.selected)
  },

  set_current_currency_object(state, newCurrency) {
    state.current_currency = newCurrency
  },

  set_general_settings(state, newSettings) {
    state.general_settings = newSettings
  },
}

export const actions = {
  async fetch_currencies({ state, getters, commit, dispatch }, currencyCookie) {
    try {
      const { data } = await this.$axios.get(state.endpoints.currencies)
      commit('set_available_currencies', data.data)
    } catch (error) {
      console.error('multiCurrency/fetch_currencies: failed to load endpoint, using safetyNet data')
      commit('set_available_currencies', state.safetyNet.currencies)
    }

    let currentCurrencyCode = currencyCookie || getters.get_currency_cookie
    let shouldUpdateProfile = false
    if (!currentCurrencyCode || !getters.is_currency_available(currentCurrencyCode)) {
      currentCurrencyCode = getters.get_selected_currency_code_from_list
      shouldUpdateProfile = true
    }
    await dispatch('set_currency', {
      newCurrencyCode: currentCurrencyCode,
      shouldUpdateProfile,
    })
  },

  async fetch_general_settings({ state, commit }) {
    try {
      const { data } = await this.$axios.get(state.endpoints.general_settings, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
      })
      commit('set_general_settings', data.data)
    } catch (error) {
      console.error('multiCurrency/fetch_general_settings', { error })
    }
  },

  async set_currency({ state, getters, rootGetters, commit, dispatch }, { newCurrencyCode, shouldUpdateProfile }) {
    if (newCurrencyCode !== getters.get_current_currency_code) {
      const newCurrency = state.available_currencies.find((curr) => curr.code === newCurrencyCode)
      if (newCurrency) {
        commit('set_current_currency_object', newCurrency)
      } else {
        console.error(`multiCurrency/set_currency: couldn't find currency object for code ${newCurrencyCode}`)
      }
    }
    commit('set_currency_cookie')
    commit('set_currency_header')
    if (shouldUpdateProfile && rootGetters.is_authenticated) {
      await dispatch('userSettings/post_settings', { currency: newCurrencyCode }, { root: true })
    }
  },
}

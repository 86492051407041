<template>
  <prompt-simple-modal
    :modal-view="promptSimpleModalView"
    :overlay-view="false"
    :no-padding="true"
    :button="buttonPrimary"
    :button-second="buttonSecondary"
    type="single-full-button"
    @onCloseModal="closeModal"
    @onClickButton="closeModal"
  >
    <div class="c--loyalty-program_user-leveled-up mb-20">
      <div class="display-flex justify-content-center align-items-center relative overflow-hidden minh-110 z-index-1">
        <div class="double-gradients" :style="headerBackgroundStyle"></div>
        <div class="user-level-image" v-if="viewUserRankImage && userRanking.image">
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :data-src="userRanking.image.url"
            width="90"
            alt=""
            v-lazy-load
          />
        </div>
        <div class="box-texts color-text-white text-center relative z-index-10">
          <p class="typo-p-small mb-10" data-testid="level-upgrade-title">{{ headerTitle }}</p>
          <p class="typo-h3 mb-0" data-testid="new-level">{{ headerSubTitle }}</p>
        </div>
      </div>
      <div class="display-flex flex-column justify-content-center align-items-center px-20 px-xs-30 mt-20">
        <p class="typo-h2 text-center mb-0">{{ bodySubTitle }}</p>
        <p class="typo-p text-center color-text-secondary mb-0 mt-10">{{ bodySubContent }}</p>
      </div>
    </div>
  </prompt-simple-modal>
</template>

<script>
  import PromptSimpleModal from '~/components/modals/prompt-simple.vue'

  export default {
    components: {
      PromptSimpleModal,
    },

    props: {
      userRanking: {
        type: Object,
        required: true,
      },
      viewUserRankImage: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data: () => ({
      promptSimpleModalView: true,
    }),

    mounted() {
      this.$utils.sendEvent('loyaltyChangeV2', {
        previous_loyalty_level: this.$store.state.userSettings.loyalty_level,
        current_loyalty_level: this.userRanking,
      })
    },

    computed: {
      bodySubContent() {
        return this.rankingChanged
          ? this.$t('components.user.leveled.up.new-level-content', { cashbackRate: this.userRanking.cashback })
          : this.$t('components.user.leveled.up.current-level-content', { cashbackRate: this.userRanking.cashback })
      },

      bodySubTitle() {
        return this.rankingChanged
          ? this.$t('components.user.leveled.up.new-level-subtitle')
          : this.$t('components.user.leveled.up.current-level-subtitle')
      },

      headerBackgroundStyle() {
        return this.userRanking
          ? `background: linear-gradient(${this.backgroundGradientDegrees}deg, ${this.userRanking.gradient_start} 0%, ${this.userRanking.gradient_end} 100%);`
          : ''
      },

      backgroundGradientDegrees() {
        return this.$dir() === 'rtl' ? 270 : 90
      },

      headerSubTitle() {
        return this.userRanking.title
      },

      headerTitle() {
        return this.rankingChanged
          ? this.$t('components.user.leveled.up.new-level-title')
          : this.$t('components.user.leveled.up.current-level-title')
      },

      buttonPrimary() {
        return {
          text: this.$t('components.tooltip.got-it-button'),
          callbackName: 'closeModal',
          isClose: true,
        }
      },

      buttonSecondary() {
        return null
      },

      rankingChanged() {
        return this.userRanking?.ranking_changed
      },
    },

    methods: {
      closeModal() {
        this.promptSimpleModalView = false
        this.$emit('onCloseUserLeveledUpModal')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .user-level-image {
    position: absolute;
    right: -25px;
    bottom: 10px;
    z-index: 9;

    [dir='rtl'] & {
      right: unset;
      left: -25px;
    }
  }
</style>

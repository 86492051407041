import createApp from './app.js'

import remoteConfigService from './service.remoteConfig.js'
import appCheckService from './service.appCheck.js'

const appConfig = {"apiKey":"AIzaSyD1iNMzBl_mtqB5jq3cVvFwnlO0sfTkc_8","authDomain":"airsims.firebaseapp.com","projectId":"airsims","storageBucket":"airsims.appspot.com","messagingSenderId":"956325725386","appId":"1:956325725386:web:97ab475720bfcd86252033"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.client) {
    servicePromises = [
      remoteConfigService(session, firebase, ctx, inject),
      appCheckService(session, firebase, ctx, inject),

    ]
  }

  const [
    remoteConfig,
    appCheck
  ] = await Promise.all(servicePromises)

  const fire = {
    remoteConfig: remoteConfig,
    appCheck: appCheck
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('prompt-simple-modal', {
    attrs: {
      "modal-view": _vm.promptSimpleModalView,
      "overlay-view": false,
      "no-padding": true,
      "button": _vm.buttonPrimary,
      "button-second": _vm.buttonSecondary,
      "type": "single-full-button"
    },
    on: {
      "onCloseModal": _vm.closeModal,
      "onClickButton": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "c--loyalty-program_user-leveled-up mb-20"
  }, [_c('div', {
    staticClass: "display-flex justify-content-center align-items-center relative overflow-hidden minh-110 z-index-1"
  }, [_c('div', {
    staticClass: "double-gradients",
    style: _vm.headerBackgroundStyle
  }), _vm._v(" "), _vm.viewUserRankImage && _vm.userRanking.image ? _c('div', {
    staticClass: "user-level-image"
  }, [_c('img', {
    directives: [{
      name: "lazy-load",
      rawName: "v-lazy-load"
    }],
    attrs: {
      "src": "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      "data-src": _vm.userRanking.image.url,
      "width": "90",
      "alt": ""
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "box-texts color-text-white text-center relative z-index-10"
  }, [_c('p', {
    staticClass: "typo-p-small mb-10",
    attrs: {
      "data-testid": "level-upgrade-title"
    }
  }, [_vm._v(_vm._s(_vm.headerTitle))]), _vm._v(" "), _c('p', {
    staticClass: "typo-h3 mb-0",
    attrs: {
      "data-testid": "new-level"
    }
  }, [_vm._v(_vm._s(_vm.headerSubTitle))])])]), _vm._v(" "), _c('div', {
    staticClass: "display-flex flex-column justify-content-center align-items-center px-20 px-xs-30 mt-20"
  }, [_c('p', {
    staticClass: "typo-h2 text-center mb-0"
  }, [_vm._v(_vm._s(_vm.bodySubTitle))]), _vm._v(" "), _c('p', {
    staticClass: "typo-p text-center color-text-secondary mb-0 mt-10"
  }, [_vm._v(_vm._s(_vm.bodySubContent))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
export const state = () => ({
  behaviors: [],
  viewer_country: null,

  MOCKS: {
    behaviors: {
      restrict_checkout_by_buyer_location: {
        warning_seen: false,
      },
    },
  },
})

export const getters = {
  get_is_checkout_by_buyer_location_restricted(state) {
    return Object.keys(state.behaviors).includes('restrict_checkout_by_buyer_location')
  },

  get_restrict_checkout_by_buyer_location_warning_seen(state) {
    return state.behaviors.restrict_checkout_by_buyer_location.warning_seen
  },

  get_viewer_country(state) {
    return state.viewer_country
  },
}

export const mutations = {
  set_behaviors(state, payload) {
    state.behaviors = payload
  },

  set_viewer_country(state, payload) {
    state.viewer_country = payload
  },

  set_warning_seen_for_behaviour(state, payload) {
    const behaviour = state.behaviors[payload]
    if (behaviour) {
      behaviour.warning_seen = true
    }
  },
}

export const actions = {
  fetch_behaviors({ state, commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('set_behaviors', state.viewer_country === 'AE' ? state.MOCKS.behaviors : [])
        resolve()
      } catch (error) {
        console.error('fetch_behaviors', { error })
        reject(error)
      }
    })
  },

  post_warning_seen({ state, commit }, behaviourName) {
    commit('set_warning_seen_for_behaviour', behaviourName)
  },
}

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$route.query.web_view ? _c('span', {
    staticClass: "c--shared_footer"
  }, [!_vm.isFooterClosed ? [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasMobileFooter,
      expression: "hasMobileFooter"
    }],
    class: ['mobile-bottom-menu', {
      'menu-down-hide': !_vm.mobileBottomMenuView
    }]
  }, [_c('div', {
    staticClass: "bottom-menu-nodes-container"
  }, _vm._l(_vm.mobileTabs, function (footerNode, index) {
    return _c('nuxt-link', {
      key: 'footerNode-' + index,
      staticClass: "bottom-menu-node",
      attrs: {
        "tag": "div",
        "to": _vm.localePath(footerNode.link)
      }
    }, [_c('i', {
      class: ['tw-block', footerNode.ico]
    }), _vm._v(" "), _c('div', {
      staticClass: "bottom-menu-node-text tw-text-2.5 tw-leading-[11px] tw-pt-px"
    }, [_vm._v("\n            " + _vm._s(footerNode.label) + "\n          ")])]);
  }), 1)]), _vm._v(" "), _c('footer', {
    class: ['footer-container home-container-padding', {
      'mb-64': _vm.deviceType.isMobile
    }]
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "new-container mx-auto"
  }, [_c('div', {
    staticClass: "footer-top-links d-grid gap-15 gap-sm-30 grid-cols-1 grid-cols-sm-12"
  }, [_c('div', {
    class: ['footer-top-links-block', _vm.infoColClass]
  }, [_c('FooterLogo', {
    attrs: {
      "branding": _vm.branding
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "footer-link-info"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.footer.short-info')) + "\n              ")])], 1), _vm._v(" "), _vm.footerCountriesLinks && _vm.footerCountriesLinks.length > 0 ? _c('div', {
    class: ['footer-top-links-block footer-collapse-mobile', _vm.topLinksColClass]
  }, [_c('span', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: `accordion-link-countires`,
      expression: "`accordion-link-countires`"
    }],
    staticClass: "linkTitle mb-0"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.footer.popular-countries')) + "\n              ")]), _vm._v(" "), _c('b-collapse', {
    attrs: {
      "id": "accordion-link-countires"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column collapse-list"
  }, _vm._l(_vm.footerCountriesLinks, function (item, index) {
    return _c('SharedLink', {
      key: `footer_top_links_categories_mobile_${index}`,
      staticClass: "footer-top-links-item-link",
      attrs: {
        "to": _vm.localePath(`/${item.slug}-esim`)
      },
      on: {
        "click": function ($event) {
          return _vm.sendEventHandler(item);
        }
      }
    }, [_vm._v("\n                    " + _vm._s(item.title) + "\n                  ")]);
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.topLinks, function (link, index) {
    return _c('div', {
      key: `footer_top_links_${index}_mobile`,
      class: ['footer-top-links-block footer-collapse-mobile', _vm.topLinksColClass]
    }, [_c('span', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: `accordion-link-${index}`,
        expression: "`accordion-link-${index}`"
      }],
      staticClass: "linkTitle mb-0"
    }, [_vm._v("\n                " + _vm._s(link.title) + "\n              ")]), _vm._v(" "), _c('b-collapse', {
      attrs: {
        "id": `accordion-link-${index}`
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column collapse-list"
    }, _vm._l(link.subLinks, function (item, subIndex) {
      return _c('SharedLink', {
        key: `footer_top_links_${index}_mobile_${subIndex}`,
        class: [item.button ? 'footer-top-links-item-button' : 'footer-top-links-item-link'],
        attrs: {
          "to": item.selfUrl ? item.link : _vm.localePath(item.link),
          "external": item.external,
          "self-url": item.selfUrl,
          "button": item.button
        },
        on: {
          "click": _vm.cookieSettingsHandler
        }
      }, [_vm._v("\n                    " + _vm._s(item.title) + "\n                  ")]);
    }), 1)])], 1);
  }), _vm._v(" "), _vm.footerCountriesLinks && _vm.footerCountriesLinks.length > 0 ? _c('div', {
    class: ['footer-top-links-block footer-no-collapse', _vm.topLinksColClass]
  }, [_c('span', {
    staticClass: "linkTitle"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.footer.popular-countries')) + "\n              ")]), _vm._v(" "), _vm._l(_vm.footerCountriesLinks, function (item, index) {
    return _c('SharedLink', {
      key: `footer_top_links_categories_${index}`,
      staticClass: "footer-top-links-item-link",
      attrs: {
        "to": _vm.localePath(`/${item.slug}-esim`)
      },
      on: {
        "click": function ($event) {
          return _vm.sendEventForCountryHandler(item);
        }
      }
    }, [_vm._v("\n                " + _vm._s(item.title) + "\n              ")]);
  })], 2) : _vm._e(), _vm._v(" "), _vm._l(_vm.topLinks, function (link, index) {
    return _c('div', {
      key: `footer-top-links-item-${index}`,
      class: ['footer-top-links-block footer-no-collapse', _vm.topLinksColClass]
    }, [_c('span', {
      staticClass: "linkTitle"
    }, [_vm._v("\n                " + _vm._s(link.title) + "\n              ")]), _vm._v(" "), _vm._l(link.subLinks, function (item, subIndex) {
      return [item.button ? _c('button', {
        key: `footer_top_links_${index}_${subIndex}_button`,
        staticClass: "footer-top-links-item-button",
        on: {
          "click": function ($event) {
            return _vm.cookieSettingsHandler(item);
          }
        }
      }, [_vm._v("\n                  " + _vm._s(item.title) + "\n                ")]) : _c('SharedLink', {
        key: `footer_top_links_${index}_${subIndex}_link`,
        staticClass: "footer-top-links-item-link",
        attrs: {
          "to": item.link,
          "external": item.external,
          "self-url": item.selfUrl
        },
        on: {
          "click": function ($event) {
            return _vm.sendEventHandler(item);
          }
        }
      }, [_vm._v("\n                  " + _vm._s(item.title) + "\n                ")])];
    })], 2);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "footer-bottom-links"
  }, [_c('div', {
    staticClass: "footer-bottom-links-left"
  }, [_vm._l(_vm.bottomLeftLinks, function (item, index) {
    return _c('SharedLink', {
      key: 'footer_bottom_links_' + index,
      attrs: {
        "to": item.link,
        "external": item.external || false,
        "self-url": item.selfUrl || false
      },
      on: {
        "click": function ($event) {
          return _vm.sendEventHandler(item);
        }
      }
    }, [_vm._v("\n                " + _vm._s(item.title) + "\n              ")]);
  }), _vm._v(" "), _c('span', {
    staticClass: "footer-language-btn",
    on: {
      "click": function ($event) {
        _vm.languagesModalView = true;
      }
    }
  }, [_c('i', {
    class: ['airalo-icon-globe tw-text-zinc-500 tw-text-4', {
      'mr-10 mr-md-5px': _vm.currentLangText.native.length > 0 && (_vm.currentLangText.dir === 'ltr' || _vm.currentLangText.dir === ''),
      'ml-10 ml-md-5px': _vm.currentLangText.native.length > 0 && _vm.currentLangText.dir === 'rtl'
    }],
    attrs: {
      "data-testid": `${_vm.currentLangText.native}-footer-language`
    }
  }), _vm._v(" "), _vm.currentLangText.native.length > 0 ? [_vm._v("\n                  " + _vm._s(_vm.currentLangText.native) + "\n                ")] : _vm._e()], 2), _vm._v(" "), _vm.currentCurrency ? _c('span', {
    staticClass: "footer-currency-btn",
    attrs: {
      "data-testid": "footer-currency-button"
    },
    on: {
      "click": function ($event) {
        _vm.currenciesModalView = true;
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.currentCurrency) + "\n              ")]) : _vm._e(), _vm._v(" "), _c('currencies-modal', {
    attrs: {
      "modal-view": _vm.currenciesModalView,
      "currencies": _vm.currencies,
      "title": _vm.$t('components.currencies.modal.title'),
      "search-input-placeholder": _vm.$t('placeholder.currencies.modal.currency'),
      "empty-text": _vm.$t('global.no-currency-available')
    },
    on: {
      "onCloseCurrenciesModal": _vm.onCloseCurrenciesModal
    }
  }), _vm._v(" "), _c('languages-modal', {
    attrs: {
      "modal-view": _vm.languagesModalView,
      "languages": _vm.$i18n.locales,
      "title": _vm.$t('components.languages.modal.title'),
      "search-input-placeholder": _vm.$t('placeholder.languages.modal.language'),
      "empty-text": _vm.$t('global.no-language-available')
    },
    on: {
      "onCloseLanguagesModal": function ($event) {
        _vm.languagesModalView = false;
      }
    }
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "footer-bottom-links-right"
  }, [_c('span', {
    staticClass: "footer-follow-text"
  }, [_vm._v(_vm._s(_vm.$t('components.footer.follow')))]), _vm._v(" "), _vm._l(_vm.socials, function (social, index) {
    return _c('a', {
      key: 'footer-social-nav-' + index,
      staticClass: "footer-social-link d-flex",
      attrs: {
        "target": "_blank",
        "href": social.link
      }
    }, [_c('SvgIcon', {
      attrs: {
        "name": social.name
      }
    })], 1);
  })], 2)])])]), _vm._v(" "), _c('div', {
    staticClass: "footer-payment-methods-container tw-bg-white"
  }, _vm._l(_vm.payments, function (paymentsRow, prIdx) {
    return _c('div', {
      staticClass: "footer-payment-methods"
    }, _vm._l(paymentsRow, function (payment, index) {
      return _c('span', {
        key: 'footer-bottom-icons-' + index,
        staticClass: "footer-bottom-icons d-flex"
      }, [_c('SvgIcon', {
        attrs: {
          "name": payment
        }
      })], 1);
    }), 0);
  }), 0)])] : _vm._e(), _vm._v(" "), _vm.branding ? _c('UiBrandingTopline', {
    attrs: {
      "branding": _vm.branding
    }
  }) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
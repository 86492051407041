import EventManager from '~/utils/eventManager'

import DeviceDetector from 'device-detector-js'

export default async ({ app }, inject) => {
  await app.$fire?.remoteConfig?.fetchAndActivate()

  inject('utils', {
    replaceTranslationHrefLinks(string) {
      return string.replace(/href="(.*?)"/g, (m, $1) => {
        if (string.indexOf('/help/') > -1) {
          return `href="${$1}"`
        }

        return `href="${app.i18n.locale === 'en' ? '' : '/' + app.i18n.locale}${$1}"`
      })
    },

    replaceTranslationLinks(string) {
      return `${app.i18n.locale === 'en' ? '/' : '/' + app.i18n.locale + '/'}${string}`
    },

    sendEvent(eventName, obj) {
      const eventManager = new EventManager(app)

      eventManager['events'](eventName, obj)
    },

    replaceAnimationKeys(data, list) {
      let lastData = data

      list.forEach((v) => {
        lastData = lastData.replace(v.regex, v.value)
      })

      return lastData
    },

    setFreshChatDisplayStyle(value) {
      if (value === 'add') {
        document.documentElement.style.setProperty('--fc-widget-display', 'none')
      } else {
        document.documentElement.style.removeProperty('--fc-widget-display')
      }
    },

    detectDevice(ua) {
      const innerWidth = window.innerWidth

      const deviceDetector = new DeviceDetector()

      const device = deviceDetector.parse(ua)

      const type = device.device.type && device.device.type.length > 0 ? device.device.type : null

      let isDesktop = type
        ? (type === 'desktop' || (type !== 'smartphone' && type !== 'phablet' && type !== 'tablet')) && innerWidth > 575
        : app.$ua.isFromPc() && innerWidth > 575

      let isMobile = type
        ? type === 'smartphone' || type === 'phablet' || innerWidth <= 575
        : app.$ua.isFromSmartphone() || app.$ua.isFromMobilephone() || innerWidth <= 575

      let isTablet = type
        ? type === 'tablet' || (innerWidth > 575 && innerWidth < 768)
        : app.$ua.isFromTablet() && innerWidth > 575

      return {
        isDesktop,
        isMobile,
        isTablet,
      }
    },

    sanitizeCoontents(elementTag, content) {
      // START-NOSCAN
      const regex = new RegExp(`<(${elementTag})>(.*?)<\/(${elementTag})>`, 'g')
      const replaceRegex = new RegExp(`<\/?(${elementTag})>`, 'g')
      // END-NOSCAN
      const result = content.match(regex).map(function (val) {
        return val.replace(replaceRegex, '')
      })

      return result
    },

    getShortLocalesCodeForHelp(item, key = null) {
      const routeKey = key || 'route'
      if (item[routeKey] === '/help' && app.i18n.locale !== 'en') {
        let localeForHelp = app.i18n.locale.toLowerCase() || 'en'
        const shortLocale =
          localeForHelp === 'en' ? '' : localeForHelp === 'es-419' ? '/es' : `/${app.i18n.locale.toLowerCase()}`

        item[routeKey] = `/help${shortLocale}`
      }
      return item
    },

    isNullObject(val = null) {
      return typeof val === 'object' && !val
    },

    getPackagesPlainType(plainTypesOfPackages = []) {
      const somePackageIsDVT = plainTypesOfPackages.includes('data-voice-text')

      const isThereAnotherPlainType =
        plainTypesOfPackages.filter((value) => !['data-voice-text'].some((element) => value.includes(element))).length >
        0

      if (somePackageIsDVT && isThereAnotherPlainType) {
        return 'multiple'
      } else {
        return 'all'
      }
    },

    firebaseFeatureFlag(key) {
      return app.$fire?.remoteConfig?.getValue(key)?._value
    },

    async activateFirebaseSdk() {
      await app.$fire?.remoteConfig?.fetchAndActivate()
    },

    getFirebaseSdkFeatureFlag(key, responseBoolean = false) {
      if (responseBoolean) {
        return app.$fire?.remoteConfig?.getValue(key)?._value === 'true'
      }

      return app.$fire?.remoteConfig?.getValue(key)?._value ?? ''
    },

    getEmptyMoneyObject() {
      const currencyCode = app.store.getters['multiCurrency/get_current_currency_code']
      const currencySymbol = app.store.getters['multiCurrency/get_current_currency_symbol']

      return {
        amount: '0.00',
        minor_amount: 0,
        formatted: `* ${currencyCode} ${currencySymbol}0.00`,
        currency: {
          code: currencyCode,
          symbol: currencySymbol,
        },
      }
    },

    MOCK_transformToMoneyObject(endpointAmount) {
      try {
        const floatAmount = parseFloat(endpointAmount)
        const stringAmount = floatAmount.toFixed(2)
        const integerAmount100 = Math.round(floatAmount * 100) // For '2.01', parseInt(...) would return 200
        const stringAmount100 = integerAmount100.toString()
        const backToOriginString = (integerAmount100 / 100).toString()
        const currencyCode = app.store.getters['multiCurrency/get_current_currency_code']
        const currencySymbol = app.store.getters['multiCurrency/get_current_currency_symbol']
        const returnedObj = {
          amount: stringAmount,
          minor_amount: integerAmount100,
          formatted: `* ${currencyCode} ${currencySymbol}${stringAmount}`,
          currency: {
            code: currencyCode,
            symbol: currencySymbol,
          },
        }

        if (endpointAmount.toString() !== backToOriginString) {
          console.warn('MOCK_transformToMoneyObject', endpointAmount, backToOriginString)
        }

        return returnedObj
      } catch (error) {
        console.error('MOCK_transformToMoneyObject', error)
      }
    },
  })
}

<template>
  <div>
    <span>
      <Header />

      <div class="default-layout-content">
        <nuxt ref="page" />
        <b-modal
          v-if="campaignPromotion && campaignPromotion.type === 'popup'"
          v-model="isCampaignPopupShown"
          hide-header
          hide-footer
          centered
        >
          <div class="pt-4">
            <div class="px-3" v-if="campaignPromotion.image">
              <div class="campaign-popup__image" :style="`background-image: url(${campaignPromotion.image.url});`" />
            </div>
            <div class="mt-4 mb-4">
              <h2 class="tp-3 text-center">{{ campaignPromotion.title }}</h2>
              <div class="tp-5 text-center" v-html="campaignPromotion.content" />
            </div>
            <button
              type="button"
              class="d-block call-to-action dark mb-2 mx-auto btn-primary-hv"
              @click="dismissCampaignPopup"
            >
              {{ campaignPromotion.button }}
            </button>
          </div>
        </b-modal>
      </div>

      <template v-if="isCloseBottom && !isCoBranded">
        <Bottom :key="`Bottom-${crKey}`" />
        <template v-if="isMyESIMSPages">
          <client-only>
            <div class="container-fluid px-20 px-sm-30 home-container-padding">
              <div class="airgsm-container mx-auto">
                <div class="d-grid grid-cols-1 grid-cols-lg-2 gap-20 gap-sm-30">
                  <MyESIMHelpCenter :is-my-esims="true" :key="`MyESIMHelpCenter-${crKey}`" />
                  <BottomReferral :is-my-esims="true" :key="`BottomReferral-myesims-${crKey}`" />
                </div>
              </div>
            </div>
          </client-only>
        </template>
        <template v-else>
          <BottomReferral :is-store="isHomePages" :key="`BottomReferral-${crKey}`" />
        </template>
      </template>
    </span>
    <Footer :key="`Footer-${crKey}`" :is-footer-closed="isFooterClosed"></Footer>
    <client-only>
      <template>
        <ZendeskButton @onEnabled="onZendeskEnabled" />
      </template>

      <template v-if="shouldDisplayHowLoyaltyProgramWorksModal">
        <HowLoyaltyProgramWorks
          :view-modal="howLoyaltyProgramWorksModalView"
          @onClickOk="onCloseHowLoyaltyProgramWorks"
        />
      </template>
      <template v-if="shouldDisplayUserLeveledUpPopup">
        <UserLeveledUpModal
          :userRanking="userRankingData"
          :viewUserRankImage="false"
          @onCloseUserLeveledUpModal="onCloseUserLeveledUpModal"
        />
      </template>
    </client-only>
  </div>
</template>

<script>
  import urlJoin from 'url-join'
  import { format } from 'date-fns'
  import { mapGetters } from 'vuex'
  import { hydrateWhenIdle } from 'vue-lazy-hydration'
  import { setSmartBannerPlatformVisibility } from '~/utils/smartbanner'

  import ClientSideCookies from 'js-cookie'

  import Footer from '~/components/shared/footer/index.vue'
  import Header from '~/components/shared/header/index.vue'
  import HowLoyaltyProgramWorks from '~/components/loyalty-program/how-loyalty-program-works-modal.vue'
  import UserLeveledUpModal from '~/components/loyalty-program/user-leveled-up.vue'
  import ZendeskButton from '~/components/shared/zendesk-button.vue'

  export default {
    middleware: 'jwt-middleware',
    components: {
      Bottom: hydrateWhenIdle(() => import('~/components/bottom/bottom.vue')),
      BottomReferral: hydrateWhenIdle(() => import('~/components/bottom/referral.vue')),
      Footer,
      Header,
      HowLoyaltyProgramWorks,
      MyESIMHelpCenter: hydrateWhenIdle(() => import('~/components/bottom/help-center.vue')),
      UserLeveledUpModal,
      ZendeskButton,
    },

    data() {
      return {
        freshChatButton: null,
        howLoyaltyProgramWorksModalView: false,
        isCampaignPopupShown: true,
        isZendeskEnabled: false,
        refreshKey: 1,
        shouldDisplayHowLoyaltyProgramWorksModal: false,
        shouldDisplayUserLeveledUpPopup: false,
      }
    },

    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
      }),

      campaignPromotion() {
        return this.$store.getters.get_campaign_promotion
      },

      crKey() {
        return this.$store.getters.get_cr_key
      },

      isHomePages() {
        return this.getRouteBaseName()
          ? ['index', 'index-list', 'index-list-package'].includes(this.getRouteBaseName())
          : false
      },

      isCloseBottom() {
        const isExpansionPages = this.getRouteBaseName()
          ? this.getRouteBaseName().includes('partner-with-us-') ||
            this.getRouteBaseName().includes('about-us-') ||
            this.getRouteBaseName().includes('airalo-careers') ||
            this.getRouteBaseName().includes('referral')
          : false

        return this.getRouteBaseName()
          ? ![
              'esim-buy-id',
              'esim-buy-id-index',
              'esim-buy-id-index-topup-topup',
              'esim-buy-id-complete',
              'esim-buy-id-cancel',
              'esim-buy-fail',
              'order-id',
              'order-fail',
            ].includes(this.getRouteBaseName()) &&
              !isExpansionPages &&
              this.getRouteBaseName() !== 'profile-delete-account-success'
          : true
      },

      isMyESIMSPages() {
        return this.getRouteBaseName()
          ? [
              'my-esims-index',
              'my-esims-index-archived',
              'my-esims-detail',
              'my-esims-detail-id',
              'my-esims-detail-id-installation',
              'my-esims-detail-id-installation-ios',
              'my-esims-detail-id-installation-android',
              'my-esims-detail-id-installation-ios-index-qr-step-by-step-guide',
              'my-esims-detail-id-installation-ios-index-manual-step-by-step-guide',
            ].includes(this.getRouteBaseName())
          : false
      },

      isFooterClosed() {
        return this.getRouteBaseName()
          ? [
              'esim-buy-id',
              'esim-buy-id-index',
              'esim-buy-id-index-topup-topup',
              'esim-buy-id-complete',
              'esim-buy-id-cancel',
              'esim-buy-fail',
              'order-id',
              'order-fail',
            ].includes(this.getRouteBaseName())
          : false
      },

      userRankingData() {
        if (this.$store.state.auth.loggedIn && this.shouldDisplayUserLeveledUpPopup) {
          return this.$store.state.auth.user.ranking
        }
        return null
      },

      isLoyaltyWelcomeViewed() {
        return this.$store.getters['userSettings/get_is_loyalty_welcome_viewed']
      },
    },

    head() {
      const isIos = (this.$device?.isIos || this.$ua?.isFromIpad()) ?? false
      const smartBannerTitle = this.$t(`components.store.banner.${isIos ? 'ios' : 'android'}-title`)
      const smartBannerDesc = this.$t(`components.store.banner.${isIos ? 'ios' : 'android'}-description`)

      const currentLocale = this.$i18n.locale
      const currentLang = this.$i18n.locales.find((v) => v.code === currentLocale)

      const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

      const allLocaleCodes = this.$i18n.locales.map((v) => v.locale)

      const isBlogDetail = this.getRouteBaseName() && this.getRouteBaseName() === 'blog-title'

      let link = []
      const listOfSpecialFonts = ['ar', 'he', 'hi', 'ja', 'ko', 'th']

      if (listOfSpecialFonts.includes(currentLocale)) {
        const isFontsIsExist = link.find((v) => v.hid === `stylesheet-${currentLocale}`)

        if (!isFontsIsExist) {
          link.push({
            hid: `stylesheet-${currentLocale}`,
            rel: 'stylesheet',
            href: `/fonts/ibm-plex-sans-${currentLocale}/fonts.css`,
          })
        }
      }

      if (!isBlogDetail) {
        link = [...link, ...i18nHead.link.filter((v) => allLocaleCodes.includes(v.hreflang))]

        let xDefault = i18nHead.link.find((v) => v.hreflang === 'x-default')

        link.unshift(xDefault)
      }

      const path = this.$route.path?.endsWith('/blog') ? this.$route.fullPath : this.$route.path
      link.push({
        rel: 'canonical',
        href: urlJoin(process.env.APP_URL, path),
      })

      link = link.map((v) => {
        if (v.rel === 'alternate') {
          const urlObj = new URL(v.href)
          urlObj.search = ''
          urlObj.hash = ''
          const newHref = urlObj.toString()

          return { ...v, href: newHref }
        }
        return v
      })

      return {
        title: this.$t('seo.homepage.title'),

        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('seo.homepage.description'),
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: this.$t('seo.homepage.keywords'),
          },
          { name: 'smartbanner:title', content: smartBannerTitle },
          { name: 'smartbanner:author', content: smartBannerDesc },
          {
            name: 'smartbanner:button',
            content: this.$t('components.store.banner.view-button'),
          },
          setSmartBannerPlatformVisibility(!this.isCoBranded),
        ],
        htmlAttrs: {
          lang: currentLang.code,
          dir: currentLang.dir,
        },
        bodyAttrs: {
          style: this.$dir() === 'rtl' ? `text-align: start;` : '',
        },
        link,
        script: [
          {
            hid: 'gtm-user-and-language',
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              user_id: ${this.$auth?.user?.id ? this.$auth?.user?.id : undefined},
              name: ${
                this.$auth?.user
                  ? "'" + [this.$auth?.user?.first_name, this.$auth?.user?.last_name].join(' ') + "'"
                  : undefined
              },
              email: ${this.$auth?.user?.email ? "'" + this.$auth?.user?.email + "'" : undefined},
              loyalty_level: ${
                this.$auth?.user?.ranking?.code ? "'" + this.$auth?.user?.ranking?.code + "'" : undefined
              },
              referral_given_amount: "${this.$store.getters['multiCurrency/get_referral_give_amount']}",
              referral_code: ${
                this.$store.getters.get_user_welcome?.code
                  ? "'" + this.$store.getters.get_user_welcome?.code + "'"
                  : undefined
              },
              referral_amount: ${
                this.$store.getters.get_user_welcome?.amount
                  ? parseFloat(this.$store.getters.get_user_welcome.amount.amount)
                  : undefined
              },
              currency: '${this.$store.getters['multiCurrency/get_current_currency_code']}',
              language: '${this.$i18n?.locale}',
            })
            window.dataLayer.push({
              event: "clevertap_notification",
              clevertap: {
                title: "${this.$i18n.t('component.clevertap.notification.title')}",
                body: "${this.$i18n.t('component.clevertap.notification.body-text')}",
                buttons: {
                  ok: "${this.$i18n.t('component.clevertap.notification.ok-button-title')}",
                  reject: "${this.$i18n.t('component.clevertap.notification.reject-button-title')}",
                }
              }
            })
          `,
          },
        ],
        __dangerouslyDisableSanitizers: ['script'],
      }
    },

    watch: {
      $route(val) {
        const mapping = this.$store.getters.get_breadcrumb_mapping
        this.$store.commit('set_current_breadcrumb_text', mapping[this.$route.path])

        // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
        this.checkFreshChatView()
        // @TODO END
      },
      isCampaignPopupShown(newVal) {
        if (newVal === false) this.$store.commit('set_campaign_popup', null)
      },
      // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
      isZendeskEnabled(val) {
        if (val) {
          setTimeout(() => {
            this.$utils.setFreshChatDisplayStyle('add')
            window.fcWidget = null

            const freshChatButton = document.getElementById('fc_frame')
            this.freshChatButton = freshChatButton

            if (freshChatButton) {
              freshChatButton.innerHTML = null
            }
          }, 2000)
        }
      },
      // @TODO END
    },

    beforeMount() {
      this.$sentry.setUser({ id: this.$auth?.user?.id })
    },

    beforeDestroy() {
      if (process.client || process.browser) {
        document.removeEventListener('smartbanner.clickout', () => this.smartBannerEvent('click'))
        document.removeEventListener('smartbanner.view', () => this.smartBannerEvent('view'))
        document.removeEventListener('smartbanner.exit', () => this.smartBannerEvent('close'))
      }
    },

    methods: {
      checkPath(currentPath) {
        return currentPath != '/esims/local' && currentPath != '/esims/regional'
      },

      registerCampaign() {
        const query = this.$route.query

        if (!query?.utm_campaign || !window?.localStorage) return // Added protection for UXP-5312

        const airaloCampaigns = window.localStorage.getItem('airalo.campaigns')
        let airaloCampaignsParsed
        try {
          airaloCampaignsParsed = JSON.parse(airaloCampaigns)
        } catch (error) {}
        const campaigns = Array.isArray(airaloCampaignsParsed) ? airaloCampaignsParsed : []
        const existingCampaignIndex = campaigns.findIndex((campaign) => campaign.utm_campaign === query.utm_campaign)

        // Create date string for backend
        const now = new Date()
        const utcTimestamp = now.getTime() + now.getTimezoneOffset() * 60000
        const date = format(utcTimestamp, 'yyyy-MM-dd HH:mm:ss')
        const newCampaign = { ...query, utm_date: date }

        if (existingCampaignIndex >= 0) {
          campaigns[existingCampaignIndex] = newCampaign
        } else {
          campaigns.push(newCampaign)
        }

        window.localStorage.setItem('airalo.campaigns', JSON.stringify(campaigns))
        this.$store.dispatch('fetch_campaign_promotion', query)
      },

      dismissCampaignPopup() {
        this.isCampaignPopupShown = false
      },

      smartBannerEvent(event) {
        const app_store = this.$device?.isIos || this.$ua?.isFromIpad() ? 'AppStore' : 'PlayStore'
        this.$utils.sendEvent('downloadBannerEvent', { event, app_store })
      },

      checkClickIdFromUrl() {
        const irClickId = this.$route.query?.irclickid

        if (irClickId?.length) {
          ClientSideCookies.set('Airalo.irclickid', irClickId, {
            expires: new Date(Date.now() + 60 * 60 * 60 * 24 * 365),
            httpOnly: false,
          })
        }
      },

      checkDiscountCode() {
        const code = this.$route.query?.code

        if (code) {
          window.localStorage.setItem('airalocode', code)
        }
      },
      // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
      checkFreshChatView() {
        if (!this.getRouteBaseName() && this.freshChatButton) {
          this.$utils.setFreshChatDisplayStyle('add')
          return false
        }

        const whiteList = [
          'index',
          'index-list-package',
          'my-esims-index',
          'my-esims-index-archived',
          'my-esims-detail-id',
          'my-esims-detail-id-installation-android-index-qr',
          'my-esims-detail-id-installation-android-index-manual',
          'my-esims-detail-id-installation-ios-index-qr',
          'my-esims-detail-id-installation-ios-index-manual',
          'profile',
        ]

        const isIncluded = whiteList.map((v) => this.getRouteBaseName() === v).includes(true)

        if (!isIncluded) {
          this.$utils.setFreshChatDisplayStyle('add')
        } else if (isIncluded && !this.isZendeskEnabled) {
          this.$utils.setFreshChatDisplayStyle('remove')
        }
      },
      // @TODO END

      //  How Loyalty Program Works

      checkShouldDisplayHowLoyaltyWorks() {
        if (this.$store.state.auth.loggedIn && !this.isLoyaltyWelcomeViewed) {
          this.shouldDisplayHowLoyaltyProgramWorksModal = true
          setTimeout(() => {
            this.howLoyaltyProgramWorksModalView = true
          }, 1000)
        }
      },

      onCloseHowLoyaltyProgramWorks() {
        this.shouldDisplayHowLoyaltyProgramWorksModal = false
        this.shouldDisplayUserLeveledUpPopup = true
      },

      //  User Leveled Up

      onCloseUserLeveledUpModal() {
        this.shouldDisplayUserLeveledUpPopup = false
      },

      removeUnusedCookies() {
        const unusedCookies = ['Airalo.10million']
        unusedCookies.forEach((cookie) => {
          if (ClientSideCookies.get(cookie) !== undefined) ClientSideCookies.remove(cookie)
        })
      },

      onZendeskEnabled() {
        this.isZendeskEnabled = true
      },
    },

    mounted() {
      this.checkClickIdFromUrl()
      this.registerCampaign()
      this.checkDiscountCode()
      this.$utils.activateFirebaseSdk()

      // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
      this.checkFreshChatView()
      // @TODO END
      this.checkShouldDisplayHowLoyaltyWorks()

      const mapping = this.$store.getters.get_breadcrumb_mapping
      this.$store.commit('set_current_breadcrumb_text', mapping[this.$route.path])

      // @TODO: this must be removed when we be sure about removing fresh chat integraiton from GTM.
      this.$nextTick(() => {
        if (process.client || process.browser) {
          setTimeout(() => {
            const freshChatButton = document.getElementById('fc_frame')

            this.freshChatButton = freshChatButton

            if (freshChatButton && (this.$route.query.web_view || (window.innerWidth < 576 && !this.isFooterClosed))) {
              freshChatButton.innerHTML = null
            }
          }, 3000)
        }
      })
      // @TODO END

      if (process.client || process.browser) {
        document.addEventListener('smartbanner.clickout', () => this.smartBannerEvent('click'))
        document.addEventListener('smartbanner.view', () => this.smartBannerEvent('view'))
        document.addEventListener('smartbanner.exit', () => this.smartBannerEvent('close'))
        this.removeUnusedCookies()
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-dialog {
      max-width: 360px;
      margin: auto;
    }
  }

  .campaign-popup__image {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
  }
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-layout layout-auth-outer relative"
  }, [_c('div', {
    staticClass: "auth-top shadowed shadowed-sm-none pt-20 pt-sm-40 relative z-index-10"
  }, [_c('div', {
    staticClass: "auth-container mx-auto"
  }, [_c('div', {
    class: ['display-flex flex-column justify-content-start align-items-center gap-20 gap-sm-40', {
      'pb-20 pb-md-0': !_vm.isAuthPages
    }]
  }, [_c('nuxt-link', {
    staticClass: "display-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index',
        params: {
          list: undefined,
          package: undefined
        }
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "w-auto airalo-logo",
    attrs: {
      "src": "/assets/images/svg/Logo.svg",
      "alt": "Airalo logo",
      "data-testid": "airalo-logo",
      "height": `${_vm.isDesktop ? 44 : 30}px`,
      "width": `${_vm.isDesktop ? 149 : 102}px`,
      "loading": "eager"
    }
  })], 1), _vm._v(" "), _vm.isAuthPages ? [_c('div', {
    staticClass: "auth-tabs-container w-100"
  }, [_c('AuthTabs', {
    staticClass: "w-100",
    attrs: {
      "tabs": _vm.tabs
    }
  })], 1)] : _vm._e()], 2)])]), _vm._v(" "), _c('div', {
    class: ['auth-bottom relative z-index-20 pb-40', {
      'mt-40': !_vm.isAuthPages
    }]
  }, [_c('div', {
    staticClass: "auth-container mx-auto"
  }, [_c('div', {
    staticClass: "display-flex flex-column justify-content-start align-items-center gap-40"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "auth-bottom-inner shadowed-sm box-rounded"
  }, [_c('nuxt')], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44px",
      "height": "28px",
      "viewBox": "0 0 44 28",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "alipay",
      "transform": "translate(9.000000, 0.000000)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.09338979,20.1101291 C9.09338979,20.6015288 9.4459824,20.9322006 9.93770548,20.9322006 C10.4293079,20.9322006 10.7821418,20.6015288 10.7821418,20.1101291 C10.7821418,19.6283142 10.4293079,19.2880577 9.93770548,19.2880577 C9.4459824,19.2880577 9.09338979,19.6283142 9.09338979,20.1101291",
      "id": "Fill-82",
      "fill": "#1677FF"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Fill-85",
      "fill": "#000000",
      "points": "9.20474245 26.3936674 10.6706203 26.3936674 10.6706203 21.3290191 9.20474245 21.3290191"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M13.3897222,25.5244344 C13.2227174,25.5244344 13.0651247,25.5055108 12.8887077,25.4488628 L12.8887077,22.7086246 C13.1949637,22.4912487 13.4454709,22.3874146 13.7608977,22.3874146 C14.3082489,22.3874146 14.7445852,22.8313823 14.7445852,23.7763344 C14.7445852,24.9858485 14.1041989,25.5244344 13.3897222,25.5244344 M14.3175403,21.2250866 C13.77936,21.2250866 13.3619685,21.4330006 12.8887077,21.8299051 L12.8887077,21.3290436 L11.4225885,21.3290436 L11.4225885,27.9999877 L12.8887077,27.9999877 L12.8887077,26.3465058 C13.1669686,26.4220774 13.426888,26.4598018 13.7424355,26.4598018 C15.0507206,26.4598018 16.2289253,25.4771253 16.2289253,23.7291483 C16.2289253,22.1605769 15.3754388,21.2250866 14.3175403,21.2250866",
      "id": "Fill-87",
      "fill": "#000000"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Fill-86",
      "fill": "#000000",
      "points": "25.9908292 21.3385177 26 21.3290559 24.6175039 21.3290559 23.7453139 24.4094278 23.6988566 24.4094278 22.6968275 21.3290559 21.0544121 21.3290559 23.0308372 26.4126279 22.2049838 27.9622756 22.2049838 28 23.4945653 28"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M19.3007043,25.1653403 C18.9110666,25.3827161 18.688313,25.4677495 18.4283936,25.4677495 C18.0759217,25.4677495 17.8531681,25.231573 17.8531681,24.8534692 C17.8531681,24.7117879 17.8809218,24.5701065 17.9924192,24.4566876 C18.1687156,24.277159 18.5120167,24.1448166 19.3007043,23.955949 L19.3007043,25.1653403 Z M20.7665821,25.1274931 L20.7665821,23.0109969 C20.7665821,21.8581308 20.0985627,21.2251726 18.920358,21.2251726 C18.1687156,21.2251726 17.6489974,21.3573922 16.7027171,21.6503396 L16.9623951,22.8125447 C17.8251731,22.4157631 18.2058814,22.2455734 18.6046899,22.2455734 C19.0871215,22.2455734 19.3007043,22.5951689 19.3007043,23.1337547 L19.3007043,23.1716019 C17.6212437,23.4929348 17.1014049,23.6724634 16.776928,24.0031352 C16.5355916,24.2487736 16.4333856,24.598369 16.4333856,25.0047353 C16.4333856,25.9779499 17.1758573,26.4976122 17.871751,26.4976122 C18.3914691,26.4976122 18.8089812,26.2992828 19.3749153,25.8645311 L19.4770006,26.393778 L20.9428784,26.393778 L20.7665821,25.1274931 Z",
      "id": "Fill-88",
      "fill": "#000000"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M18.5645557,0 L7.22391292,0 C5.74681297,0 4.55099069,1.22880638 4.55099069,2.74539922 L4.55099069,14.3916575 C4.55099069,15.9073901 5.74681297,17.1365652 7.22391292,17.1365652 L18.5645557,17.1365652 C20.0409316,17.1365652 21.2365125,15.9073901 21.2365125,14.3916575 L21.2365125,2.74539922 C21.2365125,1.22880638 20.0409316,0 18.5645557,0",
      "id": "Fill-89",
      "fill": "#1677FF"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M9.05968715,13.1780514 C6.46375126,13.1780514 5.69630124,11.0780213 6.97912532,9.92921022 C7.40713558,9.54103028 8.18966913,9.35142546 8.6065779,9.30890876 C10.1484767,9.15235883 11.575982,9.75656292 13.2605107,10.6009987 C12.0763932,12.1871419 10.5684022,13.1780514 9.05968715,13.1780514 M18.288515,10.7601291 C17.6212196,10.5305881 16.7250166,10.1793952 15.7273315,9.80854143 C16.3268114,8.73861972 16.8051403,7.52001243 17.1197224,6.19597355 L13.8310301,6.19597355 L13.8310301,4.97933235 L17.8599014,4.97933235 L17.8599014,4.30029395 L13.8310301,4.30029395 L13.8310301,2.27202613 L12.1865633,2.27202613 C11.8979249,2.27202613 11.8979249,2.56435917 11.8979249,2.56435917 L11.8979249,4.30029395 L7.82319968,4.30029395 L7.82319968,4.97933235 L11.8979249,4.97933235 L11.8979249,6.19597355 L8.53381495,6.19597355 L8.53381495,6.87501196 L15.0588295,6.87501196 C14.8197857,7.71981635 14.4994115,8.51313375 14.1188239,9.23370581 C12.0016996,8.51620576 9.7423074,7.93473458 8.3231282,8.29268588 C7.41558235,8.52234979 6.83070338,8.93240248 6.48728157,9.36174743 C4.91087165,11.3315241 6.04117108,14.3235447 9.37052855,14.3235447 C11.3389896,14.3235447 13.2351703,13.1962377 14.7047889,11.3388969 C16.8964861,12.4211067 21.2364401,14.2791848 21.2364401,14.2791848 L21.2364401,11.6311071 C21.2364401,11.6311071 20.6915024,11.5865014 18.288515,10.7601291",
      "id": "Fill-90",
      "fill": "#FFFFFF"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Fill-83",
      "fill": "#000000",
      "points": "6.73647355 26.3936674 8.20247203 26.3936674 8.20247203 19.4958857 6.73647355 19.4958857"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M2.03217387,24.0031352 L2.90436392,20.9322252 L2.94140907,20.9322252 L3.76726252,24.0031352 L2.03217387,24.0031352 Z M4.1663124,19.7321729 L2.19917871,19.7321729 L0,26.393778 L1.35474235,26.393778 L1.72591787,25.0897687 L4.05493561,25.0897687 L4.40752821,26.393778 L6.1424962,26.393778 L4.1663124,19.7321729 Z",
      "id": "Fill-84",
      "fill": "#000000"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="default-layout-content">
    <nuxt ref="page" />
  </div>
</template>

<script>
  import urlJoin from 'url-join'

  export default {
    head() {
      const currentLocale = this.$i18n.locale
      const currentLang = this.$i18n.locales.find((v) => v.code === this.$i18n.locale)

      const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

      const allLocaleCodes = this.$i18n.locales.map((v) => v.locale)

      const listOfSpecialFonts = ['ar', 'he', 'hi', 'ja', 'ko', 'th']

      let link = []

      if (listOfSpecialFonts.includes(currentLocale)) {
        const isFontsIsExist = link.find((v) => v.hid === `stylesheet-${currentLocale}`)

        if (!isFontsIsExist) {
          link.push({
            hid: `stylesheet-${currentLocale}`,
            rel: 'stylesheet',
            href: `/fonts/ibm-plex-sans-${currentLocale}/fonts.css`,
          })
        }
      }

      link = [...link, ...i18nHead.link.filter((v) => allLocaleCodes.includes(v.hreflang))]

      let xDefault = i18nHead.link.find((v) => v.hreflang === 'x-default')

      link.unshift(xDefault)

      link.push({
        rel: 'canonical',
        href: urlJoin(process.env.APP_URL, this.$route.path),
      })

      link = link.map((v) => {
        if (v.rel === 'alternate') {
          const urlObj = new URL(v.href)
          urlObj.search = ''
          urlObj.hash = ''
          const newHref = urlObj.toString()

          return { ...v, href: newHref }
        }
        return v
      })

      return {
        title: this.$t('seo.homepage.title'),

        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('seo.homepage.description'),
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: this.$t('seo.homepage.keywords'),
          },
        ],

        link,

        htmlAttrs: {
          lang: currentLang.code,
          dir: currentLang.dir,
        },
        bodyAttrs: {
          style: this.$dir() === 'rtl' ? `text-align: start;` : '',
        },
      }
    },
  }
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "size": "languages",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.onHide
    },
    model: {
      value: _vm.modalView,
      callback: function ($$v) {
        _vm.modalView = $$v;
      },
      expression: "modalView"
    }
  }, [_c('div', {
    staticClass: "c--modals_languages-modal modal-languages-close",
    attrs: {
      "dir": _vm.$dir()
    }
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$bvModal.hide(_vm.modalId);
      }
    }
  }, [_c('span', {
    staticClass: "airalo-icon-close-round-filled tw-text-5.5 tw-text-neutral-600"
  })])]), _vm._v(" "), _c('div', {
    staticClass: "languages-item-wrapper pt-40"
  }, [_c('language-list', {
    staticClass: "h-100",
    attrs: {
      "type": "modal",
      "title": _vm.title,
      "empty-text": _vm.emptyText,
      "search-input-placeholder": _vm.searchInputPlaceholder,
      "list": _vm.languages,
      "searchable": true,
      "is-shadowed-box": false,
      "is-rounded-box": false
    },
    on: {
      "onClickItem": function ($event) {
        return _vm.$bvModal.hide(_vm.modalId);
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
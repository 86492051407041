import ServerSideCookies from 'cookies'

export default async (context) => {
  if (process.server) {
    try {
      const cfViewerCountry = context.req.headers['cf-ipcountry'] || context.req.headers['cloudfront-viewer-country']
      if (cfViewerCountry) {
        context.$axios.defaults.headers.common['Airalo-Viewer-Country'] = cfViewerCountry
        context.store.commit('behaviors/set_viewer_country', cfViewerCountry)
      }

      const ssCookies = new ServerSideCookies(context.req, context.res)
      const currencyCookie = ssCookies.get('Airalo.currency')
      if (currencyCookie) context.$axios.defaults.headers.common['Accept-Currency'] = currencyCookie
    } catch (error) {
      console.error('axios.js server', { error })
    }
  } else {
    context.$axios.setHeader('Airalo-Viewer-Country', context.store.getters['behaviors/get_viewer_country'])
    await context.store.dispatch('multiCurrency/set_currency', {
      newCurrencyCode: context.store.getters['multiCurrency/get_current_currency_code'],
      shouldUpdateProfile: false,
    })
  }
}

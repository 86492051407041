<template>
  <component class="c--shared_svg-icon" :is="name" />
</template>

<script>
  import * as icons from '~/components/shared/svg-icon/variants'

  export default {
    name: 'SvgIcon',
    components: {
      ...icons,
    },
    props: {
      name: {
        type: String,
        required: true,
        validator: (value) => Object.keys(icons).includes(value),
      },
    },
  }
</script>

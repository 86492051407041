<template>
  <b-modal :id="modalId" size="languages" v-model="modalView" centered hide-header hide-footer @hide="onHide">
    <div class="c--modals_languages-modal modal-languages-close" :dir="$dir()">
      <button type="button" class="btn" @click.prevent="$bvModal.hide(modalId)">
        <span class="airalo-icon-close-round-filled tw-text-5.5 tw-text-neutral-600" />
      </button>
    </div>
    <div class="languages-item-wrapper pt-40">
      <language-list
        type="modal"
        :title="title"
        :empty-text="emptyText"
        :search-input-placeholder="searchInputPlaceholder"
        :list="languages"
        :searchable="true"
        :is-shadowed-box="false"
        :is-rounded-box="false"
        class="h-100"
        @onClickItem="$bvModal.hide(modalId)"
      />
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'languages-modal',
    components: {
      LanguageList: () => import('~/components/shared/language-list'),
    },
    props: {
      modalId: {
        type: String,
        required: false,
        default: 'languages-modal',
      },
      modalView: {
        type: Boolean,
        required: true,
      },
      languages: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      searchInputPlaceholder: {
        type: String,
        required: false,
        default: '',
      },
      emptyText: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      onHide(evt) {
        if (evt.trigger === 'backdrop' || evt.trigger === 'esc' || evt.trigger === 'event') {
          evt.preventDefault()
          this.$emit('onCloseLanguagesModal')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .languages-item-wrapper {
    height: 100%;
  }

  ::v-deep {
    .modal-languages {
      max-width: 540px;

      &-close {
        .btn {
          padding: 0;
          margin: 0;
          display: flex;
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 10;
          box-shadow: none;
        }

        &[dir='rtl'] {
          .btn {
            left: 5px;
            right: unset;
          }
        }
      }

      .modal-body {
        padding: 0;
        height: 540px;
        overflow: hidden;
      }

      @media (max-width: 575px) {
        width: 100vw;
        height: 100vh;
        max-width: unset;
        min-height: unset;
        margin: 0;
      }

      .modal-content {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 7px;

        @media (max-width: 575px) {
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>

<template>
  <span class="c--shared_icons airalo-icon-wrapper display-flex">
    <template v-if="type === 'image'">
      <template v-if="loader === 'raw'">
        <span class="display-flex" v-html="require(`~/assets/images/svg/${name}${themeName}.svg?raw`)"></span>
      </template>
      <template v-else>
        <img
          v-if="loader === 'data'"
          :src="require(`~/assets/images/svg/${name}${themeName}.svg?data`)"
          :alt="alternativeText"
          :width="width"
          :height="height"
          :loading="loading"
        />
        <img
          v-else
          :src="require(`~/assets/images/svg/${name}${themeName}.svg`)"
          :alt="alternativeText"
          :width="width"
          :height="height"
          :loading="loading"
        />
      </template>
    </template>
    <template v-else-if="type === 'exclusive'">
      <div class="airmoney-pay-icon">
        <i class="airalo-icon-airmoney tw-text-white tw-text-3 mr-3" />
        <span class="text">Pay</span>
      </div>
    </template>
    <template v-else>
      <i :class="['airalo-icon', `icon-${name}${themeName}`]"></i>
    </template>
  </span>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      theme: {
        type: String,
        required: false,
        default: '', // light, dark
      },
      type: {
        type: String,
        required: false,
        default: 'image', // image or class
      },
      loader: {
        type: String,
        required: false,
        default: '', // data, raw
      },
      width: {
        required: false,
        default: null,
      },
      height: {
        required: false,
        default: null,
      },
      alternativeText: {
        type: String,
        default: '',
      },
      loading: {
        type: String,
        default: 'lazy',
        validator: (value) => ['lazy', 'eager'].includes(value),
      },
    },
    computed: {
      themeName() {
        return this.theme.length > 0 ? `-${this.theme}` : ''
      },
    },
  }
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "width": "16",
      "height": "16"
    }
  }, [_c('path', {
    attrs: {
      "id": "Layer",
      "fill-rule": "evenodd",
      "fill": "#000000",
      "d": "m12.2 1.5h2.2l-4.9 5.5 5.7 7.5h-4.4l-3.5-4.5-4 4.5h-2.2l5.2-5.9-5.5-7.1h4.6l3.1 4.2zm-0.7 11.6h1.2l-7.9-10.4h-1.3z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
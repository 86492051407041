<template>
  <div class="footer-ignore tw-inline-flex">
    <template v-if="branding">
      <div class="tw-flex lg:tw-flex-col tw-items-center lg:tw-gap-2">
        <div class="footer-ignore tw-flex tw-items-center tw-gap-3">
          <img
            :src="branding.primary_logo"
            :alt="branding.name"
            :class="coBrandingImageClasses"
            height="auto"
            width="auto"
            loading="eager"
          />

          <template v-if="branding.secondary_logo">
            <img
              :src="branding.secondary_logo"
              :alt="branding.name"
              :class="coBrandingImageClasses"
              height="auto"
              width="auto"
              loading="eager"
            />
          </template>
        </div>

        <div
          class="footer-ignore tw-flex lg:tw-hidden before:tw-content-['']"
          :class="[
            'tw-relative tw-items-center',
            'ltr:tw-pl-2.5 ltr:tw-ml-2.5 rtl:tw-pr-2.5 rtl:tw-mr-2.5',
            'before:tw-absolute ltr:before:tw-left-0 rtl:before:tw-right-0 before:tw-h-[44px] before:tw-w-[1px] before:tw-bg-zinc-300',
          ]"
        >
          <nuxt-link class="tw-inline-flex" :to="localePath({ name: 'index' })">
            <nuxt-img
              src="/assets/images/svg/powered-by-airalo-mobile-logo.svg"
              alt="Powered by Airalo mobile footer logo"
              height="47"
              width="62"
              class="tw-w-auto"
              loading="eager"
            />
          </nuxt-link>
        </div>

        <nuxt-link class="tw-hidden lg:tw-inline-flex" :to="localePath({ name: 'index' })">
          <nuxt-img
            src="/assets/images/svg/powered-by-airalo-desktop-footer-logo.svg"
            alt="Powered by Airalo desktop footer logo"
            height="18"
            width="130"
            class="tw-w-auto"
            loading="eager"
          />
        </nuxt-link>
      </div>
    </template>
    <template v-else>
      <nuxt-link :to="localePath('/')" class="tw-inline-flex">
        <img src="/assets/images/svg/Footer-Logo-Horizontal.svg" alt="Airalo" width="95" height="28" />
      </nuxt-link>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'ui-footer-logo',
    props: {
      branding: {
        type: Object | null,
        required: true,
      },
    },
    computed: {
      coBrandingImageClasses() {
        if (this.branding?.secondary_logo) {
          return 'footer-ignore tw-max-w-[50px] tw-max-h-[50px]'
        }

        return 'footer-ignore tw-max-w-[130px] tw-max-h-[50px]'
      },
    },
  }
</script>

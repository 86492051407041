var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "40",
      "height": "30",
      "viewBox": "0 0 40 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask0_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "40",
      "height": "30"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0 0H39.5455V30H0V0Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask0_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M39.5455 23.9481C39.5455 27.2943 36.7913 30.0073 33.394 30.0073H0V6.05907C0 2.71281 2.75419 0 6.15184 0H39.5455V23.9481Z",
      "fill": "#FFFFFE"
    }
  })]), _vm._v(" "), _c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask1_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "28",
      "y": "15",
      "width": "5",
      "height": "3"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M28.6575 17.813H31.2008C31.2808 17.813 31.4321 17.8006 31.5042 17.7849C31.9924 17.681 32.399 17.254 32.399 16.6724C32.399 16.0907 31.9924 15.6627 31.5042 15.5586C31.4321 15.5431 31.2808 15.5304 31.2008 15.5304H28.6575V17.813Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask1_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M28.6575 17.813H31.2008C31.2808 17.813 31.4321 17.8006 31.5042 17.7849C31.9924 17.681 32.399 17.254 32.399 16.6724C32.399 16.0907 31.9924 15.6627 31.5042 15.5586C31.4321 15.5431 31.2808 15.5304 31.2008 15.5304H28.6575V17.813Z",
      "fill": "url(#paint0_linear_1370_10539)"
    }
  })]), _vm._v(" "), _c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask2_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "26",
      "y": "2",
      "width": "12",
      "height": "26"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M30.9036 2.01941C28.477 2.01941 26.5095 3.9571 26.5095 6.34709V10.8423H32.727C32.8711 10.8423 33.0474 10.8522 33.1574 10.8579C34.5644 10.9274 35.6022 11.6393 35.6022 12.8749C35.6022 13.8465 34.8929 14.6761 33.6021 14.863V14.9117C35.028 15.0092 36.1197 15.782 36.1197 17C36.1197 18.3173 34.9214 19.1643 33.3242 19.1643H26.5095V27.9871H32.9543C35.3808 27.9871 37.3482 26.0493 37.3482 23.6591V2.01941H30.9036Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask2_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M30.9036 2.01941C28.477 2.01941 26.5095 3.9571 26.5095 6.34709V10.8423H32.727C32.8711 10.8423 33.0474 10.8522 33.1574 10.8579C34.5644 10.9274 35.6022 11.6393 35.6022 12.8749C35.6022 13.8465 34.8929 14.6761 33.6021 14.863V14.9117C35.028 15.0092 36.1197 15.782 36.1197 17C36.1197 18.3173 34.9214 19.1643 33.3242 19.1643H26.5095V27.9871H32.9543C35.3808 27.9871 37.3482 26.0493 37.3482 23.6591V2.01941H30.9036Z",
      "fill": "url(#paint1_linear_1370_10539)"
    }
  })]), _vm._v(" "), _c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask3_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "28",
      "y": "12",
      "width": "5",
      "height": "3"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M32.0978 13.1892C32.0978 12.6175 31.6912 12.2342 31.2131 12.1546C31.1685 12.147 31.0467 12.1355 30.9629 12.1355H28.6575V14.2426H30.9629C31.0467 14.2426 31.1685 14.2308 31.2131 14.2232C31.6912 14.1435 32.0978 13.7605 32.0978 13.1892Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask3_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M32.0978 13.1892C32.0978 12.6175 31.6912 12.2342 31.2131 12.1546C31.1685 12.147 31.0467 12.1355 30.9629 12.1355H28.6575V14.2426H30.9629C31.0467 14.2426 31.1685 14.2308 31.2131 14.2232C31.6912 14.1435 32.0978 13.7605 32.0978 13.1892Z",
      "fill": "url(#paint2_linear_1370_10539)"
    }
  })]), _vm._v(" "), _c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask4_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "2",
      "y": "2",
      "width": "12",
      "height": "26"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.59119 2.01941C4.16438 2.01941 2.19727 3.9571 2.19727 6.34709V17.0353C3.42952 17.6233 4.70269 18.0063 5.99844 18.0063C7.51764 18.0063 8.35233 17.0929 8.35233 15.8638V10.8425H12.1313V15.8638C12.1313 17.8278 10.9074 19.4029 6.70739 19.4029C4.17963 19.4029 2.19727 18.8661 2.19727 18.8661V27.9871H8.64176C11.0683 27.9871 13.0357 26.0493 13.0357 23.6591V2.01941H6.59119Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask4_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.59119 2.01941C4.16438 2.01941 2.19727 3.9571 2.19727 6.34709V17.0353C3.42952 17.6233 4.70269 18.0063 5.99844 18.0063C7.51764 18.0063 8.35233 17.0929 8.35233 15.8638V10.8425H12.1313V15.8638C12.1313 17.8278 10.9074 19.4029 6.70739 19.4029C4.17963 19.4029 2.19727 18.8661 2.19727 18.8661V27.9871H8.64176C11.0683 27.9871 13.0357 26.0493 13.0357 23.6591V2.01941H6.59119Z",
      "fill": "url(#paint3_linear_1370_10539)"
    }
  })]), _vm._v(" "), _c('mask', {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      "id": "mask5_1370_10539",
      "maskUnits": "userSpaceOnUse",
      "x": "14",
      "y": "2",
      "width": "12",
      "height": "26"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.7477 2.01941C16.3209 2.01941 14.3538 3.9571 14.3538 6.34709V12.0132C15.4671 11.0725 17.3923 10.4848 20.5202 10.6235C22.1946 10.697 23.9701 11.1393 23.9701 11.1393V12.9718C23.0815 12.5282 22.0158 12.1173 20.6426 12.0148C18.2688 11.8382 16.8402 12.9808 16.8402 15.0035C16.8402 17.0258 18.2688 18.1682 20.6426 17.9907C22.0158 17.8892 23.0955 17.4673 23.9701 17.035V18.8665C23.9701 18.8665 22.1946 19.3086 20.5202 19.3835C17.3923 19.5221 15.4671 18.9345 14.3538 17.9937V27.9871H20.7983C23.2247 27.9871 25.1919 26.0493 25.1919 23.6591V2.01941H18.7477Z",
      "fill": "white"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask5_1370_10539)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.7477 2.01941C16.3209 2.01941 14.3538 3.9571 14.3538 6.34709V12.0132C15.4671 11.0725 17.3923 10.4848 20.5202 10.6235C22.1946 10.697 23.9701 11.1393 23.9701 11.1393V12.9718C23.0815 12.5282 22.0158 12.1173 20.6426 12.0148C18.2688 11.8382 16.8402 12.9808 16.8402 15.0035C16.8402 17.0258 18.2688 18.1682 20.6426 17.9907C22.0158 17.8892 23.0955 17.4673 23.9701 17.035V18.8665C23.9701 18.8665 22.1946 19.3086 20.5202 19.3835C17.3923 19.5221 15.4671 18.9345 14.3538 17.9937V27.9871H20.7983C23.2247 27.9871 25.1919 26.0493 25.1919 23.6591V2.01941H18.7477Z",
      "fill": "url(#paint4_linear_1370_10539)"
    }
  })]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_1370_10539",
      "x1": "26.5098",
      "y1": "19.9864",
      "x2": "37.3762",
      "y2": "19.9864",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#5FB345"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#007444"
    }
  })], 1), _vm._v(" "), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_1370_10539",
      "x1": "26.5095",
      "y1": "28.0207",
      "x2": "37.3762",
      "y2": "28.0207",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#5FB345"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#007444"
    }
  })], 1), _vm._v(" "), _c('linearGradient', {
    attrs: {
      "id": "paint2_linear_1370_10539",
      "x1": "26.5096",
      "y1": "16.5167",
      "x2": "37.3757",
      "y2": "16.5167",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#5FB345"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#007444"
    }
  })], 1), _vm._v(" "), _c('linearGradient', {
    attrs: {
      "id": "paint3_linear_1370_10539",
      "x1": "2.18319",
      "y1": "28.222",
      "x2": "13.2177",
      "y2": "28.222",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#006EAB"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#2F2E61"
    }
  })], 1), _vm._v(" "), _c('linearGradient', {
    attrs: {
      "id": "paint4_linear_1370_10539",
      "x1": "14.2888",
      "y1": "27.8418",
      "x2": "25.0056",
      "y2": "27.8418",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#D91F43"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#613033"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "version": "1.2",
      "viewBox": "0 0 22 22",
      "width": "22",
      "height": "22"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#4ca7e6",
      "d": "m19 6q-0.9 0.4-1.9 0.6c0.7-0.5 1.2-1.1 1.5-1.9q-1 0.6-2.1 0.8c-0.6-0.6-1.5-1-2.4-1-2.1 0-3.7 2-3.2 4-2.7-0.1-5.2-1.4-6.8-3.4-0.8 1.5-0.4 3.4 1 4.4q-0.8-0.1-1.5-0.4c0 1.5 1.1 2.9 2.7 3.2q-0.7 0.2-1.5 0.1c0.4 1.3 1.6 2.2 3.1 2.3-1.4 1-3.2 1.5-4.9 1.3 1.5 1 3.2 1.5 5 1.5 6.1 0 9.6-5.1 9.4-9.8q0.9-0.7 1.6-1.7z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
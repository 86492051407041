<template>
  <b-modal
    :id="modalId"
    :size="modalSize"
    modal-class="prompt-simple-wrapper"
    v-model="modalView"
    centered
    hide-header
    hide-footer
    @hide="onHide"
    @hidden="onHidden"
  >
    <box-type-simple :shadowed="true" :rounded="true" :class="{ 'close-animation': isCloseAnimation }">
      <b-overlay :show="overlayView" variant="white">
        <div class="c--modals_prompt-simple prompt-simple-content" data-testid="prompt-item">
          <div class="prompt-simple-text-wrapper" :class="{ 'tw-px-5 md:tw-px-7.5 tw-pt-5 md:tw-pt-7.5': !noPadding }">
            <div class="prompt-simple-text" data-testid="prompt-text-item" v-if="text && text.length > 0">
              <p>{{ text }}</p>
            </div>
            <slot></slot>
          </div>
          <div
            class="prompt-simple-actions tw-px-5 md:tw-px-7.5 tw-mb-5 md:tw-mb-7.5"
            data-testid="prompt-action-buttons"
            :class="{
              'single-full-button': type === 'single-full-button',
              'double-full-buttons': type === 'double-full-buttons',
            }"
          >
            <template v-for="(button, index) in buttons">
              <template v-if="button.isClose">
                <b-button
                  :variant="button.variant || 'prompt-simple-primary'"
                  class="btn-prompt-simple"
                  :data-testid="`${button.text}-button`"
                  :class="[button.class]"
                  :key="`prompt-simple-content-button-${index}`"
                  @click.prevent="$bvModal.hide(modalId)"
                >
                  {{ button.text }}
                </b-button>
              </template>
              <template v-else-if="button.url && button.url.length">
                <b-button
                  :variant="button.variant || 'prompt-simple-primary'"
                  class="btn-prompt-simple"
                  :data-testid="`${button.text}-button`"
                  :class="[button.class]"
                  :key="`prompt-simple-content-button-${index}`"
                  :to="localePath(button.url)"
                >
                  {{ button.text }}
                </b-button>
              </template>
              <template v-else>
                <b-button
                  :variant="button.variant || 'prompt-simple-primary'"
                  class="btn-prompt-simple"
                  :data-testid="`${button.text}-button`"
                  :class="[button.class]"
                  :key="`prompt-simple-content-button-${index}`"
                  @click.prevent="clickButtonEvent($event, button)"
                >
                  {{ button.text }}
                </b-button>
              </template>
            </template>
          </div>
        </div>
      </b-overlay>
    </box-type-simple>
  </b-modal>
</template>
<script>
  import BoxTypeSimple from '~/components/card-boxes/box-type-simple.vue'

  export default {
    components: {
      BoxTypeSimple,
    },
    props: {
      modalView: {
        type: Boolean,
        required: true,
      },
      overlayView: {
        type: Boolean,
        required: false,
        default: false,
      },
      modalId: {
        type: String,
        required: false,
        default: 'modal-prompt-simple',
      },
      modalSize: {
        type: String,
        required: false,
        default: 'prompt-simple', // prompt-simple, prompt-big
      },
      type: {
        type: String,
        required: false,
        default: 'single',
      },
      text: {
        type: String,
        required: false,
        default: '',
      },
      button: {
        type: Object,
        required: false,
        default: null,
      },
      buttonSecond: {
        type: Object,
        required: false,
        default: null,
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        isCloseAnimation: false,
      }
    },
    computed: {
      buttons() {
        if (this.type == 'single') return [this.button].filter((v) => !!v)
        if (this.type == 'single-full-button') return [this.button].filter((v) => !!v)
        if (this.type == 'double') return [this.button, this.buttonSecond].filter((v) => !!v)
        if (this.type == 'double-full-buttons') return [this.button, this.buttonSecond].filter((v) => !!v)
        return []
      },
    },
    methods: {
      onHide(e) {
        if (e.trigger === 'backdrop' || e.trigger === 'esc' || e.trigger === 'event' || e.trigger === 'click') {
          e.preventDefault()
          this.isCloseAnimation = true
          this.$emit('onCloseModal')
        }
      },
      onHidden() {
        this.isCloseAnimation = false
      },
      clickButtonEvent(e, data) {
        this.$emit('onClickButton', e, data)
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .prompt-simple-wrapper {
      & + .modal-backdrop {
        opacity: 0.75;
      }

      .modal-prompt-simple {
        width: 315px;
        max-width: 315px;
        padding: 0;

        @media only screen and (max-width: 355px) {
          width: 100%;
          max-width: 100%;
          padding: 0 20px;
        }
      }

      .modal-prompt-big {
        width: 540px;
        max-width: 540px;
        padding: 0;

        @media only screen and (max-width: 580px) {
          width: 100%;
          max-width: 100%;
          padding: 0 20px;
        }
      }

      .modal-prompt-simple,
      .modal-prompt-big {
        margin: 0 auto;
        .modal-body {
          padding: 0;
        }

        .modal-content {
          background-color: transparent;
          background-clip: padding-box;
          border: none;
          border-radius: 0px;
          outline: 0;
        }
      }

      .box-type-shadow-card {
        overflow: hidden;

        &.close-animation {
          opacity: 0;
          transform: translateY(20px);
        }
      }

      .prompt-simple {
        &-text {
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: #4a4a4a;
            font-size: 1.3125rem;
            font-weight: 600;
            letter-spacing: -0.3px;
            line-height: 1.190476;
            text-align: center;
            margin: 0;
          }

          &-wrapper {
            & + .prompt-simple-actions {
              margin-top: 20px;
            }
          }
        }

        &-actions {
          display: flex;
          flex-wrap: nowrap;
          gap: 15px;

          &.double-full-buttons {
            flex-direction: column;
          }

          & > * {
            width: 50%;
          }

          &.single-full-button,
          &.double-full-buttons {
            & > * {
              width: 100%;
            }
          }

          .btn {
            &.btn-prompt-simple {
              font-size: 0.6875rem;
              font-weight: 600;
              letter-spacing: 1px;
              line-height: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 15px 10px 16px;
              border-radius: 7px;
              outline: none !important;
              transition: all 0.2s;
            }

            &.btn-prompt-simple-primary {
              color: #4a4a4a;
              background-color: #ffffff;
              box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);

              &.red {
                color: #f22c38;
              }
            }

            &.btn-prompt-simple-secondary {
              color: #ffffff;
              background-color: #4a4a4a;
            }
          }
        }
      }
    }
  }
</style>

import { Validator } from 'vee-validate'

export const dictionaryGet = (app) => {
  return {
    custom: {
      email: {
        required: app.i18n.t('messages.error.email-required'),
        email: app.i18n.t('messages.error.email-valid'),
      },

      current_password: {
        required: app.i18n.t('messages.error.password-required'),
        min: app.i18n.t('messages.error.password-required'),
      },

      password: {
        required: app.i18n.t('messages.error.password-required'),
        min: app.i18n.t('messages.error.password-minimum-lenght'),
        max: app.i18n.t('messages.error.password-maximum-lenght'),
        verify_password: app.i18n.t('messages.error.password-validation'),
      },

      new_password: {
        required: app.i18n.t('messages.error.new-password-required'),
        min: app.i18n.t('messages.error.password-minimum-lenght'),
        max: app.i18n.t('messages.error.password-maximum-lenght'),
        verify_password: app.i18n.t('messages.error.password-validation'),
      },

      password_confirmation: {
        required: app.i18n.t('messages.error.retype-password-required'),
        confirmed: app.i18n.t('messages.error.retype-password-not-match'),
      },

      first_name: {
        required: app.i18n.t('messages.error.first-name-required'),
        min: app.i18n.t('messages.error.first-name-minimum-lenght'),
      },

      esimName: {
        required: app.i18n.t('messages.error.rename-esim-required'),
        min: app.i18n.t('messages.error.rename-esim-minimum-lenght'),
      },

      voucherCode: {
        required: app.i18n.t('messages.error.voucher-code-required'),
      },

      phonenumber: {
        required: app.i18n.t('messages.error.phone-number-required'),
      },

      subject: {
        required: app.i18n.t('messages.error.subject-required'),
      },

      subject_id: {
        required: app.i18n.t('messages.error.subject-required'),
      },

      iccid: {
        integer: app.i18n.t('messages.error.iccid-lenght'),
      },

      message: {
        required: app.i18n.t('messages.error.message-required'),
      },

      discountReferralCode: {
        required: app.i18n.t('messages.error.discount-referral-code-required'),
      },

      comments: {
        required: app.i18n.t('messages.error.comments-required'),
      },

      'name-of-contact': {
        required: app.i18n.t('messages.error.name-of-contact-required'),
      },

      'number-of-employees': {
        required: app.i18n.t('messages.error.number-of-employees-required'),
        integer: app.i18n.t('messages.error.number-of-employees-type-integer'),
      },

      industry: {
        required: app.i18n.t('messages.error.industry-required'),
      },

      region: {
        required: app.i18n.t('messages.error.region-required'),
      },

      country: {
        required: app.i18n.t('messages.error.country-required'),
      },

      'company-website': {
        required: app.i18n.t('messages.error.company-website-required'),
      },

      'company-name': {
        required: app.i18n.t('messages.error.company-name-required'),
      },

      'partnership-type': {
        required: app.i18n.t('messages.error.partnership-type-required'),
      },

      confirm_new_password: {
        required: app.i18n.t('messages.error.retype-new-password-required'),
        confirmed: app.i18n.t('messages.error.retype-new-password-not-match'),
      },

      'card-holder-name': {
        required: app.i18n.t('messages.error.card-name-required'),
      },

      cardHolderName: {
        required: app.i18n.t('messages.error.card-name-required'),
      },

      cardExpiry: {
        required: app.i18n.t('messages.error.card-expire-date-required'),
      },

      birthday: {
        required: app.i18n.t('messages.error.birthday-required'),
        date_format: app.i18n.t('messages.error.birthday-invalid'),
      },

      mediaOutlet: {
        required: app.i18n.t('messages.error.media-outlet-required'),
      },

      mediaWebsite: {
        required: app.i18n.t('messages.error.media-website-required'),
        url: app.i18n.t('messages.error.media-website-url'),
      },

      mediaPhoneNumber: {
        phone: app.i18n.t('messages.error.media-phone-number-valid'),
      },

      countryRegion: {
        required: app.i18n.t('messages.error.country-region-required'),
      },

      contactName: {
        required: app.i18n.t('messages.error.contact-name-required'),
      },

      details: {
        required: app.i18n.t('messages.error.details-required'),
      },
    },
  }
}

export default ({ app }) => {
  const dict = dictionaryGet(app)

  Validator.localize(app.i18n.locale, dict)
}

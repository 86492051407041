import Vue from 'vue'
import VeeValidate from 'vee-validate'

import en from 'vee-validate/dist/locale/en'

Vue.use(VeeValidate, {
  "inject": true
})

VeeValidate.Validator.localize('en', en)

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator
}

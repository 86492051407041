import urlJoin from 'url-join'

export const state = () => ({
  appID: null,
  cachingStrategy: null,
  cacheTimestamp: null,
  environment: null,
  flags: null,
  error: null,
})

export const mutations = {
  SET(state, payload) {
    state.cachingStrategy = payload.cachingStrategy
    state.cacheTimestamp = payload.cacheTimestamp
    state.appID = payload.appID
    state.environment = payload.environment
    state.flags = payload.flags
    state.error = payload.error
  },
}

export const actions = {
  async fetch(context) {
    const { APP_URL, CLOUDFLARE_ACCESS_CLIENT_ID, CLOUDFLARE_ACCESS_CLIENT_SECRET } = process.env

    try {
      const url = urlJoin(APP_URL, '/nuxt-api/feature-flags')
      const options = {}

      if (CLOUDFLARE_ACCESS_CLIENT_ID && CLOUDFLARE_ACCESS_CLIENT_SECRET) {
        options.headers = {
          'CF-Access-Client-Id': CLOUDFLARE_ACCESS_CLIENT_ID,
          'CF-Access-Client-Secret': CLOUDFLARE_ACCESS_CLIENT_SECRET,
        }
      }

      const data = await this.$axios.$get(url, options)

      context.commit('SET', data)
    } catch (error) {
      context.commit('SET', {
        cacheTimestamp: null,
        cachingStrategy: null,
        appID: null,
        environment: null,
        flags: null,
        error,
      })
    }
  },
}

export const getters = {
  get(state) {
    return state
  },
}

<template>
  <button v-if="button" type="button" @click="$emit('click')">
    <slot />
  </button>
  <!-- If there is not link, render span tag -->
  <span v-else-if="!to" class="c--shared_link">
    <slot />
  </span>

  <!--
  If there is a link and the 'external' variable (prop) is true,
  render anchor link with target attr _blank
  -->
  <a
    v-else-if="to && external"
    :href="selfUrl ? to : localePath(to)"
    target="_blank"
    rel="noopener"
    @click="$emit('click')"
  >
    <slot />
  </a>

  <!-- In all other cases render nuxt link -->
  <nuxt-link v-else :to="selfUrl ? to : localePath(to)" @click.native="$emit('click')">
    <slot />
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      to: {
        type: String,
        default: '',
      },
      external: {
        type: Boolean,
        default: false,
      },
      selfUrl: {
        type: Boolean,
        default: false,
      },
      button: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

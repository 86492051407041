export const state = () => ({
  branding: null,
})

export const mutations = {
  SET_BRANDING(state, payload) {
    state.branding = payload
  },
}

export const actions = {
  async fetch_branding_config(context, payload) {
    if (!payload) {
      return false
    }

    try {
      const branding = await this.$axios.$get(`/brandings/${payload}`)

      if (branding) {
        context.commit('SET_BRANDING', branding)
      }
    } catch (error) {
      /**
       * DO NOTHING
       */
    }
  },
  async re_fetch_branding_config(context) {
    if (!context.state.branding) {
      return false
    }

    try {
      const branding = await this.$axios.$get(`/brandings/${context.state.branding?.identifier}`)

      if (branding) {
        context.commit('SET_BRANDING', branding)
      }
    } catch (error) {
      /**
       * DO NOTHING
       */
    }
  },
}

export const getters = {
  getBranding(state) {
    return state.branding
  },

  getIsCoBranded(state) {
    return !!state.branding
  },
}

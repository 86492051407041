var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--tabs_auth-tabs relative noselect"
  }, [_c('ul', {
    staticClass: "display-flex justify-content-between align-items-end pb-10 pb-sm-0 px-20 px-sm-0"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('li', {
      key: `auth-tab-${index}`,
      staticClass: "w-half",
      attrs: {
        "data-testid": `${tab.text}-tab`
      }
    }, [_c('nuxt-link', {
      class: ['display-flex justify-content-center align-items-center p-10 mb-10 mb-sm-0 p-sm-16 pb-sm-30 typo-h6 typo-sm-h4-right text-decoration-none', {
        'auth-tab-active box-rounded-sm-t color-text-primary color-bg-lighter color-bg-white-sm shadowed-sm': _vm.getRouteBaseName() && _vm.getRouteBaseName() === tab.link.name,
        'color-text-secondary': !(_vm.getRouteBaseName() && _vm.getRouteBaseName() === tab.link.name)
      }],
      attrs: {
        "to": _vm.localePath(_vm.tabAction(tab.link))
      }
    }, [_vm._v("\n        " + _vm._s(tab.text) + "\n      ")])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
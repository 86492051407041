var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "c--shared_header_sub-menu sub-menu",
    class: [`size-${_vm.type}`, _vm.customClass]
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: `shared-header-submenu-item-${index}`
    }, [!item.link && item.callback && item.callback.event && item.callback.functionName ? [_c('a', {
      class: [item.customClass ? item.customClass : ''],
      on: _vm._d({}, [item.callback.event, function ($event) {
        return _vm.clickPreventItem($event, item.callback.event, item);
      }])
    }, [_vm._v("\n        " + _vm._s(item.text)), item.image && item.image.length > 0 ? [_c('span', {
      class: [item.image, 'tw-text-4 sub-menu-item-image', {
        'rtl-transform-rotate-y': _vm.$dir() === 'rtl'
      }],
      attrs: {
        "data-testid": `${item.text}-item`
      }
    })] : _vm._e()], 2)] : [_c(item.selfUrl ? 'a' : 'nuxt-link', {
      tag: "component",
      class: [item.customClass ? item.customClass : ''],
      attrs: {
        "target": item.external ? '_blank' : '_self',
        "to": item.selfUrl ? item.link : _vm.localePath(item.link),
        "href": item.selfUrl ? item.link : _vm.localePath(item.link),
        "data-testid": `${item.text}-item`
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.clickHandler(item);
        }
      }
    }, [_vm._v("\n        " + _vm._s(item.text) + "\n        "), item.image && item.image.length > 0 ? [_c('span', {
      class: [item.image, 'sub-menu-item-image', {
        'rtl-transform-rotate-y': _vm.$dir() === 'rtl'
      }]
    })] : _vm._e()], 2)]], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "is-error": true
    }
  }), _vm._v(" "), _c('nuxt', {
    ref: "page"
  }), _vm._v(" "), _c('Footer', {
    key: `Footer-error`
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "size": "general",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.onHide,
      "hidden": _vm.onHidden,
      "shown": _vm.onShown
    },
    model: {
      value: _vm.modalView,
      callback: function ($$v) {
        _vm.modalView = $$v;
      },
      expression: "modalView"
    }
  }, [_vm.hasCloseButton ? _c('div', {
    staticClass: "c--modals_general-modal modal-general-close",
    attrs: {
      "dir": _vm.$dir()
    }
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onPressClose();
      }
    }
  }, [_c('span', {
    staticClass: "airalo-icon-close-round-filled tw-text-5.5 tw-text-neutral-600",
    attrs: {
      "data-testid": "close-button"
    }
  })])]) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.modalBodyWrapperClasses,
    style: _vm.modalBodyWrapperStyle
  }, [_vm.isModalTitle && _vm.modalTitle.length > 0 ? [_c('div', {
    class: ['general-item-wrapper-header', _vm.modalTitleMarginBottomClass],
    attrs: {
      "data-testid": `${_vm.modalTitle}-title`
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.modalTitle))])])] : _vm._e(), _vm._v(" "), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div data-testid="important-tips-modal" class="c--modals_important-tips important-tips-modal">
    <div v-if="title" data-testid="important-tips-title" class="important-tips-modal-title">{{ title }}</div>

    <template v-if="preloader">
      <div class="relative" style="min-height: 406px">
        <client-only>
          <v-lottie :is-background="false" />
        </client-only>
      </div>
    </template>
    <template v-else>
      <div class="select-none">
        <swiper
          ref="importantTipsModalSwiper"
          class="important-tips-modal-swiper"
          :options="swiperOption"
          :dir="$dir()"
          @slideChangeTransitionEnd="onSlideChange"
        >
          <swiper-slide
            v-for="(item, index) in tipsList"
            :key="`important-tips-modal-item-${index}`"
            class="important-tips-modal-swiper-item"
          >
            <template v-if="assetsType === 'lottie'">
              <Lottie
                :id="`important-tips-modal-item-lottie-${index}`"
                :ref="`important-tips-modal-item-lottie-${index}`"
                :options="{
                  loop: true,
                  autoplay: false,
                  animationData: item.data,
                  rendererSettings: false,
                }"
                :height="445"
                class="player-item"
                v-on:animCreated="handleAnimation($event, index)"
              />
            </template>
            <template v-else>
              <div :class="{ 'important-tips-modal-swiper-item-image': hasLargeImage }">
                <nuxt-img
                  :src="item.imageUrl"
                  alt=""
                  :width="imgWidth"
                  :height="imgHeight"
                  class="max-w-full"
                  loading="lazy"
                />
              </div>
            </template>
            <p
              data-testid="swiper-item-title"
              class="swiper-item-title"
              :class="{ 'swiper-item-title--no-subtitle': !item.subtitle }"
            >
              {{ item.title }}
            </p>
            <p v-if="item.subtitle" class="swiper-item-subtitle">{{ item.subtitle }}</p>
          </swiper-slide>
        </swiper>
      </div>
    </template>
    <div v-if="hasHorizontalAlign" class="important-tips-modal-swiper-bottom">
      <div class="important-tips-modal-swiper-bottom-left">
        <div class="sw-itm-pagination" slot="pagination"></div>
      </div>
      <div :data-testid="`${swiperButtonCaption}-btn`" class="swiper-button">
        <b-button variant="overlay-tooltip-cta" block @click="swiperBottomButton">{{ swiperButtonCaption }}</b-button>
      </div>
    </div>
    <div v-else class="tw-flex tw-flex-col tw-items-center tw-mb-5">
      <div class="tw-mb-3">
        <div class="sw-itm-pagination" slot="pagination"></div>
      </div>
      <div :data-testid="`${swiperButtonCaption}-btn`" class="swiper-butto tw-block tw-w-full tw-px-5">
        <button
          class="tw-block tw-border-primary tw-border-solid tw-rounded tw-mx-auto tw-py-4 tw-w-full tw-font-semibold tw-text-3 tw-tracking-wider"
          :class="{ 'tw-bg-neutral-600 tw-text-white': activeIndex === 2, 'tw-bg-white': activeIndex !== 2 }"
          @click="swiperBottomButton"
        >
          {{ swiperButtonCaption }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import Lottie from 'vue-lottie/src/lottie.vue'

  export default {
    components: {
      Swiper,
      SwiperSlide,
      Lottie,
    },

    props: {
      assetsType: {
        type: String,
        required: false,
        default: 'lottie', // image
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      nextTipCaption: {
        type: String,
        required: true,
      },
      lastTipCaption: {
        type: String,
        required: true,
      },
      tipsList: {
        type: Array,
        required: true,
      },
      preloader: {
        type: Boolean,
        required: true,
      },
      hasLargeImage: {
        type: Boolean,
        required: false,
        default: true,
      },
      imgWidth: {
        type: Number,
        required: false,
        default: 500,
      },
      imgHeight: {
        type: Number,
        required: false,
        default: 466,
      },
      hasHorizontalAlign: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data() {
      return {
        activeIndex: 0,

        animations: [],

        swiperOption: {
          centeredSlides: true,
          spaceBetween: 30,
          pagination: {
            el: '.sw-itm-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      }
    },

    computed: {
      numberOfTips() {
        return this.tipsList?.length || 0
      },

      isLastTip() {
        return this.activeIndex === this.numberOfTips - 1
      },

      swiperButtonCaption() {
        return this.isLastTip ? this.lastTipCaption : this.nextTipCaption
      },
    },

    methods: {
      onSlideChange() {
        this.activeIndex = this.$refs.importantTipsModalSwiper.$swiper.activeIndex

        if (this.assetsType === 'lottie') {
          this.resetPlayer()
          setTimeout(() => {
            this.setLottieAnimation(this.activeIndex)
          }, 100)
        }
      },

      swiperBottomButton() {
        if (this.isLastTip) {
          this.$emit('closeModalClicked')
        } else {
          this.$refs.importantTipsModalSwiper.$swiper.slideNext()
        }
      },

      checkAnimationIsExist(id) {
        const ids = this.animations.map((v) => v.animationID)

        return ids.includes(id)
      },

      handleAnimation(anim, index) {
        const animIsExist = this.checkAnimationIsExist(anim.animationID)

        if (!animIsExist) {
          this.animations.push(anim)
        }

        if (this.activeIndex === 0) {
          this.animations[0].play()
        }
      },

      setLottieAnimation(index) {
        const player = this.animations[index]

        setTimeout(() => {
          player.play()
        }, 100)
      },

      resetPlayer() {
        this.tipsList.forEach((_v, i) => {
          this.animations[i].stop()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .important-tips-modal {
    height: 100%;
    overflow-y: auto;

    &-title {
      border-bottom: 1px solid #eeeeee;
      color: #4a4a4a;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 25px;
      padding: 0 20px 20px 20px;
      text-align: center;
    }

    &-swiper {
      padding: 20px !important;

      @media only screen and (max-width: 575px) {
        min-height: calc(100vh - 250px);
      }

      &-item {
        display: flex !important;
        flex-direction: column !important;

        .swiper-item-img {
          align-self: center !important;
        }

        .swiper-item-title {
          color: #4a4a4a;
          font-size: 21px;
          font-weight: 600;
          letter-spacing: -0.3px;
          line-height: 25px;
          margin: 20px 0 10px 0;
        }

        .swiper-item-subtitle {
          color: #8a8a8a;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 21px;
          margin: 0;
        }

        .player-item {
          width: 100%;
          height: 445px !important;

          @media only screen and (max-width: 575px) {
            height: 50vh !important;
          }
        }

        &-image {
          display: flex;
          justify-content: center;
          height: 445px;

          @media only screen and (max-width: 575px) {
            height: auto;
          }

          img {
            width: auto;
            height: 100%;

            @media only screen and (max-width: 575px) {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      &-bottom {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        padding: 0 20px 40px 20px !important;

        @media only screen and (max-width: 768px) {
          position: sticky !important;
          bottom: 0 !important;
          padding-top: 30px !important;
          padding-bottom: 30px !important;
          background-color: #ffffff !important;
          z-index: 9 !important;
        }

        @media only screen and (max-width: 575px) {
          padding-top: 20px !important;
          padding-bottom: 20px !important;
        }

        &-left {
          width: calc(100% - 240px) !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;

          @media only screen and (max-width: 575px) {
            width: 50% !important;
          }
        }

        .sw-itm-pagination {
          background-color: rgba(238, 238, 238, 0.5) !important;
        }

        .swiper-button {
          display: flex !important;
          border-radius: 7px !important;
          background-color: #ffffff !important;
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15) !important;
          width: 240px !important;
          top: unset !important;
          transform: none !important;

          @media only screen and (max-width: 575px) {
            width: 50% !important;
          }

          button {
            padding: 16px !important;
            font-size: 11px !important;
            font-weight: 600 !important;
            letter-spacing: 1px !important;
            line-height: 11px !important;
            text-align: center !important;
            box-shadow: none !important;
          }
        }
      }
    }
    .sw-itm-pagination {
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      position: unset !important;
      border-radius: 13px !important;
      height: 26px !important;
      padding: 0 12px !important;
      margin: unset !important;

      ::v-deep .swiper-pagination-bullet {
        margin: 0 5px !important;
        background-color: #dddddd !important;
        opacity: 1 !important;

        &.swiper-pagination-bullet-active {
          background-color: #4a4a4a !important;
        }
      }
    }
  }
</style>

<template>
  <div data-testid="airmoney-header" class="c--shared_header_airmoney">
    <nuxt-link
      @click.native="clickHandler"
      class="display-flex flex-column flex-sm-row justify-content-center align-items-end align-items-sm-center text-decoration-none color-text-primary"
      :to="
        localePath({
          name: 'profile-index-airmoney',
        })
      "
    >
      <span class="d-flex justify-content-start align-items-center">
        <span class="display-flex display-sm-none">
          <i
            :class="[
              'airalo-icon-airmoney tw-text-zinc-500 tw-text-4',
              {
                'mr-3px': $dir() === 'ltr',
                'ml-3px': $dir() === 'rtl',
              },
            ]"
          />
        </span>
        <span class="display-none display-sm-inline-flex">
          <i
            :class="[
              'airalo-icon-airmoney tw-text-zinc-500 tw-text-4',
              {
                'mr-10': $dir() === 'ltr',
                'ml-10': $dir() === 'rtl',
              },
            ]"
          />
        </span>
        <span class="typo-h6 typo-sm-p font-weight-sm-normal">{{ $t('components.main.navigation.airmoney') }}</span>
      </span>
      <span
        data-testid="airmoney-value"
        :class="[
          'airmoney-box display-flex justify-content-center align-items-center tw-whitespace-nowrap border-default border-solid color-border-light border-sm-unset box-rounded px-10 px-sm-0 py-3px py-sm-0',
          {
            'ml-5px': $dir() === 'ltr',
            'mr-5px': $dir() === 'rtl',
          },
        ]"
      >
        <sup class="typo-input-heading">{{ userAirmoneyCurrencyCode }}</sup>
        <span class="typo-h3">{{ userAirmoneyAmount }}</span>
      </span>
    </nuxt-link>
  </div>
</template>

<script>
  export default {
    computed: {
      user() {
        return this.$store?.$auth?.$state?.user
      },

      userAirmoney() {
        return this.user?.airmoney
      },

      userAirmoneyAmount() {
        return this.userAirmoney ? this.userAirmoney.formatted.replaceAll(this.userAirmoneyCurrencyCode, '').trim() : ''
      },

      userAirmoneyCurrencyCode() {
        return this.userAirmoney?.currency.code
      },
    },

    methods: {
      clickHandler() {
        this.$utils.sendEvent('mainNavigation', {
          text: `${this.$t('components.main.navigation.airmoney')} ${this.userAirmoney?.formatted}`,
          url: this.localePath({ name: 'profile-index-airmoney' }),
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .airmoney-box {
    @media screen and (max-width: 575px) {
      margin-top: 1px;
    }
  }
</style>
